import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility/breakpoint'

interface ContentColumnProps {
  left: React.ReactChild
  right: React.ReactChild
  revertMobile?: boolean
}

interface ContentColumnContainerProps
  extends Pick<ContentColumnProps, 'revertMobile'> {}

export const ContentColumn: React.FC<ContentColumnProps> = ({
  left,
  right,
  revertMobile,
}) => {
  return (
    <ContentColumnContainer>
      <ContentColumnLeft revertMobile={revertMobile}>{left}</ContentColumnLeft>
      <ContentColumnRight>{right}</ContentColumnRight>
    </ContentColumnContainer>
  )
}

const ContentColumnContainer = styled.div(
  ({ theme: { grid } }) => `
  display: grid;
  grid-template-columns: repeat(${grid.columns - 4},1fr);
  grid-template-rows: 1fr;
  img{
    width:100%;
  }
  & > div{
    display:flex;
    align-items:center;
  }`
)
const ContentColumnLeft = styled.div<ContentColumnContainerProps>(
  ({ theme: { grid }, revertMobile }) => `
  grid-column: 1/35;
  grid-row: ${revertMobile ? 2 : 'inherit'};
  ${[mq[3]]}{
    grid-column: 1/16;
    grid-row:1;
  }
  `
)

const ContentColumnRight = styled.div(
  ({ theme: { grid } }) => `
  grid-column: 1/35;
  ${[mq[3]]}{
    grid-column: 18/35;
  }
  `
)
