import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface TextareaProps extends HTMLInputElement {
  error: string | undefined
  label?: string | undefined
}

interface TextareaContainerProps {
  placeholder?: string | undefined
  label?: string | undefined
  focus?: boolean
  error?: boolean
}

export const Textarea = React.forwardRef<
  TextareaProps,
  JSX.IntrinsicElements['textarea']
>(({ ...rest }, ref) => {
  const [placeholder, setPlaceholder] = React.useState<string | undefined>(
    rest.placeholder
  )
  const [focus, setFocus] = React.useState<boolean>(false)

  return (
    <TextareaContainer
      placeholder={placeholder}
      focus={focus}
      error={rest.error && rest.error !== ''}
    >
      <TextareaElement
        className={rest.error && rest.error !== '' ? 'error' : ''}
        ref={ref}
        {...rest}
        onFocus={() => {
          setFocus(true)
          //setPlaceholder(rest.label || rest.placeholder)
        }}
        onBlur={e => {
          setFocus(false)
          //setPlaceholder(e.currentTarget.value ? placeholder : '')
        }}
      />
      {rest.error && <Error>{rest.error}</Error>}
    </TextareaContainer>
  )
})

export const TextareaContainer = styled.div<TextareaContainerProps>(
  ({ theme: { colors, fonts }, label, placeholder, focus, error }) => `
  position:relative;
  &:after{
    color:${
      error
        ? colors.error
        : focus
        ? colors.inputBorderHover
        : colors.inputBorder
    };
    content: "${placeholder}";
    position:absolute;
    font-size:12px;
    font-family: ${fonts.fontFamily};
    left:16px;
    top:2px;
  }
`
)

export const TextareaElement = styled.textarea(
  ({ theme: { colors, fonts } }) => ({
    fontFamily: fonts.fontFamily,
    fontSize: '18px',
    padding: '19px 16px 10px 16px',
    border: 'none',
    outline: 'none',
    width: '100%',
    background: colors.inputBackground,
    borderBottom: `1px solid ${colors.inputBorder}`,
    color: colors.inputText,
    boxSizing: 'border-box',
    '&:hover, &:focus': {
      background: colors.inputBackgroundHover,
      borderBottom: `1px solid ${colors.inputBorderHover}`,
    },
    '&::placeholder': {
      transform: 'translateY(-5px)',
      color: colors.inputBorder,
      fontSize: 0,
    },
    '&:focus': {
      background: colors.inputBackgroundActive,
    },
    '&.error': {
      background: `rgba(${colors.error}, 0.2)`,
      borderBottom: `1px solid ${colors.error}`,
    },
    '&:disabled': {
      color: '#AAA',
    },
  })
)

export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
