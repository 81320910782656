import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface CheckboxGroupProps {
  label?: string
  error?: string
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  children,
  label,
  error,
}) => {
  return (
    <CheckboxGroupContainer className={error && 'error'}>
      {label && <span>{label}</span>}
      <div>{children}</div>
      {error && <Error>{error}</Error>}
    </CheckboxGroupContainer>
  )
}

export const CheckboxGroupContainer = styled.div(
  ({ theme: { colors, fonts } }) => `

  & > div{
    display:flex;
    justify-content:space-between;
  }
  & > span{
    position:absolute;
    font-size:12px;
    font-family: ${fonts.fontFamily};
    color:${colors.inputBorder};
    left:16px;
    top:2px;
  }
  &.error{
    background:${rgba(colors.error, 0.2)};
    border-bottom:1px solid ${colors.error};
  }
`
)
export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  bottom:-20px;
  left:0;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
