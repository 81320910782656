import { TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import React from 'react'
dayjs.extend(isBetween)

export const useTimeRange = (
  start: dayjs.Dayjs | string,
  end: dayjs.Dayjs | string
): boolean => {
  return dayjs(TimeUtil.getNowUtc()).isBetween(start, dayjs(end))

  const [isBetweenRange, setIsBetweenRange] = React.useState<boolean>(false)

  const checkIsBetween = () =>
    setIsBetweenRange(dayjs(TimeUtil.getNowUtc()).isBetween(start, dayjs(end)))

  React.useEffect(() => {
    checkIsBetween()
    const inter = setInterval(() => checkIsBetween(), 1000)

    return () => {
      clearInterval(inter)
    }
  }, [])

  return isBetweenRange
}
