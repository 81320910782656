import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

export interface TrackRenderFunctionConfig {
  filledTrackWidth: number
  hoverTrackWidth: number
}

export type TrackRenderFunction = (
  config: TrackRenderFunctionConfig
) => CSSObject

export interface DraggableSliderProps {
  containerStyles?: CSSObject
  trackStyles?: CSSObject
  thumbStyles?: CSSObject
  filledTrackStyles?: CSSObject
  hoverTrackStyles?: CSSObject
  onChange?(x: number): void
  renderAdditionalTracks?: CSSObject[]
  value?: number
  defaultValue?: number
  // defines the value for 100%
  max?: number
  thumbSize?: number
  trackHeight?: number
  // if true, the thumb is only visible when the userhovers the container
  hoverThumb?: boolean
  step?: number
  min?: number
  showHoverTrack?: boolean
}

const DEFAULT_THUMB_SIZE = 16
const DEFAULT_TRACK_HEIGHT = 4
const DEFAULT_MAX_VALUE = 100
const DEFAULT_MIN_VALUE = 0
const DEFAULT_CONTAINER_MIN_WIDTH = 100
const DEFAULT_HOVER_THUMB_VALUE = false
const DEFAULT_STEP = 1
const DEFAULT_DEFAULT_VALUE = 0

export interface DraggableSliderState {
  percentage: number
  hoverPercentage: number
  dragging: boolean
}

export interface DraggableSliderThumbProps {
  left: number
  size: number
  thumbStyles: DraggableSliderProps['thumbStyles']
}

export interface DraggableSliderContainerProps {
  height: DraggableSliderThumbProps['size']
  minWidth?: number
  containerStyles: DraggableSliderProps['containerStyles']
  hoverThumb: DraggableSliderProps['hoverThumb']
  dragging: DraggableSliderState['dragging']
  trackHeight: number
}

export interface DraggableSliderCenteredTrackProps {
  trackHeight: number
  trackStyles: DraggableSliderProps['trackStyles']
}

export interface DraggableSliderTrackProps
  extends DraggableSliderCenteredTrackProps {}

export interface FilledTrackProps extends DraggableSliderCenteredTrackProps {
  width: number
  thumbSize: number
}

export interface HoverTrackProps extends DraggableSliderCenteredTrackProps {
  width: number
}

export const DraggableSlider: React.FC<DraggableSliderProps> = ({
  onChange,
  defaultValue = DEFAULT_DEFAULT_VALUE,
  thumbSize = DEFAULT_THUMB_SIZE,
  trackHeight = DEFAULT_TRACK_HEIGHT,
  max = DEFAULT_MAX_VALUE,
  min = DEFAULT_MIN_VALUE,
  containerStyles,
  thumbStyles,
  trackStyles,
  filledTrackStyles,
  hoverTrackStyles,
  hoverThumb = DEFAULT_HOVER_THUMB_VALUE,
  step = DEFAULT_STEP,
  value,
  showHoverTrack,
  renderAdditionalTracks = [],
}) => {
  const [isVertical, setIsVertical] = React.useState(false)

  if (
    thumbStyles &&
    (thumbStyles.width !== undefined || thumbStyles.height !== undefined)
  ) {
    console.warn(
      `height and width shouldn't be set via thumbStyles.height/thumbStyles.width but thumbSize`
    )
  }

  if (containerStyles && containerStyles.height !== undefined) {
    console.warn(
      `containerStyles.height is set. This shouldn't be altered like this but by altering thumbSize`
    )
  }

  if (defaultValue > max) {
    console.warn(
      `The given defaultValue ${defaultValue} is greater than the given max ${max}. Using ${max} as defaultValue`
    )
  }

  if (min >= max) {
    console.warn(`min ${min} should be less than max ${max}`)
  }

  // use this value to initialize the slider
  const percentageToInit =
    value !== undefined ? value / max : Math.min(defaultValue, max) / max

  const [state, setState] = React.useState<DraggableSliderState>({
    // the value in percent where 0 is the smallest and 1 the biggest value
    percentage: percentageToInit,
    hoverPercentage: 0,
    dragging: false,
  })

  const containerRef = React.useRef<HTMLDivElement>(null)

  /**
   * For a given mousePosX, this function calculates the amount of track that is to the
   * left of the mouse in percent, where 0 is the smallest and 1 is the highest value
   */
  const getPercentage = (mousePosX: number, mousePosY: number) => {
    if (!containerRef.current) {
      return 0
    }

    const {
      x,
      width,
      left,
      y,
      height,
      top,
    } = containerRef.current.getBoundingClientRect()

    // x is not available in legacy Edge
    const xToUse = typeof x === 'number' ? x : left
    const yToUse = typeof y === 'number' ? y : top

    if (!isVertical && width === 0) {
      return 0
    }
    if (isVertical && height === 0) {
      return 0
    }

    const percantage = isVertical
      ? 1 - (mousePosY - yToUse) / height
      : (mousePosX - xToUse) / width

    if (percantage < 0) {
      return 0
    }

    if (percantage > 1) {
      return 1
    }

    return percantage
  }

  /**
   * For a given percentage between 0 and 1, this function will return a value that is in the range
   * of min and max
   *
   * @param percentage
   */
  const percentageToStep = (percentage: number) => {
    const valueToStep = percentage * Math.abs(max - min) + min
    const steppedValue = Math.round(valueToStep / step) * step
    const stepSizeDecimals = `${step}`.split('.')[1]
    const stepSizeDecimalsCount = stepSizeDecimals ? stepSizeDecimals.length : 0

    return Number(steppedValue.toFixed(stepSizeDecimalsCount))
  }

  const stepToPercentage = (steppedValue: number) => {
    return Math.abs(steppedValue - min) / Math.abs(max - min)
  }

  /**
   * When mouse button is pressed down on the container, enter dragging mode
   */
  const onContainerMouseDown = (clientX: number, clientY: number) => {
    const percentage = getPercentage(clientX, clientY)

    if (!state.dragging || state.percentage !== percentage) {
      setState(s => ({ ...s, dragging: true, percentage }))
    }
  }

  /**
   * Returns true, if the mouse iswithin the container
   *
   * @param clentX
   * @param clientY
   */
  const getIsMouseInContainer = (clientX: number, clientY: number) => {
    if (!containerRef.current) {
      return false
    }

    const { x, width, height, y } = containerRef.current.getBoundingClientRect()

    const withinXRange = clientX >= x && clientX <= x + width
    const withinYRange = clientY >= y && clientY <= y + height

    return withinXRange && withinYRange
  }

  /**
   * When the mouse button is released, leave dragging mode
   */
  React.useEffect(() => {
    const endDragging = () => {
      if (state.dragging) {
        setState(s => ({ ...s, dragging: false }))
      }
    }

    document.addEventListener('mouseup', endDragging)
    document.addEventListener('touchend', endDragging)

    return () => {
      document.removeEventListener('mouseup', endDragging)
      document.removeEventListener('touchend', endDragging)
    }
  }, [state.dragging])

  /**
   * If in dragging mode, change the state.value on mousemove
   */
  React.useEffect(() => {
    if (!state.dragging && !showHoverTrack) {
      return
    }

    const setValue = ({
      clientX,
      clientY,
    }: {
      clientX: number
      clientY: number
    }) => {
      if (!containerRef.current) {
        return
      }

      const { x, width } = containerRef.current.getBoundingClientRect()
      const leftBoundary = x
      const rightBoundary = leftBoundary + width
      const withinContainer = getIsMouseInContainer(clientX, clientY)

      if (!withinContainer && !state.dragging) {
        if (state.hoverPercentage !== 0) {
          setState(s => ({ ...s, hoverPercentage: 0 }))
        }

        return
      }

      if (clientX <= leftBoundary) {
        if (state.dragging && state.percentage !== 0) {
          setState(s => ({ ...s, percentage: 0 }))
        } else if (state.hoverPercentage !== 0) {
          setState(s => ({ ...s, hoverPercentage: 0 }))
        }

        return
      }

      if (clientX >= rightBoundary) {
        if (state.dragging && state.percentage !== 1) {
          setState(s => ({ ...s, percentage: 1 }))
        } else if (state.hoverPercentage !== 1) {
          setState(s => ({ ...s, hoverPercentage: 1 }))
        }

        return
      }

      const percentage = getPercentage(clientX, clientY)

      if (state.dragging && state.percentage !== percentage) {
        setState(s => ({ ...s, percentage }))
      } else if (state.hoverPercentage !== percentage) {
        setState(s => ({ ...s, hoverPercentage: percentage }))
      }
    }

    const setValueTouch = (touchEvent: TouchEvent) =>
      setValue(touchEvent.changedTouches[0])

    document.addEventListener('mousemove', setValue)
    document.addEventListener('touchmove', setValueTouch)

    return () => {
      document.removeEventListener('mousemove', setValue)
      document.removeEventListener('touchmove', setValueTouch)
    }
  }, [state.dragging, state.hoverPercentage])

  /**
   * If state.value changes, call props.onChange, if given
   */
  React.useEffect(() => {
    onChange && onChange(percentageToStep(state.percentage))
  }, [state.percentage, step, min, max])

  React.useEffect(() => {
    if (containerRef.current) {
      const rotate = window
        .getComputedStyle(containerRef.current)
        .getPropertyValue('transform')
      if (rotate && rotate !== 'none') {
        let values = rotate.split('(')[1]
        values = values.split(')')[0]
        values = values.split(',')
        const angle = Math.round(Math.asin(values[1]) * (180 / Math.PI))
        setIsVertical(Math.abs(angle) === 90)
      }
    }
  }, [containerRef])

  // if containerStyles defines width or minWidth, don't set a minWidth
  const containerMinWidth =
    !containerStyles ||
    (containerStyles.width === undefined &&
      containerStyles.minWidth === undefined)
      ? DEFAULT_CONTAINER_MIN_WIDTH
      : undefined

  const percentageToUse =
    value !== undefined
      ? stepToPercentage(value)
      : stepToPercentage(percentageToStep(state.percentage))
  const steppedPercentage = percentageToUse * 100
  const steppedHoverPercentage =
    stepToPercentage(percentageToStep(state.hoverPercentage)) * 100

  return (
    <Container
      className="DraggableSlider"
      onMouseDown={e => {
        onContainerMouseDown(e.clientX, e.clientY)
      }}
      onTouchStart={e => {
        onContainerMouseDown(
          e.changedTouches[0].clientX,
          e.changedTouches[0].clientY
        )
      }}
      ref={containerRef}
      height={thumbSize}
      minWidth={containerMinWidth}
      containerStyles={containerStyles}
      hoverThumb={hoverThumb}
      dragging={state.dragging}
      trackHeight={trackHeight}
    >
      <Track
        id="track"
        trackStyles={trackStyles}
        trackHeight={trackHeight}
        className="scale-up"
      />
      {renderAdditionalTracks.map((styles, key) => (
        <CenteredTrack
          trackHeight={trackHeight}
          trackStyles={styles}
          key={key}
        ></CenteredTrack>
      ))}
      <HoverTrack
        trackStyles={hoverTrackStyles}
        width={steppedHoverPercentage}
        trackHeight={trackHeight}
        className="scale-up"
        id="hoverTrack"
      />
      <ThumbContainer thumbSize={thumbSize}>
        <FilledTrack
          trackStyles={filledTrackStyles}
          width={steppedPercentage}
          thumbSize={thumbSize}
          trackHeight={trackHeight}
          id="filledTrack"
          className="scale-up"
        />
        <Thumb
          left={steppedPercentage}
          size={thumbSize}
          thumbStyles={thumbStyles}
          id="thumb"
        ></Thumb>
      </ThumbContainer>
    </Container>
  )
}

const ThumbContainer = styled.div<{ thumbSize: number }>(({ thumbSize }) => ({
  width: `calc(100% - ${thumbSize}px)`,
  height: '100%',
  position: 'relative',
  minHeight: `${thumbSize}px`,
}))

const Container = styled.div<DraggableSliderContainerProps>(
  ({
    height,
    minWidth,
    containerStyles,
    hoverThumb,
    dragging,
    trackHeight,
  }) => ({
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    minHeight: height,
    touchAction: 'none',
    minWidth,

    '& div#thumb': {
      height: dragging ? height : hoverThumb ? 0 : height,
      width: dragging ? height : hoverThumb ? 0 : height,
    },

    '&:hover div#thumb': {
      height,
      width: height,
    },

    ...containerStyles,
  })
)

export const CenteredTrack = styled.div<DraggableSliderCenteredTrackProps>(
  ({ trackHeight, trackStyles }) => ({
    borderRadius: '0.25rem',
    touchAction: 'none',
    height: `${trackHeight}px`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',

    ...trackStyles,
  })
)

const FilledTrack = styled(CenteredTrack)<FilledTrackProps>(
  ({ width, thumbSize, trackStyles, theme: { colors } }) => ({
    backgroundColor: colors.inputBorderHover,
    width: `calc(${width}% + ${thumbSize / 2}px)`,
    ...trackStyles,
  })
)

const HoverTrack = styled(CenteredTrack)<HoverTrackProps>(
  ({ trackStyles, width }) => ({
    width: `${width}%`,
    backgroundColor: 'rgba(255,255,255,.5)',
    ...trackStyles,
  })
)

const Track = styled(CenteredTrack)<DraggableSliderTrackProps>(
  ({ trackStyles, theme: { colors } }) => ({
    backgroundColor: colors.border,
    width: '100%',
    ...trackStyles,
  })
)

const Thumb = styled.div<DraggableSliderThumbProps>(
  ({ left, size, thumbStyles, theme: { colors } }) => ({
    position: 'absolute',
    backgroundColor: 'red',
    width: `${size}px`,
    height: `${size}px`,
    left: `${left}%`,
    borderRadius: '50%',
    touchAction: 'none',
    WebkitUserSelect: 'none',
    transition: 'height .2s linear, width .2s linear',
    zIndex: 3,

    ...thumbStyles,
  })
)
