import styled from '@emotion/styled'
import { Fieldset, Form, Legend as LegendCore } from 'cuenect-web-core'
import i18n from 'i18next'
import parse from 'html-react-parser'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { mq } from '../../../utility'
import { Button, Heading, Input, Select, Text, Paragraph } from '../../atoms'
import { CountryList } from '../salesForm/countryList'

export interface SelftrainingFormData {
  email: string
  firstName: string
  lastName: string
  language: string
  businessUnit?: string
  country?: typeof CountryList[0]['value']
}

export interface SelftrainingProps {
  onSubmit?(data: SelftrainingFormData): void
}

const BUSINESS_UNITS = [
  'CM',
  'CS',
  'DS',
  'EP',
  'FA',
  'MC',
  'PA',
  'SFS',
  'SW',
] as const

export const SelftrainingForm: React.FC<SelftrainingProps> = ({ onSubmit }) => {
  const { register, handleSubmit, errors, getValues } = useForm<
    SelftrainingFormData
  >({
    mode: 'onSubmit',
  })

  const { t } = useTranslation('salestraining')
  const lang = i18n.language === 'de' ? 'de' : 'en'

  // this function validates the values for country and/or business unit
  // the validation fails, if there is no value for both dropdowns
  // everything else (one select, both selected) is allowed
  const validateCountryBU = () => {
    const { businessUnit, country } = getValues()

    if (!businessUnit && !country) {
      return false
    }

    return true
  }

  return (
    <Form
      onSubmit={handleSubmit(data => {
        onSubmit && onSubmit(data)
      })}
    >
      <Fieldset>
        <Legend>
          <Heading type="h5">{t('form.personalData')}</Heading>
        </Legend>
        <input
          type="hidden"
          name="language"
          ref={register({ required: true })}
          value={lang}
        />
        <PersonalDataInputContainer>
          <Input
            placeholder={t('form.email')}
            ref={register({
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
            name="email"
            error={errors.email && t('form.errors.email')}
          ></Input>
          <Input
            placeholder={t('form.firstName')}
            ref={register({ required: true })}
            name="firstName"
            error={errors.firstName && t('form.errors.firstName')}
          ></Input>
          <Input
            placeholder={t('form.lastName')}
            ref={register({ required: true })}
            name="lastName"
            error={errors.lastName && t('form.errors.lastName')}
          ></Input>
        </PersonalDataInputContainer>
      </Fieldset>

      <Fieldset>
        <Legend>
          <Heading type="h5">{t('form.categorization')}</Heading>
        </Legend>

        <SelectContainer>
          <div>
            <Select
              placeholder={t('form.businessUnit')}
              ref={register({ validate: validateCountryBU })}
              name="businessUnit"
            >
              <option disabled selected value={undefined}></option>
              {BUSINESS_UNITS.map(unit => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </Select>
            {errors.businessUnit && (
              <ErrorText>{t('form.errors.businessUnit')}</ErrorText>
            )}
          </div>

          <div>
            <Select
              placeholder={t('form.country')}
              ref={register({ validate: validateCountryBU })}
              name="country"
            >
              <option disabled selected value={undefined}></option>
              {CountryList.sort((a, b) =>
                a.name[lang].localeCompare(b.name[lang])
              ).map((country, key) => (
                <option key={key} value={country.value}>
                  {country.name[lang]}
                </option>
              ))}
            </Select>
            {errors.country && (
              <ErrorText>{t('form.errors.country')}</ErrorText>
            )}
          </div>
        </SelectContainer>
      </Fieldset>

      <ButtonContainer>
        <Button type="submit">{t('form.cta')}</Button>
      </ButtonContainer>
    </Form>
  )
}

const Legend = styled(LegendCore)({
  borderBottom: '0 !important',
  '& h5': { margin: 0 },
})

const ErrorText = styled(Text)(({ theme }) => ({
  color: theme.colors.error,
}))

const ButtonContainer = styled.div({ marginTop: '1.5rem' })

const SelectContainer = styled.div({
  '& > div:not(:last-of-type)': { marginBottom: '1rem' },
})

const PersonalDataInputContainer = styled(Fieldset)({
  display: 'grid',
  rowGap: '1rem',

  [`${mq[2]}`]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '1rem',
    rowGap: '0',
  },

  '& input': {
    width: '100%',
  },
})
