import styled from '@emotion/styled'

export const Progress = styled.progress(({ theme: { colors } }) => ({
  appearance: 'none',
  webkitAppearance: 'none',
  width: '100%',
  borderRadius: '999px',
  background: '#22223e',
  border: `2px solid #22223e`,
  height: '22px',
  '&::-webkit-progress-bar': {
    backgroundColor: 'transparent',
    borderRadius: '999px',
  },
  '&::-moz-progress-bar': {
    background: colors.buttonBackground,
    borderRadius: '999px',
  },
  '&::-webkit-progress-value': {
    border: `1px solid #22223e`,
    background: colors.buttonBackground,
    borderRadius: '999px',
  },
}))
