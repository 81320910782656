import styled from '@emotion/styled'

export const ProgramCta = styled.button(({ theme: { colors } }) => ({
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.text,
  outline: 0,
  border: 0,
  cursor: 'pointer',
  position: 'relative',
  '&:before': {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: colors.buttonActiveBackground,
  },
}))
