import { GenIcon, IconBaseProps } from 'react-icons'
export const SieMenu = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M21.9,17v2H2.1v-2H21.9z M14,11v2H2.1v-2H14z M21.9,5.1v2H2.1v-2H21.9z',
        },
        child: [],
      },
    ],
  })(props)
