import { GenIcon, IconBaseProps } from 'react-icons'
export const SieYoutube = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M21.6,7.2c-0.2-0.9-0.9-1.5-1.8-1.8C18.3,5,12,5,12,5S5.7,5,4.2,5.4C3.3,5.6,2.6,6.3,2.4,7.2C2,8.8,2,12,2,12s0,3.3,0.4,4.8c0.2,0.9,0.9,1.5,1.8,1.8C5.8,19,12,19,12,19s6.3,0,7.8-0.4c0.9-0.2,1.5-0.9,1.8-1.8C22,15.3,22,12,22,12S22,8.8,21.6,7.2z M10,15V9l5.2,3L10,15z',
        },
        child: [],
      },
    ],
  })(props)
