import { GenIcon, IconBaseProps } from 'react-icons'
export const SieUser = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M12,2.4c1.3,0,2.4,1.1,2.4,2.4S13.3,7.2,12,7.2S9.6,6.1,9.6,4.8S10.7,2.4,12,2.4 M12,0C9.3,0,7.2,2.1,7.2,4.8S9.3,9.6,12,9.6s4.8-2.1,4.8-4.8S14.7,0,12,0z M14.4,14.4c2.7,0,4.8,2.1,4.8,4.8v2.4H4.8v-2.4c0-2.7,2.1-4.8,4.8-4.8H14.4 M14.4,12H9.6c-4,0-7.2,3.2-7.2,7.2V24h19.2v-4.8C21.6,15.2,18.4,12,14.4,12z',
        },
        child: [],
      },
    ],
  })(props)
