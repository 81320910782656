import { LocalStorageUtil } from 'cuenect-web-core'
import React from 'react'
import { AnalyticsTransformer } from './../utility'
export const AGENDA_RESERVATIONS = 'agendaReservations'

interface State {
  reservations: string[]
  selectedDate: number
}

const defaultState: State = {
  reservations: LocalStorageUtil.getObject<string[]>(AGENDA_RESERVATIONS) || [],
  selectedDate: -1,
}
export interface ReservationAction {
  type: 'ADD_RESERVATION' | 'REMOVE_RESERVATION' | 'RESET_DATESELECTED'
  state?: State
  id?: string
  selectedDate?: number
}

const reducer: React.Reducer<State, ReservationAction> = (state, action) => {
  switch (action.type) {
    case 'ADD_RESERVATION':
      AnalyticsTransformer.customEvent([
        'reservation',
        `reserve||${JSON.stringify({ uid: action.id })}`,
      ])

      return {
        ...state,
        reservations: [...state.reservations, action.id],
        selectedDate: action.selectedDate,
      }

    case 'REMOVE_RESERVATION':
      AnalyticsTransformer.customEvent([
        'reservation',
        `cancel||${JSON.stringify({ uid: action.id })}`,
      ])

      return {
        ...state,
        reservations: state.reservations.filter(item => item !== action.id),
        selectedDate: action.selectedDate,
      }

    case 'RESET_DATESELECTED':
      return {
        ...state,
        selectedDate: -1,
      }
    default:
      return defaultState
  }
}
export const ReservationContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<ReservationAction>
}>({ state: defaultState, dispatch: () => null })

export const ReservationContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  React.useEffect(() => {
    LocalStorageUtil.saveData<string[]>(AGENDA_RESERVATIONS, state.reservations)
  }, [state])

  return (
    <ReservationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ReservationContext.Provider>
  )
}

export const useReservationContext = () => React.useContext(ReservationContext)
