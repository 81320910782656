import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'
import { SieCheck } from '../icons'

// removing the ref object since it allows React.LegacyRef, which isn't allowed by React.forwardRef
type InputProps = Omit<JSX.IntrinsicElements['input'], 'ref'>

export interface CheckboxProps extends InputProps {
  // adding ref again but only allowing types allowed by React.forwardRef
  ref?: React.Ref<HTMLInputElement>
  label?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ ...rest }, ref) => (
    <StyledLabel>
      <StyledInput ref={ref} type="checkbox" {...rest}></StyledInput>
      <CheckedIndicator>
        <SieCheck size={'20px'} />
      </CheckedIndicator>
      {rest.label ? rest.label : ''}
    </StyledLabel>
  )
)

const CheckedIndicator = styled.div(
  ({ theme: { colors, fonts } }) => `
  display:inline-flex;
  width: 24px;
  height: 24px;
  box-sizing:border-box;
  border: 1px solid ${colors.inputBorder};
  justify-content:center;
  align-items:center;
  background:${rgba(colors.buttonSecondaryBackground, 0.12)};
  margin-right:10px;
  svg{
    opacity:0;
  }

`
)

const StyledLabel = styled.label(
  ({ theme: { colors, fonts } }) => `
  cursor:pointer;
  display:inline-flex;
  align-items:center;
  & input:checked ~ div{
    svg{
      opacity:1;
    }
  }
  &:hover{
    & > div{
      background:${rgba(colors.buttonSecondaryBackground, 0.2)};
      border-color:${colors.inputBorderHover};
      svg{
        fill:${colors.inputBorderHover};
      }
    }
  }
`
)

const StyledInput = styled.input({
  display: 'none',
})
