import { AxiosResponse } from 'axios'
import { IHm21Appointment, IHm21DialogData } from '../interfaces/dialogdata'
import { DialogAppointmentTopics } from '../utility/dialogAppointmentTopics'
import { HttpService } from './../api/http'
import { apiConfig } from './../config'
export interface SalesUser {
  email: string
  firstname: string
  lastname: string
}
export interface SalesResource {
  email: string
  start: string
  end: string
  title: string
}

export class DialogAppointmentService {
  public static async getAppointment(token: string) {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${apiConfig.dialogAppointment}/${token}`,
      config: { withCredentials: true },
    })

    if (!result) {
      return null
    }

    return result.data
  }

  public static async sendForm(
    dialogData: IHm21DialogData,
    appointment: IHm21Appointment,
    token: string
  ) {
    const parsedData = DialogAppointmentTopics.parseFormData(
      dialogData,
      appointment
    )

    const response: AxiosResponse | false = await HttpService.post({
      url: `${apiConfig.dialogAppointment}/${token}`,
      data: parsedData,
      config: { withCredentials: false },
    })

    return response
  }
}
