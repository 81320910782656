import { GenIcon, IconBaseProps } from 'react-icons'
export const SieArrow = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M17.6,12l-9.9,9.9l-1.3-1.3l7.5-8.7L6.4,3.3l1.3-1.3L17.6,12L17.6,12z',
        },
        child: [],
      },
    ],
  })(props)
