import styled from '@emotion/styled'
import { useOnClickOutside } from 'cuenect-web-core'
import { rgba } from 'emotion-rgba'
import React from 'react'
interface ErrorBarInterface {
  // need random number to toggle
  visible?: string
}

export const ErrorBar: React.FC<ErrorBarInterface> = ({
  children,
  visible,
}) => {
  const [currentVisible, setCurrentVisible] = React.useState(visible !== '')

  const errorBarRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(errorBarRef, () => setCurrentVisible(false))

  React.useEffect(() => setCurrentVisible(visible !== ''), [visible])

  return (
    <>
      {currentVisible ? (
        <ErrorBarOuter ref={errorBarRef}>
          <div>{children}</div>
        </ErrorBarOuter>
      ) : (
        <></>
      )}
    </>
  )
}
const ErrorBarOuter = styled.div(
  ({ theme: { colors } }) => `
  position:fixed;
  bottom:0;
  left:0;
  width:100vw;
  background:${rgba(colors.error, 0.9)};
  padding:20px;
  display:flex;
  justify-content:center;
  div{
    max-width:1000px;
    text-align:center;
  }
`
)
