import styled from '@emotion/styled'
import React from 'react'
import { mq } from '../../../utility'

export const ImageTeaserRow: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.div({
  gridTemplateColumns: '1fr ',
  display: 'grid',
  rowGap: '2rem',

  [`${mq[2]}`]: {
    columnGap: '2rem',
    gridTemplateColumns: '1fr 1fr',
  },

  [`${mq[3]}`]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})
