import { GenIcon, IconBaseProps } from 'react-icons'
export const SieChat = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M20,4V15H14.05v3.41l-5-3.11L8.57,15H4V4H20m2-2H2V17H8l8,5,0-5h6V2Z',
        },
        child: [],
      },
    ],
  })(props)
