import { GenIcon, IconBaseProps } from 'react-icons'
export const SiePlay = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M17.5,12l-11,5.5v-11L17.5,12z',
        },
        child: [],
      },
    ],
  })(props)
