import { AxiosResponse } from 'axios'
import { LocalStorageUtil, TimeUtil } from 'cuenect-web-core'
import { ProgramEvent } from '.'
import { AppointmentFormData } from './../components/organisms/appointmentForm'
import { apiConfig, EventBreakEvent, eventBreaks } from './../config'
import { AppointmentTopics } from './../utility'
import { HttpService } from './http'
import { v4 as uuidv4 } from 'uuid'

export class TopicsService {
  public static async getTopics() {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${apiConfig.topics}`,
      config: { withCredentials: true },
    })
    if (!result) {
      return false
    }
    const topics = result.data

    const data = []
    for (let mainTopic in topics) {
      const mTopic = topics[mainTopic]
      const push_data = {
        label: mainTopic,
        ...TopicsService.parseTopics(mTopic, mainTopic),
      }
      data.push(push_data)
    }
    console.log('topics', data)
    return data
  }

  public static parseTopics(node) {
    let ret = {}
    ret.uuid = uuidv4()
    ret.value = node[2]
    // ret.businessUnit = node[2][1]

    node.forEach((e, key) => {
      if (key === 0) {
        //ret.businessLabel = e
      }
      if (key === 1) {
        //ret.businessUnitLabel = e
      }
      let label = null

      if (key === 2) {
        //  ret.business = e[0]
        //  ret.businessUnit = e[1]

        label = e[2].split('/').pop().trim()
      }
      if (key === 3) {
        const ch = []
        for (let topic in e) {
          ch.push({
            label: topic,
            // value: topic.replace(/\,/g, ";"),
            ...TopicsService.parseTopics(node[3][topic]),
          })
        }

        if (ch.length > 0) {
          ret.children = ch
          // ret.business = ''
          // ret.businessUnit = ''
        }
      }
    })
    return ret
  }
}
