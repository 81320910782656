import languageData from './languages-data.json'

interface MeetingLanguagesProps {
  name: {
    de: string
    en: string
  }
  key: string
}

export const MeetingLanguages: MeetingLanguagesProps[] = languageData
