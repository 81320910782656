import styled from '@emotion/styled'
import { AspectRatioContainer } from 'cuenect-web-core'
import i18n from 'i18next'
import React from 'react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnalyticsTransformer } from '../../../utility'
import { Button, Select, SieArrow } from '../../atoms'
import { VideoWithControls } from './../../'
import { ShowroomItem } from './../../../api'
import { useDimensions } from './../../../utility'
import cq from './../../../utility/coreQuery'
import { mq } from '../../../utility'

interface VodVideoProps {
  src?: string
  track?: string
  trackingTitle?: string
  playerName?: string
  videoSelector?: ShowroomItem[] | null
}

interface VodVideoContainer {
  fullWidth?: boolean
}

export const VodVideo: React.FC<VodVideoProps> = ({
  src,
  track,
  trackingTitle,
  playerName = 'Vod',
  videoSelector,
}) => {
  const { t } = useTranslation('core')

  const [, boundingBox] = useDimensions()
  const [currentSrc, setCurrentSrc] = React.useState<string | null>(src || null)
  const [tracks, setTracks] = React.useState<TextTrackCue[] | null>(null)
  const [currentTrack, setCurrentTrack] = React.useState<string | null>(null)
  const [videoHeight, setVideoHeight] = React.useState(0)
  const videoElemRef = React.useRef()
  const languageSelectorRef = React.useRef()

  const [scrollBarRef, setScrollBarRef] = React.useState()
  const [scrollbarPos, setScrollbarPos] = React.useState(0)
  const [manualScroll, setManualScroll] = React.useState(true)

  const _scrollBarRef = React.useRef()

  const calcVideoHeight = () => {
    if (videoElemRef.current) {
      setVideoHeight(videoElemRef.current.offsetHeight)
    }
  }

  const gotoCue = (time: number, text: string) => {
    videoElemRef.current.currentTime = time + 0.1 // add 0.1 second to prevent cuechange event
    setCurrentTrack(text)

    AnalyticsTransformer.customEvent([
      'guidedtourvideo',
      `chapter||${JSON.stringify({
        label: text,
        src: src,
        video: `${trackingTitle} – ${currentLabel()}`,
      })}`,
    ])
  }

  const scrollNavTo = (slug: string) => {
    if (scrollBarRef) {
      setManualScroll(false)
      if (document.getElementById(slug)) {
        scrollBarRef.scrollTop = document.getElementById(slug).offsetTop
      } else {
        scrollBarRef.scrollTop = scrollbarPos
      }
      setTimeout(() => {
        setManualScroll(true)
      }, 100)
    }
  }

  React.useEffect(() => {
    scrollNavTo(currentTrack)
  }, [currentTrack])

  React.useEffect(() => {
    if (videoElemRef.current) {
      window.addEventListener('resize', calcVideoHeight)
      videoElemRef.current.addEventListener('loadedmetadata', calcVideoHeight)
    }

    return () => {
      window.removeEventListener('resize', calcVideoHeight)
      if (videoElemRef.current) {
        videoElemRef.current.removeEventListener(
          'loadedmetadata',
          calcVideoHeight
        )
      }
    }
  }, [videoElemRef.current])

  const paddingBox = {
    ...boundingBox,
    width:
      boundingBox.width < 1024
        ? boundingBox.width * 0.9
        : boundingBox.width - (isTablet ? 300 : videoSelector ? 600 : 200),
    height:
      boundingBox.width < 1024
        ? boundingBox.height * 0.9
        : boundingBox.height - (isTablet ? 300 : 200),
  }

  React.useEffect(() => {
    if (tracks) {
      calcVideoHeight()
    }
  }, [tracks])

  const changeLanguage = () => {
    const current = videoSelector.find(
      ({ slug }) => slug === languageSelectorRef.current.value
    )
    if (current) {
      setCurrentSrc(current.video)
    }
    // setCurrentSrc
  }

  const currentLabel = () =>
    videoSelector.find(({ video }) => video === currentSrc)?.label || ''

  const videoSelectorComp = () => {
    return (
      <Select
        ref={languageSelectorRef}
        placeholder={t('language')}
        onChange={() => changeLanguage()}
        defaultValue={
          videoSelector.find(({ video }) => video === currentSrc)?.slug || ''
        }
      >
        {videoSelector.map(({ slug, label, video }) => (
          <option value={slug}>{label}</option>
        ))}
      </Select>
    )
  }

  return (
    <VodOuter fullWidth={!tracks}>
      {tracks && (
        <VodTrackNavOuter>
          {/*  {videoSelector && videoSelectorComp()} */}
          <VodTrackNav
            style={{ height: `${videoHeight - (videoSelector ? 55 : 0)}px` }}
            containerRef={ref => {
              setScrollBarRef(ref)
            }}
            onScrollY={() => {
              manualScroll && setScrollbarPos(scrollBarRef.scrollTop)
            }}
          >
            {tracks?.map(({ text, startTime }) => (
              <VodTrackItem
                key={text}
                id={text}
                menuItem
                icon={SieArrow}
                iconAlign="right"
                active={text === currentTrack}
                onClick={() => gotoCue(startTime, text)}
              >
                {text}
              </VodTrackItem>
            ))}
          </VodTrackNav>
        </VodTrackNavOuter>
      )}

      <VodVideoContainer fullWidth={!tracks}>
        <AspectRatioContainer xRatio={16} yRatio={9} boundingBox={paddingBox}>
          <VideoWithControls
            playerName={playerName}
            showBigPlayButton={true}
            autoPlay
            onTrackLoaded={data => data && setTracks(Object.values(data))}
            onTrackChange={data => setCurrentTrack(data.text)}
            trackingTitle={
              trackingTitle
                ? videoSelector
                  ? `${trackingTitle} – ${currentLabel()}`
                  : trackingTitle
                : 'VoD'
            }
            src={currentSrc}
            track={track}
            ref={videoElemRef}
          ></VideoWithControls>
        </AspectRatioContainer>
      </VodVideoContainer>
    </VodOuter>
  )
}

const VodOuter = styled.div<VodVideoContainer>(
  ({ fullWidth }) => `

  display: grid;
  ${mq[3]}{
    grid-template-columns: ${fullWidth ? '' : '20vw'} auto;
  grid-template-rows: 1;
  }
  

`
)

const VodTrackNavOuter = styled.div(
  ({ theme: { colors, grid } }) => `
  grid-column: 1 / 2;
  display:none;
  ${mq[3]}{
    display:block;
  }
  height:300px;
  select{
    margin-bottom:10px;
  }
  `
)

const VodTrackNav = styled(PerfectScrollbar)(
  ({ theme: { colors, grid } }) => `
  height:300px;
  `
)
const VodVideoContainer = styled.div<VodVideoContainer>(
  ({ fullWidth }) => `
  grid-column: ${fullWidth ? 1 : 2} / 2;
    & > div > div:first-of-type {
      height:100%;
    }
    ${cq('LoadingSpinnerContainer')} {
      display:none;
    }

  `
)
const VodTrackItem = styled(Button)(
  ({ theme: { colors, grid } }) => `
  text-align:left;
  padding-left:5px;
  &.active{
    color:white;
  }
`
)
{
  /*     <AspectRatioContainer xRatio={16} yRatio={9} boundingBox={paddingBox}>
   */
}
