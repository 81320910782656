import styled from '@emotion/styled'
import React from 'react'
import { SieStar, SieStarFilled } from '../icons'
import { mq } from './../../../utility/breakpoint'
type InputProps = Omit<JSX.IntrinsicElements['input'], 'ref'>

export interface BookmarkProps extends InputProps {
  ref?: React.Ref<HTMLInputElement>
  label?: string
}

export const Bookmark = React.forwardRef<HTMLInputElement, BookmarkProps>(
  ({ ...rest }, ref) => (
    <StyledLabel>
      <StyledInput ref={ref} type="checkbox" {...rest}></StyledInput>
      <SieStar size={'20px'} />
      <SieStarFilled className="active" size={'20px'} />
      {rest.label ? rest.label : ''}
    </StyledLabel>
  )
)

const StyledLabel = styled.label(({ theme: { colors, fonts } }) => ({
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  'svg.active': { display: 'none' },
  svg: {
    paddingTop: '0.2rem',
    fill: colors.link,
  },
  '& input:checked ~ svg': {
    '&.active': {
      display: 'inline-block',
      fill: colors.inputBorderHover,
    },
    '&:not(.active)': {
      display: 'none',
    },
  },
  [mq[1]]: {
    '&:hover': {
      '& svg': {
        fill: colors.inputBorderHover,
      },
    },
  },
}))

const StyledInput = styled.input({
  display: 'none',
})
