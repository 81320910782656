import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languages } from './../config/languages'

const resources = {}

languages.languages.forEach(
  ({ key }) => (resources[key] = require(`./${key}.json`))
)

i18next.use(initReactI18next).init({
  resources,
  lng: 'en',
})

i18next.languages = languages.languages.map(({ key }: { key: string }) => key)

export default i18next
