import styled from '@emotion/styled'
import { mq } from '../../../utility'
import { getSpacingString, ISpacing } from '../../../utility/styleProps'

type TDisplayOptions = 'hide' | 'show'

enum EDisplay {
  None = 'none',
  Table = 'table',
}

interface ITable {
  spacing?: ISpacing
  borderSpacing?: string
  displayOnMobile?: TDisplayOptions
}

export const Table = styled.table<ITable>(
  ({ theme: { fonts, colors }, spacing, borderSpacing, displayOnMobile }) => {
    const mobileDisplay = (
      onMobile: boolean,
      dm: TDisplayOptions | undefined
    ): string => {
      switch (dm) {
        case 'hide':
          return onMobile ? EDisplay.None : EDisplay.Table
        case 'show':
          return onMobile ? EDisplay.Table : EDisplay.None
        default:
          return EDisplay.Table
      }
    }

    return {
      color: colors.text,
      fontFamily: fonts.fontFamily,
      fontSize: fonts.fontSize,
      lineHeight: '28px',
      borderCollapse: borderSpacing ? 'separate' : 'collapse',
      borderSpacing: borderSpacing ? `${borderSpacing} 0` : 0,
      width: `calc(100% + 2*${borderSpacing})`,

      marginLeft: borderSpacing
        ? `-${borderSpacing}`
        : getSpacingString(spacing?.left),
      marginTop: getSpacingString(spacing?.top),
      marginRight: getSpacingString(spacing?.right),
      marginBottom: getSpacingString(spacing?.bottom),

      // Display properties:
      display: mobileDisplay(true, displayOnMobile),
      [mq[3]]: {
        display: mobileDisplay(false, displayOnMobile),
      },
    }
  }
)
