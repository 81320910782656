import styled from '@emotion/styled'
import React from 'react'
import { Button, SieArrow } from '../../atoms'

interface AccordionElement {
  open?: boolean
}

export interface AccordionEntry {
  [index: undefined]: [title: string, node: React.ReactNode, open?: boolean]
}

export interface AccordionProps {
  data: AccordionEntry[]
}

export const Accordion: React.FC<AccordionProps> = ({ data }) => {
  const [currentData, setCurrentData] = React.useState(data)

  const toggleOpen = (index: number) => {
    const newData = currentData.map(
      ([title, component, open], currentIndex) => {
        if (currentIndex === index) {
          return [title, component, !open]
        }

        return [title, component, false]
      }
    )
    setCurrentData(newData)
  }

  return (
    <div>
      {currentData.map(([title, component, open], index) => {
        return (
          <AccordionElement key={index}>
            <AccordionHeader
              open={open === true}
              onClick={() => toggleOpen(index)}
              plain
              small
              icon={SieArrow}
              iconAlign="right"
            >
              {title}
            </AccordionHeader>
            <AccordionContent open={open === true}>
              {component}
            </AccordionContent>
          </AccordionElement>
        )
      })}
    </div>
  )
}

const AccordionHeader = styled<AccordionElement>(Button)(
  ({ theme: { grid }, open }) => `
  display:flex;
  width:100%;
  justify-content:space-between;
  margin-bottom:10px;
  svg{
    transform:rotate(${open ? '90deg' : '0deg'});
  }
`
)
const AccordionContent = styled.div<AccordionElement>(
  ({ open }) => `
  display:${open ? 'block' : 'none'}
`
)

const AccordionElement = styled.div<AccordionElement>(
  ({ open }) => `
  margin-bottom:20px;
`
)
