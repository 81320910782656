import { GenIcon, IconBaseProps } from 'react-icons'
export const SieHome = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M12,2 L2,10 L2,22 L10,22 L10,16 L14,16 L14,22 L22,22 L22,10 L12,2 Z M20,20 L16,20 L16,14 L8,14 L8,20 L4,20 L4,11 L12,4.6 L20,11 L20,20 Z',
        },
        child: [],
      },
    ],
  })(props)
