import styled from '@emotion/styled'
import {
  isLivestreamSimulatorState,
  LiveStreamSimulator,
  LiveStreamSimulatorRef,
  LivestreamSimulatorState,
  LivestreamSimulatorStateType,
  LiveVideo,
  StateId,
  TimedRenderer,
  TimeUtil,
} from 'cuenect-web-core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramEvent, ProgramService } from '../../../api/program'
import { Button, Paragraph } from '../../atoms'
import {
  Heading,
  IProgramEntry,
  ProgramEntry,
  Video,
  LineContainer,
} from './../../'
import { eventConfig } from './../../../config'
import {
  LivestreamTransformer,
  ProgramEntryExtProps,
  ProgramTransformer,
} from './../../../transformers'
import { mq, useTimeRange } from './../../../utility'
import cq from './../../../utility/coreQuery'
import { navigate } from 'gatsby-link'
import parse from 'html-react-parser'
export interface LiveStreamContainerProps {
  source: string
  slug?: string
}

const BEFORE_EVENT1_START = '2021-11-30T07:30Z'
const BEFORE_EVENT1_END = '2021-11-30T08:00Z'
const BEFORE_EVENT2_START = '2021-11-30T10:00Z'
const BEFORE_EVENT2_END = '2021-11-30T14:00Z'
const AFTER_STREAM = '2021-11-30T16:00Z'

const AFTER_EVENT_TEASER = '2021-12-01T14:00Z'

const BEFORE_EVENT1_KEY = 'overlay.beforeEvent1'
const BEFORE_EVENT2_KEY = 'overlay.beforeEvent2'
const WHILE_STREAM_KEY = 'overlay.whileStream'
const AFTER_EVENT_KEY = 'overlay.afterEvent'

interface LiveStreamContainerState {
  streamData: LiveVideo[]
  loading?: boolean
  isFiller?: boolean
  currentSessionId: string
  nextSessionId: string
  entriesRaw?: ProgramEvent[]
  currentSession?: ProgramEntryExtProps
  nextSession?: ProgramEntryExtProps
}

export const LiveStreamContainer: React.FC<LiveStreamContainerProps> = ({
  source,
  slug,
}) => {
  const { t } = useTranslation('live')

  const [state, setState] = React.useState<LiveStreamContainerState>({
    streamData: [],
    loading: true,
    isFiller: false,
    currentSessionId: '',
    nextSessionId: '',
  })

  const displayTimezone = TimeUtil.getUsersTimezone() || eventConfig.timezone

  const getIsBeforeEvent = () => {
    return TimeUtil.getUtc(BEFORE_EVENT1_START).isAfter(TimeUtil.getNowUtc())
  }

  const getIsAfterEvent = () => {
    const now = TimeUtil.getNowUtc()

    return now.isAfter(AFTER_STREAM)
  }

  const getTextForStreamOverlay = () => {
    const now = TimeUtil.getNowUtc()

    if (now.isAfter(BEFORE_EVENT1_START) && now.isBefore(BEFORE_EVENT1_END)) {
      return t(BEFORE_EVENT1_KEY)
    }

    if (now.isAfter(BEFORE_EVENT2_START) && now.isBefore(BEFORE_EVENT2_END)) {
      return t(BEFORE_EVENT2_KEY)
    }

    return t(WHILE_STREAM_KEY)
  }

  const getOverlay = () => {
    const text = (() => {
      if (getIsBeforeEvent()) {
        return t(BEFORE_EVENT1_KEY)
      }

      if (getIsAfterEvent()) {
        return t(AFTER_EVENT_KEY)
      }

      return getTextForStreamOverlay()
    })()

    return (
      <LiveStreamOverlay>
        <LineContainer>
          <LiveStreamOverlayHeading type="h4">
            {parse(text)}
          </LiveStreamOverlayHeading>
        </LineContainer>
      </LiveStreamOverlay>
    )
  }

  const handleStateChange = (simulatorState: LivestreamSimulatorState) => {
    const isFiller = [StateId.FILLER, StateId.END].includes(
      simulatorState.stateId
    )

    if (state.isFiller !== isFiller) {
      setState(s => ({ ...s, isFiller }))
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_LOADING>(
        simulatorState,
        StateId.LOADING
      )
    ) {
      const currentSessionId = (() => {
        const isInRange = useTimeRange(
          simulatorState.currentVideo.startUtc.format(),
          simulatorState.currentVideo.endUtc.format()
        )

        if (!simulatorState.currentVideo.id || !isInRange) {
          return ''
        }

        return simulatorState.currentVideo.id
      })()

      const nextSessionId =
        simulatorState.nextVideos.length > 0
          ? simulatorState.nextVideos[0].id
          : ''

      if (
        currentSessionId !== state.currentSessionId ||
        nextSessionId !== state.nextSessionId
      ) {
        setState(s => ({ ...s, currentSessionId, nextSessionId }))
      }
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_INITIAL>(
        simulatorState,
        StateId.INITIAL
      )
    ) {
      const nextSessionId =
        simulatorState.videos.length > 0 ? simulatorState.videos[0].id : ''

      if (state.nextSessionId !== nextSessionId) {
        setState(s => ({ ...s, nextSessionId }))
      }
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_END>(
        simulatorState,
        StateId.END
      )
    ) {
      if (state.currentSessionId !== '' || state.nextSessionId !== '') {
        setState(s => ({ ...s, currentSessionId: '', nextSessionId: '' }))
      }
    }
  }

  React.useEffect(() => {
    if (state.entriesRaw) {
      // IIFE
      const currentSession = (() => {
        if (!state.currentSessionId) {
          return
        }

        return ProgramTransformer.getSessionById(
          state.currentSessionId,
          state.entriesRaw,
          {
            displayTimezone,
            showAppointment: true,
          }
        )
      })()

      const nextSession = (() => {
        if (!state.nextSessionId) {
          return
        }

        return ProgramTransformer.getSessionById(
          state.nextSessionId,
          state.entriesRaw,
          {
            displayTimezone,
            showAppointment: true,
          }
        )
      })()

      setState(s => ({
        ...s,
        currentSession: currentSession || s.currentSession,
        nextSession: nextSession || s.nextSession,
      }))
    }
  }, [state.currentSessionId, state.nextSessionId, state.entriesRaw])

  // returns a custom time string
  const parseCustom = (session: ProgramEntryExtProps) => (
    <>
      {TimeUtil.convertToTimeZone(session.startUtc, displayTimezone).format(
        'HH:mm'
      )}
      {session.endUtc &&
        ` - ${TimeUtil.convertToTimeZone(
          session.endUtc,
          displayTimezone
        ).format('HH:mm')}`}
    </>
  )

  /**
   * Get the agenda
   */
  React.useEffect(() => {
    ;(async () => {
      const apiResponse: ProgramEvent[] = await ProgramService.getProgram(
        source,
        slug,
        true
      )

      setState(s => ({
        ...s,
        entriesRaw: apiResponse,
        streamData: LivestreamTransformer.transform({ apiResponse }),
        loading: false,
      }))
    })()
  }, [])

  const getVideoOverlay = () => {
    return state.isFiller ? getOverlay() : null
  }

  return (
    <LiveStreamPlayerOuter>
      {!state.loading && (
        <>
          <LiveStreamPlayer>
            <LiveStreamPlayerRatio>
              <LiveStreamSimulator
                player={Video}
                startVideoType="autoplay_muted_topcontrols"
                videos={state.streamData}
                fadeOut
                loadingSpinnerDealyMs={4000}
                fadeToBlackMs={650}
                onStateChange={handleStateChange}
                videoProps={{
                  renderTopLeft: getVideoOverlay,
                  playerName: 'Live',
                  trackingTitle: state.currentSession?.title
                    ? `${state.currentSession?.title}||${JSON.stringify({
                        uid: state.currentSession.id,
                      })}`
                    : 'Filler Video',
                }}
                fillerVideo={'/assets/Cognisphere_Fluid07_preview_16_9.mp4'}
                endVideo={'/assets/Cognisphere_Fluid07_preview_16_9.mp4'}
              />
            </LiveStreamPlayerRatio>
          </LiveStreamPlayer>
          <LiveStreamTeaser>
            <TimedRenderer endUtc={AFTER_EVENT_TEASER}>
              <Heading type="h5">{t('teaser.headline')}</Heading>
              <Paragraph>{t('teaser.copy')}</Paragraph>
              <Button onClick={() => navigate('/en/dialog')}>
                {t('teaser.cta')}
              </Button>
            </TimedRenderer>
            <TimedRenderer startUtc={AFTER_EVENT_TEASER}>
              <Heading type="h5">{t('teaserPostEvent.headline')}</Heading>
              <Paragraph>{t('teaserPostEvent.copy')}</Paragraph>
              <Button
                onClick={() =>
                  navigate(
                    'https://new.siemens.com/global/en/company/fairs-events/fairs/formnext.html#Contact'
                  )
                }
              >
                {t('teaserPostEvent.cta')}
              </Button>
            </TimedRenderer>
          </LiveStreamTeaser>
        </>
      )}

      <Spacer />

      {state.currentSessionId !== '' &&
        state.currentSession &&
        !state.currentSession.hidden &&
        (state.currentSession.endUtc.isAfter(TimeUtil.getNowUtc()) ||
          (state.nextSession &&
            state.nextSession.startUtc.isSame(
              TimeUtil.getNowUtc(),
              'day'
            ))) && (
          <SessionsContainer>
            <Heading underlined type="h4">
              {t('currentPresentation')}
            </Heading>
            <ProgramEntry
              toggleOpen={true}
              renderCustom={() =>
                state.currentSession && parseCustom(state.currentSession)
              }
              {...state.currentSession}
            />
          </SessionsContainer>
        )}

      <Spacer />

      {state.nextSessionId !== '' &&
        state.nextSession &&
        !state.nextSession.hidden &&
        state.nextSession.startUtc.isSame(TimeUtil.getNowUtc(), 'day') && (
          <SessionsContainer>
            <Heading underlined type="h5">
              {!state.currentSession
                ? t('firstPresentation')
                : t('nextPresentation')}
            </Heading>
            <ProgramEntry
              renderCustom={() =>
                state.nextSession && parseCustom(state.nextSession)
              }
              {...state.nextSession}
            />
          </SessionsContainer>
        )}
    </LiveStreamPlayerOuter>
  )
}

const Spacer = styled.div`
  grid-column: 1/37;
  margin: 15px 0;
  ${mq[3]} {
    margin: 45px 0;
  }
`
const LiveStreamContainerOuter = styled.div(() => ``)

const LiveStreamPlayerOuter = styled.div(
  ({ theme: { grid } }) => ` display: grid;
  grid-template-columns: repeat(${grid.columns},1fr);
  grid-template-rows: 1fr;
  margin-top:50px;
  z-index:2000;

  ${mq[3]} {
    margin-top:0;
  }
  `
)

const LiveStreamOverlay = styled.div(
  ({ theme: { colors } }) => `
    position:absolute;
    left:40px;
    right:20px;
    top:20px;
    bottom:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:12px;

    text-shadow: 0px 0px 5px rgba(0,0,0,.5);

    ${mq[1]} {
      font-size:32px;
    }
  `
)

const LiveStreamOverlayHeading = styled(Heading)(
  () => `
    font-size:16px;
    text-shadow: 0px 0px 5px rgba(0,0,0,.5);

    ${mq[1]} {
      font-size:32px;
    }
  `
)

const LiveStreamPlayer = styled.div(
  ({ theme: { colors } }) => `
  position:relative;
   background:black;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  grid-column: 1/37;
  ${cq('UnmuteButtonIcon')}{
    svg{ fill:${colors.buttonText}}
  }


  ${mq[3]} {
    grid-column: 1/25;
  }
  `
)

const LiveStreamPlayerRatio = styled.div(
  ({ theme: { colors } }) => `
 position:absolute;
 top:0;
 left:0;
 width:100%;
 height:100%;
 & > div > div:first-of-type {
   height:100%;
 }
 ${cq('video-Black')}{
  background:${colors.bodyBackground};
}
  `
)

const LiveStreamTeaser = styled.div(
  ({ theme: { grid } }) => `
  display:flex;
  align-items: flex-start;
  flex-direction:column;
  justify-content:center;
  grid-column: 1/37;
  ${mq[3]} {
    grid-column: 27/37;
  }
  button{
    text-align:center;
  }
  `
)

const SessionsContainer = styled.div(
  ({ theme: { grid } }) => `
  grid-column: 3/34;
  & > div{
    flex-direction: column;
    & > div:nth-of-type(1){
      padding-top:10px;
    }
    & > div:nth-of-type(2){
      padding-top:15px;
      border-bottom:none;
      & > div > div:nth-of-type(2){
        display:none;
      }
    }
  }
  `
)
