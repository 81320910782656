import styled from '@emotion/styled'
import {
  getSpacingString,
  ISpacing,
  TSizeLevel,
} from '../../../utility/styleProps'

interface ITextProps {
  spacing?: ISpacing
  size?: TSizeLevel
  black?: boolean
  ellipsis?: boolean
}

export const Text = styled.p<ITextProps>(
  ({
    theme: { fonts, colors },
    spacing,
    size = 0,
    color,
    black,
    ellipsis,
  }) => ({
    color: color ? color : colors.text,
    textOverflow: ellipsis ? 'ellipsis' : 'clip',
    fontFamily: black ? fonts.fontFamilyBlack : fonts.fontFamily,
    fontSize: fonts.text.sizes[size],
    lineHeight: fonts.text.lineHeight,
    marginTop: getSpacingString(spacing?.top),
    marginRight: getSpacingString(spacing?.right),
    marginBottom: getSpacingString(spacing?.bottom),
    marginLeft: getSpacingString(spacing?.left),
  })
)
