import { Dayjs, TimeUtil } from 'cuenect-web-core'

export type DateRange = [Dayjs, Dayjs]

interface SalesFormConfig {
  datesRange: DateRange[]
}

export const salesFormConfig: SalesFormConfig = {
  datesRange: [
    [
      TimeUtil.getUtc('2021-11-30 02:00:00'),
      TimeUtil.getUtc('2021-12-01 20:59:59'),
    ],
  ],
}
