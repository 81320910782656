import { GenIcon, IconBaseProps } from 'react-icons'
export const SieInstagram = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M17.4,5H6.6C5.7,5,5,5.7,5,6.6v10.8C5,18.3,5.7,19,6.6,19h10.8c0.9,0,1.6-0.7,1.6-1.6V6.6C19,5.7,18.3,5,17.4,5z M12,9.3c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7S9.3,13.5,9.3,12S10.5,9.3,12,9.3z M17.4,16.9c0,0.3-0.2,0.5-0.5,0.5H7.2c-0.3,0-0.5-0.2-0.5-0.5v-6h1.2c-0.1,0.3-0.1,0.8-0.1,1.1c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3c0-0.4-0.1-0.8-0.1-1.1h1.2L17.4,16.9L17.4,16.9z M17.4,8.7c0,0.3-0.2,0.5-0.5,0.5h-1.6c-0.3,0-0.5-0.2-0.5-0.5V7.1c0-0.3,0.2-0.5,0.5-0.5h1.6c0.3,0,0.5,0.2,0.5,0.5V8.7z',
        },
        child: [],
      },
    ],
  })(props)
