import { AxiosResponse } from 'axios'
import { TimeUtil } from 'cuenect-web-core'
import { SalesFormData } from '../components'
import { ShowroomList } from '../components/organisms/salesFormVirtual/showroomList'

import { HttpService } from './../api/http'
import { apiConfig } from './../config'
export interface SalesUser {
  email: string
  firstname: string
  lastname: string
}
export interface SalesResource {
  email: string
  start: string
  end: string
  title: string
}
interface SalesPost {
  version: string
  description?: string
  start: string
  end: string
  host: SalesUser
  participants: SalesUser[]
  tags: string[]
}

export enum BusinessUnit {
  ADV = 'ADV',
  MC = 'MC',
  T = 'T',
  SW = 'SW',
  TRAINING = 'Training',
}

export interface GetTagsConfig
  extends Pick<SalesFormData, 'businessUnit' | 'country' | 'meetinglanguage'> {}

export class SalesService {
  public static getTags = ({
    businessUnit,
    country,
    meetinglanguage,
  }: GetTagsConfig): string[] => {
    // below, there are IIFEs that add tags depending on the given arguments

    const tagsWithCategory = ((tags: string[]) => {
      if (businessUnit === BusinessUnit.TRAINING) {
        return [...tags, 'Category: Sales Process Training']
      }

      return [...tags, 'Category: Expert Talk by Sales']
    })([])

    const tagsWithBusiness = ((tags: string[]) => {
      if (businessUnit === BusinessUnit.TRAINING) {
        return tags
      }

      return [...tags, `Business Unit: ${businessUnit}`]
    })(tagsWithCategory)

    const tagsWithCountry = ((tags: string[]) => {
      return [...tags, `Country: ${country}`]
    })(tagsWithBusiness)

    const tagsWithLanguage = ((tags: string[]) => [
      ...tags,
      `#${meetinglanguage.toUpperCase()}`,
    ])(tagsWithCountry)

    console.log({ tagsWithLanguage })

    return tagsWithLanguage
  }

  public static parseFormRequest(data: SalesFormData, tags: string[]) {
    const {
      start,
      end,
      hostEmail,
      hostFirstname,
      hostLastname,
      businessUnit,
      country,
      meetinglanguage,
      emaillanguage,
      note,
      timezone,
      showroom,
    } = data

    return {
      version: 1,
      start,
      end,
      host: {
        email: hostEmail,
        firstName: hostFirstname,
        lastName: hostLastname,
        language: emaillanguage,
        description: null,
        timeZone: timezone,
      },
      participants: SalesService.getParticipants(data),
      resources: SalesService.getRecources(data),
      description: note,
      services: showroom?.map(sr => Number(sr)) || [],
      tags,
    }
  }

  public static getParticipants = ({
    email,
    gender,
    firstname,
    lastname,
    emaillanguage,
  }: SalesFormData) => {
    const participants = []
    email.forEach((value, index) => {
      if (value) {
        participants.push({
          email: value,
          address: gender[index],
          firstName: firstname[index],
          lastName: lastname[index],
          language: emaillanguage,
        })
      }
    })

    return participants
  }

  public static getRecources = ({
    expertEmail,
    expertFirstname,
    expertLastname,
    expertStart,
    expertEnd,
    emaillanguage,
    start,
    end,
    timezone,
  }: SalesFormData) => {
    const resources = []
    expertEmail.forEach((value, index) => {
      const hasSameTime: boolean =
        TimeUtil.getUtc(start).isSame(TimeUtil.getUtc(expertStart[index])) &&
        TimeUtil.getUtc(end).isSame(TimeUtil.getUtc(expertEnd[index]))

      if (value) {
        resources.push({
          email: value,
          firstName: expertFirstname[index],
          lastName: expertLastname[index],
          start: hasSameTime ? null : expertStart[index],
          end: hasSameTime ? null : expertEnd[index],
          timeZone: timezone,
          language: emaillanguage,
        })
      }
    })

    return resources
  }

  public static async sendForm(data: SalesFormData, tags: string[]) {
    const parsedData = {
      ...SalesService.parseFormRequest(data, tags),
      location,
    }

    console.log({ parsedData })

    const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.sales,
      data: parsedData,
    })

    console.log({ result })

    return result
  }
}
