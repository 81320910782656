import styled from '@emotion/styled'
import { Speaker, Text } from 'cuenect-web-core'
import React from 'react'
import { mq } from '../../../utility'

interface AvatarImageProps {
  avatar?: string
}

interface IAvatar {
  speaker: Speaker
}

export const Avatar: React.FC<IAvatar> = ({
  speaker: { name, image, occupation },
}) => (
  <AvatarHolder>
    <AvatarImage avatar={image || '/avatar.png'} />
    <AvatarMeta>
      <AvatarName>{name}</AvatarName>
      {occupation && <AvatarOccupation>{occupation}</AvatarOccupation>}
    </AvatarMeta>
  </AvatarHolder>
)

const AvatarHolder = styled.div({
  border: '0',
  marginBottom: '2rem',
  display: 'inline-flex',
})
const AvatarName = styled(Text)(({ theme: { colors, fonts } }) => ({
  fontFamily: fonts.fontFamilyBlack,
  fontSize: fonts.text.sizes[18],
  marginBottom: '10px',
}))

const AvatarOccupation = styled(Text)(({ theme: { colors, fonts } }) => ({
  fontSize: fonts.text.sizes[16],
}))

const AvatarImage = styled.div<AvatarImageProps>(({ avatar }) => ({
  border: '0',
  background: `url(${avatar}) center center / cover`,
  minWidth: '64px',
  height: '80px',
}))

const AvatarMeta = styled.div({
  border: '0',
  marginRight: '1rem',
  padding: '0 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'align',
  [mq[1]]: {
    width: '15rem',
  },
  h5: {
    whiteSpace: 'nowrap',
  },
  Small: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [mq[1]]: {
      whiteSpace: 'inherit',
    },
  },
})
