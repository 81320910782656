import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface TimepickerEntryProps {
  value: dayjs.Dayjs
  onSelect(value: dayjs.Dayjs): void
  selected?: boolean | undefined
  visible?: boolean
}
interface TimepickerEntryPropsElement
  extends Pick<TimepickerEntryProps, 'selected'> {}

export const TimepickerEntry: React.FC<TimepickerEntryProps> = ({
  value,
  selected,
  onSelect,
  visible,
}) => {
  const timeElement = React.useRef()
  React.useEffect(() => {
    if (selected) {
      // SCROLL
      if (timeElement.current) {
        timeElement.current.scrollIntoView({ block: 'nearest' })
      }
    }
  }, [selected, visible])

  return (
    <TimepickerEntryContainer
      selected={selected}
      onClick={() => onSelect(value)}
      ref={timeElement}
    >
      {value.format('HH:mm')}
    </TimepickerEntryContainer>
  )
}
const TimepickerEntryContainer = styled.li<TimepickerEntryPropsElement>(
  ({ theme: { colors }, selected }) => `
  padding:4px 20px;
  font-size:0.8rem;
  cursor:pointer;
  &:hover{
    background:${colors.buttonActiveBackground};
    color:black;
  }
    ${selected && `background:${rgba(colors.buttonActiveBackground, 0.5)};`}
  `
)
