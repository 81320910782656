import { LocalStorageUtil } from 'cuenect-web-core'
import React, { useEffect } from 'react'
import { Auth } from './../api'
import { AUTH_ACTIVE } from './../config'
interface State {
  isAuthenticated?: boolean
  token?: string
  loading?: boolean
  reAuth?: boolean
}

const defaultState: State = {
  isAuthenticated: false,
  token: '',
  loading: true,
  reAuth: false,
}

export interface AuthAction {
  type: 'ERROR' | 'AUTHENTICATE' | 'RE_AUTHENTICATE'
  state?: State
}
const reducer: React.Reducer<State, AuthAction> = (state, action) => {
  switch (action.type) {
    case 'ERROR':
      return {
        ...state,
        ...action.state,
      }
    case 'AUTHENTICATE':
      return {
        ...state,
        ...action.state,
      }
    case 'RE_AUTHENTICATE':
      return {
        ...state,
        ...action.state,
        reAuth: true,
      }
    default:
      return defaultState
  }
}
export const AuthenticationContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<AuthAction>
}>({ state: defaultState, dispatch: () => null })

export const AuthenticationContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  useEffect(() => {
    if (process.env.GATSBY_BYPASS_AUTH === 'true') {
      dispatch({
        type: 'ERROR',
        state: { isAuthenticated: true, token: '', loading: false },
      })

      return true
    }

    const token = new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('token')

    if (
      typeof window !== 'undefined' &&
      document.location.pathname.match(/feedback\/[a-z]{2}/)
    ) {
      dispatch({
        type: 'AUTHENTICATE',
        state: { isAuthenticated: true, loading: false },
      })
      return
    }

    const t: string = token
    if (!Auth.isAuthenticated() && !t) {
      dispatch({
        type: 'ERROR',
        state: { isAuthenticated: false, token: '', loading: false },
      })
    }
    if (t) {
      dispatch({
        type: 'AUTHENTICATE',
        state: { isAuthenticated: false, loading: true },
      })
      Auth.authenticate(t).then(success => {
        dispatch({
          type: 'AUTHENTICATE',
          state: { isAuthenticated: success, loading: false },
        })
      })
    } else if (Auth.isAuthenticated()) {
      dispatch({
        type: 'AUTHENTICATE',
        state: { isAuthenticated: false, loading: true },
      })
      Auth.reAuthenticate().then(success => {
        dispatch({
          type: 'AUTHENTICATE',
          state: { isAuthenticated: success, loading: false },
        })
      })
    }
  }, [])

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = () => React.useContext(AuthenticationContext)
