import styled from '@emotion/styled'
import {
  getSpacingString,
  ISpacing,
  TSizeLevel,
} from '../../../utility/styleProps'

interface ITd {
  noBottomLine?: boolean
  padding?: ISpacing
  size?: TSizeLevel
  black?: boolean
  ellipsis?: boolean
}

export const Td = styled.td<ITd>(
  ({ theme: { fonts, colors }, noBottomLine, padding, black, size = 18 }) => ({
    textAlign: 'left',

    paddingTop: getSpacingString(padding?.top, 1),
    paddingRight: getSpacingString(padding?.right, 1),
    paddingBottom: getSpacingString(padding?.bottom, 1),
    paddingLeft: getSpacingString(padding?.left, 1),

    fontFamily: black ? fonts.fontFamilyBlack : fonts.fontFamily,
    fontSize: fonts.text.sizes[size],
    verticalAlign: 'baseline',
    color: colors.tableColor,
    borderBottom: noBottomLine ? 'none' : `1px solid ${colors.tableBorderLine}`,
    lineHeight: '28px',
  })
)
