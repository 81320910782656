import { TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import {
  GroupedProgramCategory,
  Participations,
  ProgramCategory,
  ProgramEvent,
  ProgramLinks,
} from '../api/program'
import { IProgramEntry } from '../components/molecules/programEntry'
import { useTimeRange } from './../utility'
export interface ProgramEntryExtProps extends IProgramEntry {
  durationMs?: number
  startVodUtc?: dayjs.Dayjs
  showReservations?: boolean
  registered: boolean
  meetingUrl?: string
  hidden?: boolean
}

type Location = 'on-site' | null

interface ProgamEntryConfig {
  displayTimezone: string
  showReservations?: boolean
  showAppointment?: boolean
  participations?: string[]
  showOnlyParticipations?: boolean
  rawParticipations?: Participations
}

export interface RedirectProps {
  found: boolean
  selectedDate: number
  uid: string
  title: string
  isLive: boolean
  openVod: boolean
  videoUrl: string
}

export class ProgramTransformer {
  public static transform = ({
    apiResponse,
    cta = () => null,
    customContent = () => null,
    displayTimezone = 'Europe/Berlin',
    filters = [],
    bookmarks = [],
    showOnlyBookmarks = false,
    showReservations = false,
    showAppointment = false,
    participations = [],
    rawParticipations = undefined,
    showOnlyParticipations = false,
  }: {
    apiResponse: ProgramEvent[]
    customContent: Function
    cta: Function
    displayTimezone: string
    filters: string[]
    bookmarks: string[]
    participations: string[]
    showOnlyBookmarks: boolean
    showReservations: boolean
    showAppointment: boolean
    rawParticipations?: Participations
    showOnlyParticipations?: boolean
  }): IProgramEntry[][] => {
    const preFilterParticipations: ProgramEvent[] = ProgramTransformer.filterParticipations(
      apiResponse,
      participations,
      rawParticipations,
      showOnlyParticipations
    )

    const mappedData = ProgramTransformer.mapData({
      apiResponse: preFilterParticipations,
      displayTimezone,
      showReservations,
      showAppointment,
      participations,
      showOnlyParticipations,
    })

    const filteredData = showAppointment
      ? mappedData
      : ProgramTransformer.filterData(
          mappedData,
          filters,
          bookmarks,
          showOnlyBookmarks
        )

    // Sort & Group by Date
    return ProgramTransformer.groupByDay(
      ProgramTransformer.sortDates(
        ProgramTransformer.addCustom(filteredData, customContent, cta)
      )
    )
  }
  public static hasBookmarkIds = (
    bookmarks: string[],
    rawData: ProgramEvent[]
  ): boolean => rawData.find(({ id }) => bookmarks.includes(id)) !== undefined

  public static filterParticipations = (
    rawData: ProgramEvent[],
    participations: string[] | undefined,
    rawParticipations: Participations,
    showOnlyParticipations: boolean
  ): ProgramEvent[] => {
    if (showOnlyParticipations) {
      return rawData
        .filter(
          ({ id, isAppointment }) =>
            isAppointment || (participations && participations.includes(id))
        )
        .map(session => ({
          ...session,
          meeting: rawParticipations[session.id]
            ? rawParticipations[session.id].meetingURL
            : session.meeting,
        }))
    }

    return rawData.map(session => ({
      ...session,
      meeting:
        rawParticipations && rawParticipations[session.id]
          ? rawParticipations[session.id].meetingURL
          : session.meeting,
    }))
  }

  public static getSessionById = (
    liveStreamId: string,
    rawData: ProgramEvent[],
    config: ProgamEntryConfig
  ) => {
    const entry = rawData.find(({ id }) => id === liveStreamId)
    if (!entry) {
      return false
    }

    return ProgramTransformer.parseEntry(entry, config)
  }

  public static mapData = ({
    apiResponse,
    displayTimezone,
    showReservations,
    showAppointment,
    participations,
    showOnlyParticipations,
    location,
  }: {
    apiResponse: ProgramEvent[]
    displayTimezone: string
    showReservations: boolean
    showAppointment: boolean
    participations: string[]
    showOnlyParticipations?: boolean
  }): ProgramEntryExtProps[] =>
    apiResponse.map(entry =>
      ProgramTransformer.parseEntry(entry, {
        displayTimezone,
        showReservations,
        showAppointment,
        participations,
        showOnlyParticipations,
      })
    )

  public static parseEntry = (
    data: ProgramEvent,
    config: ProgamEntryConfig
  ) => {
    const {
      id,
      title,
      category,
      description,
      start,
      end,
      startPlayback,
      persons,
      links,
      categories,
      participants,
      isBreak,
      shareable,
      room,
      eventSlug,
      isAppointment,
      meeting,
      hidden,
      location,
    } = data

    const {
      displayTimezone,
      showReservations,
      showAppointment,
      participations,
      showOnlyParticipations,
    } = config

    const ret: ProgramEntryExtProps = {
      id,
      title,
      category: category?.title || undefined,
      participants,
      description,
      startUtc: TimeUtil.getUtc(start),
      endUtc: TimeUtil.getUtc(end),
      durationMs: TimeUtil.getUtc(end).diff(TimeUtil.getUtc(start)),
      startVodUtc: TimeUtil.getUtc(startPlayback || ''),
      speakers:
        persons?.map(({ name, profileImageURL, occupation }) => ({
          name,
          image: profileImageURL,
          occupation,
        })) || [],
      displayTimezone,
      categories,
      isBreak,
      showReservations,
      showAppointment,
      shareable,
      registered: participations?.includes(id) || false,
      showOnlyParticipations,
      isBlockbuster:
        categories &&
        categories?.find(({ slug }) => slug === 'sys-blockbuster-color')
          ? true
          : false,
      room,
      eventSlug,
      isAppointment,
      meetingUrl: meeting || '',
      hidden,
      location,
    }

    const video: string | null | undefined = links
      ? ProgramTransformer.getVideo(links)
      : null
    if (video) {
      ret.videoUrl = video
    }

    return ret
  }

  public static addCustom = (
    data: IProgramEntry[],
    customContent: Function,
    cta: Function
  ): IProgramEntry[] => {
    return data.map((session: IProgramEntry) => {
      return {
        ...session,
        renderCta: () => cta(session),
        renderCustom: () => customContent(session),
      }
    })
  }

  public static filterData = (
    data: ProgramEntryExtProps[],
    filters: string[],
    bookmarks: string[],
    showOnlyBookmarks: boolean
  ) => {
    let fData = data

    if (showOnlyBookmarks) {
      fData = fData.filter(({ id }) => bookmarks.includes(id))
    }

    if (filters.length < 1) {
      return fData
    }

    return fData.filter(({ categories, room }) => {
      if (!categories || categories.length === 0) {
        return true
      }
      let found = false
      categories.forEach(({ slug }) => {
        if (filters.includes(slug)) {
          found = true
        }
      })
      if (room && filters.includes(room.slug)) {
        found = true
      }

      return found
    })
  }

  public static getVideo = (links: Array<ProgramLinks>) => {
    const { href } = links.find(link => link.type === 'video') || {}

    return href
  }

  public static sortDates = (data: IProgramEntry[]): IProgramEntry[] => {
    return data.sort((a: IProgramEntry, b: IProgramEntry) =>
      a.startUtc.isSame(b.startUtc)
        ? 0
        : a.startUtc.isBefore(b.startUtc)
        ? -1
        : 1
    )
  }

  public static groupByDay = (data: IProgramEntry[]): IProgramEntry[][] => {
    const resultData: IProgramEntry[] = data.reduce((result, item) => {
      const day: string = item.startUtc.format('M/D/YYYY')

      return {
        ...result,
        // @ts-ignore: need to fix
        [day]: [...(result[day] || []), item],
      }
    }, [])

    // @ts-ignore: need to fix
    return Object.keys(resultData).map(key => resultData[key])
  }

  public static getPeriod = (programEntriesRaw: ProgramEvent[]) => {
    // TimeUtil.getUtc(start)
    const startDate = programEntriesRaw
      .map(({ start }) => TimeUtil.getUtc(start))
      //@ts-ignore NEED TO FIX
      .sort((a, b) => b.isBefore(a))[0]

    const endDate = programEntriesRaw
      .map(({ end }) => TimeUtil.getUtc(end))
      //@ts-ignore NEED TO FIX
      .sort((a, b) => b.isBefore(a))[0]

    return { startDate, endDate }
  }

  public static getFilters(programEntriesRaw: ProgramEvent[]) {
    let filters = []
    programEntriesRaw.forEach(({ categories }) => {
      if (categories) {
        filters = [...filters, ...categories]
      }
    })

    const setObj = new Set() // create key value pair from array of array

    const result = filters.reduce((acc, item) => {
      if (!setObj.has(item.slug)) {
        setObj.add(item.slug, item)
        acc.push(item)
      }

      return acc
    }, [])

    return result
  }

  public static getRooms(programEntriesRaw: ProgramEvent[]) {
    let rooms = []

    programEntriesRaw.forEach(({ room }) => {
      if (room) {
        rooms = [...rooms, room]
      }
    })

    const setObj = new Set() // create key value pair from array of array

    const result = rooms.reduce((acc, item) => {
      if (!setObj.has(item.slug)) {
        setObj.add(item.slug, item)
        acc.push(item)
      }

      return acc
    }, [])

    return result
  }

  public static groupCategoryFilter = (
    categories: ProgramCategory[]
  ): GroupedProgramCategory[] => {
    const filter1: ProgramCategory[] = []
    const filter2: ProgramCategory[] = []
    const languages: ProgramCategory[] = []
    const ret: GroupedProgramCategory[] = []
    categories.forEach(filter => {
      if (filter.slug.match(/^1\-/)) {
        filter1.push(filter)
      } else if (filter.slug.match(/^2\-/)) {
        filter2.push(filter)
      } else if (filter.slug.match(/^lang\-/)) {
        languages.push(filter)
      }
    })

    if (filter1.length) {
      ret.push({ type: 'programFilter', filters: filter1 })
    }
    if (filter2.length) {
      ret.push({ type: 'programFilterAlt', filters: filter2 })
    }
    if (languages.length) {
      ret.push({ type: 'programFilterLang', filters: languages })
    }

    return ret
  }

  public static handleRedirect(
    entries: IProgramEntry[][]
  ): RedirectProps | undefined {
    const isStandalone = process.env.GATSBY_STANDALONE

    const uid = new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('uid')

    if (!uid) {
      return
    }

    let selectedDate = 0
    let found = null
    let foundEntry: IProgramEntry | null = null

    entries.forEach((day: IProgramEntry[], i) => {
      const entry: IProgramEntry | null =
        day.find(({ id }) => id === uid) || null
      if (entry) {
        foundEntry = entry
        found = true
        selectedDate = i
      }
    })

    if (!foundEntry) {
      return
    }

    const diff = TimeUtil.getDuration(
      TimeUtil.getUtc(foundEntry.start).diff(TimeUtil.getNowUtc())
    ).asMinutes()

    return {
      found,
      selectedDate,
      uid,
      title: foundEntry.title,
      isLive:
        (useTimeRange(
          foundEntry.startUtc.format(),
          foundEntry.endUtc.format()
        ) ||
          TimeUtil.getNow().isBefore(foundEntry.endUtc.format())) &&
        foundEntry.videoUrl,
      openVod: diff < 0 && !isStandalone,
      videoUrl: foundEntry.videoUrl,
    }
  }
}
