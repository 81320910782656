interface ShowroomConfig {
  url: string
  staticUrl?: string
  assets: string
}
/* export const showroomConfig: ShowroomConfig = {
  url:
    'https://dvt4t9p29wi8.cloudfront.net/module/content/api/v1/depot/terminals/HM21Vi_0/allcontents.json',
  assets:
    'https://dvt4t9p29wi8.cloudfront.net/module/content/api/v1/depot/asset/',
} */

export const showroomConfig: ShowroomConfig = {
  url: '/mockData/allcontents.json',
  staticUrl: '/assets/showroom/data.json',
  assets:
    'https://dvt4t9p29wi8.cloudfront.net/module/content/api/v1/depot/asset/',
}
