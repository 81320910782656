import { GenIcon, IconBaseProps } from 'react-icons'
export const SieArrowBack = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M6.4,11.9L16.3,2l1.3,1.3L10.1,12l7.5,8.6l-1.3,1.3L6.4,11.9L6.4,11.9',
        },
        child: [],
      },
    ],
  })(props)
