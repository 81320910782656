import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import { navigate } from 'gatsby-link'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React from 'react'
import { isMobile as internalIsMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Button,
  LoadingIndicator,
  Paragraph,
  SieArrow,
  SieClose,
  SieMenuDots,
  SiePlayFilled,
} from '../../atoms'
import { Cognisphere } from '../../molecules'
import { ShowroomBooth } from './'
import {
  HttpService,
  ShowroomData,
  ShowroomItem,
  ShowroomService,
} from './../../../api'
import { isPostEventSecond } from './../../../config'
import { mq, useModalContext, useVodContext } from './../../../utility'
import { PreselectGuidedTour } from './preselectGuidedTour'

export interface BoothRatio {
  x: number
  y: number
}
export interface ContainerProps {
  showMenu: boolean
}

interface BoothContainer {
  boothRatio: BoothRatio
}

export const ShowRoom = () => {
  const boothSize: BoothRatio = { x: 1920, y: 888 }
  const { t } = useTranslation(['showroom', 'core'])

  const [scrollBarRef, setScrollBarRef] = React.useState()
  const [scrollbarPos, setScrollbarPos] = React.useState(0)
  const [manualScroll, setManualScroll] = React.useState(true)
  const [showMenu, setShowMenu] = React.useState(false)
  const [isMobile, setIsMobile] = React.useState(
    document.documentElement.clientWidth < 769
  )

  const _scrollBarRef = React.useRef()

  const { dispatch: openVod } = useVodContext()
  const { dispatch: modalDispatch } = useModalContext()

  const [showroomContent, setShowroomContent] = React.useState<
    ShowroomItem[] | null
  >(null)

  const [showroomGuidedTours, setShowroomGuidedTours] = React.useState<
    ShowroomItem[] | null
  >(null)

  const [activeElement, setActiveElement] = React.useState<string>('')

  const getContent = async () => {
    const data: ShowroomData = await ShowroomService.getStaticData({
      language: i18n.language,
    })

    setShowroomContent(data.experts)
    setShowroomGuidedTours(data.guidedTours)
  }

  const scrollNavTo = (slug: string) => {
    setManualScroll(false)
    if (document.getElementById(slug)) {
      scrollBarRef.scrollTop = document.getElementById(slug).offsetTop
    } else {
      scrollBarRef.scrollTop = scrollbarPos
    }
    setTimeout(() => {
      setManualScroll(true)
    }, 100)
  }

  React.useEffect(() => {
    getContent()
  }, [i18n.language])

  React.useEffect(() => {
    if (showroomGuidedTours) {
      // Open Guidedtour
      if (document.location.hash === '#guided-tour') {
        openGuidedTour()
      }
    }
  }, [showroomGuidedTours])

  const checkWindowSize = () => {
    setIsMobile(document.documentElement.clientWidth < 769)
  }

  React.useEffect(() => {
    window.addEventListener('resize', checkWindowSize)

    return () => {
      window.removeEventListener('resize', checkWindowSize)
    }
  }, [])

  const openGuidedTour = () => {
    openVod({
      type: 'OPEN_VOD',
      src: showroomGuidedTours.find(({ label }) => label === 'English')
        .video /* t('cta.guidedTours.src') */,
      track: t('cta.guidedTours.track'),
      trackingTitle: 'Guided Tour Video',
      videoSelector: showroomGuidedTours,
      playerName: 'Guided Tour',
    })
  }

  const guidedTourVideoButton = (
    <Button
      onClick={() => {
        setActiveElement('')
        openGuidedTour()
      }}
    >
      {t('cta.guidedTours.label')}
    </Button>
  )

  const guidedTourButton = isPostEventSecond() ? (
    <></>
  ) : (
    <Button
      secondary
      icon={SieArrow}
      iconAlign="right"
      active
      onClick={() => navigate(`/${i18n.language}/program/guidedtours`)}
    >
      {t('cta.tourSchedules')}
    </Button>
  )

  return (
    <ShowRoomContainer showMenu={showMenu}>
      {showroomContent && (
        <ShowRoomInner>
          <Cognisphere
            fullscreen
            asImage={isMobile}
            visual={`/assets/showroom/${
              isMobile ? 'showroom-bg.jpg' : 'bg_loop.mp4'
            }`}
          />

          <ShowRoomNav className={`${showMenu ? 'show' : 'hide'}`}>
            {isMobile && (
              <ShowRoomNavClose>
                <Button
                  icon={SieClose}
                  plain
                  small
                  iconAlign="right"
                  onClick={() => setShowMenu(false)}
                >
                  {t('core:close')}
                </Button>
              </ShowRoomNavClose>
            )}
            <ShowRoomNavInnerContainer>
              <ShowRoomNavInner
                containerRef={ref => {
                  setScrollBarRef(ref)
                }}
                onScrollY={() => {
                  manualScroll && setScrollbarPos(scrollBarRef.scrollTop)
                }}
              >
                {showroomContent.map(elem => (
                  <ShowRoomNavItem
                    key={elem.slug}
                    id={elem.slug}
                    menuItem
                    icon={SieArrow}
                    iconAlign="right"
                    onMouseOver={() => setActiveElement(elem.slug)}
                    onMouseOut={() => setActiveElement('')}
                    onClick={() => {
                      openVod({
                        type: 'OPEN_VOD',
                        trackingTitle: `${elem.label}||${JSON.stringify({
                          uid: elem.slug,
                        })}`,
                        src: elem.video,
                        playerName: 'Showroom Highlights',
                      })
                      setActiveElement('')
                    }}
                    active={activeElement !== '' && activeElement === elem.slug}
                  >
                    <span>{elem.label}</span>
                  </ShowRoomNavItem>
                ))}{' '}
                <ShowRoomNavInnerSpacer />
                {!isMobile && (
                  <AppointmentButton>{guidedTourVideoButton}</AppointmentButton>
                )}
              </ShowRoomNavInner>
            </ShowRoomNavInnerContainer>
          </ShowRoomNav>

          {!isMobile && (
            <ShowRoomBoothOuter>
              <div>
                <BoothContainer boothRatio={boothSize}>
                  <ShowroomBooth
                    onHighlight={slug => {
                      scrollNavTo(slug)

                      setActiveElement(slug)
                    }}
                    activeElement={activeElement}
                    boothRatio={boothSize}
                    data={showroomContent}
                  />
                </BoothContainer>
              </div>
            </ShowRoomBoothOuter>
          )}

          {isMobile && (
            <ShowRoomBoothMobile>
              <div className="btn-menu">
                <Button
                  plain
                  active
                  small
                  icon={SieMenuDots}
                  onClick={() => setShowMenu(true)}
                >
                  Show all videos
                </Button>
              </div>
              <div className="img-container">
                <img src={'/assets/showroom/island.png'} />
              </div>
              <div className="btn-container">
                {guidedTourVideoButton}
                <br />
                {guidedTourButton}
              </div>
            </ShowRoomBoothMobile>
          )}
          <IntroText>{parse(t('intro'))}</IntroText>
          {!isMobile && <TourButtons>{guidedTourButton}</TourButtons>}
        </ShowRoomInner>
      )}

      {!showroomContent && <Loading />}
    </ShowRoomContainer>
  )
}

const ShowRoomContainer = styled.div<ContainerProps>(
  ({ theme: { colors, grid }, showMenu }) => `
  width:100%;
  overflow-x:hidden;
  overflow-y:hidden;
  height:${showMenu ? 'auto' : 'calc(100vh)'};

  ${mq[2]}{
    padding-top:73px;
    height:calc(100vh - 67px);

  }
  ${mq[3]}{
    height:calc(100vh - 132px);
    padding-top:0;
  }
`
)
const Loading = styled(LoadingIndicator)(
  ({ theme: { colors, grid } }) => `
  position: absolute;
    left: 50%;
    top: 50%;
`
)

const ShowRoomInner = styled.div<ContentContainerProps>(
  ({ theme: { colors, grid } }) => `
  position:relative;
  grid-column: 3/35;
  display:grid;
  grid-template-columns: repeat(${grid.columns - 6},1fr);
  grid-template-rows:2fr;
  ${mq[2]}{
    grid-template-rows:1fr;
    margin-top:20px;
  }
`
)

const ShowRoomNavClose = styled.div(`
display:flex;
justify-content:flex-end;
padding:7px 15px;
`)

const ShowRoomNav = styled.div(
  ({ theme: { colors, grid } }) => `
  grid-column: 1/30;
z-index:2;
  position:absolute;
  left:0;
  padding-top:94px;
  height:100vh;
  width:100vw;
  display:none;
  background:${colors.bodyBackground};

  &.show{
    display:block;
  }
  ${mq[2]}{
    display:block;
    position:relative;
    left:inherit;
    top:inherit;
    width:inherit;
    overscroll-behavior: contain;
    padding-top:inherit;
    display:flex;
    flex-direction: column;
    grid-column: 1/8;

    grid-row:1;
height:calc(100% + 75px);
    min-width:360px;



  }
  ${mq[3]}{


  }

  `
)

const ShowRoomNavInnerContainer = styled.div(
  ({ theme: { colors, grid } }) => `
  flex:1;
  position:relative;
  width:100%;
  height:100%;
  ${mq[2]}{
    width:inherit;
    height:inherit;
  }

  `
)
const ShowRoomNavInner = styled(PerfectScrollbar)(
  ({ theme: { colors, grid } }) => `
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  box-sizing: border-box;
  ${mq[3]}{

  }
  `
)
const ShowRoomNavInnerSpacer = styled(PerfectScrollbar)(
  ({ theme: { colors, grid } }) => `
  height:25px;
  `
)

const ShowRoomNavItem = styled(Button)(
  ({ theme: { colors, grid } }) => `
  text-align:left;
  padding-left:40px;
  font-size:16px;
  border-top:1px solid transparent;
  span{
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
  &.active{
    color:white;
  }

  &:hover, &.active{
    border-color:${colors.inputBorderHover};
  }
`
)

const AppointmentButton = styled.div(
  ({ theme: { colors, grid } }) => `
  flex:1;
  padding:15px 40px 40px 40px;
  button{width:100%;
    justify-content: center;
    white-space: nowrap;
  }`
)

const ShowRoomBoothMobile = styled.div`
  grid-column: 2/30;
  min-height: 100vh;

  padding-top: 180px;
  .img-container {
    text-align: center;
    img {
      width: 160%;
      margin-left: -30%;
      margin-right: -30%;
      margin-bottom: -20%;
      max-height: 70vh;
    }
  }
  .btn-container {
    button {
      width: 100%;
    }
  }

  @media (orientation: landscape) {
    ${mq[1]} {
      height: calc(100vh - 180px);
      display: flex;
      .btn-menu {
        position: absolute;
      }
      .btn-container {
        order: 1;
        margin-top: 35px;
        button {
          padding: 10px;
          font-size: 18px;
          white-space: nowrap;
        }
      }
      .img-container {
        order: 3;
        img {
          width: 80%;
          margin: 0%;
          max-height: 70vh;
        }
      }
    }
  }
`
const ShowRoomBoothOuter = styled.div`
  grid-column: 2/30;
  height: calc(80vh);
  grid-row: 1;
  ${mq[2]} {
    grid-column: 9/30;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

const BoothContainer = styled.div<BoothContainer>(
  ({ boothRatio }) => `
  max-width: ${(boothRatio.y / boothRatio.x) * 225}vh;
  width: 100vw;
  padding-top: ${(boothRatio.y / boothRatio.x) * 100}%;
  position: relative;
`
)

const TourButtons = styled.div(
  ({ theme: { colors, grid } }) => `
  position:absolute;
  bottom:2.7vw;
  right:2.7vw;
  display:flex;
  align-items:flex-end;
  flex-direction:column;
  button{
    margin-top:10px;
  }
`
)
const IntroText = styled(Paragraph)(
  ({ theme: { colors, grid } }) => `
  position:absolute;
  top:100px;

  left:5vw;
  right:5vw;
  font-size:18px;
  line-height:1.2;
  ${mq[2]}{

    font-size:inherit;
    left:inherit;
    top:2.7vw;
    right:2.7vw;
    width:278px;
  }
`
)
