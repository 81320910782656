import React from 'react'
import { BookmarkService } from './../api'

interface State {
  bookmarks: string[]
}

const defaultState: State = {
  bookmarks: [],
}
export interface BookmarksAction {
  type: 'ADD_BOOKMARK' | 'REMOVE_BOOKMARK' | 'LOADED_BOOKMARK'
  state?: State
  id?: string
  bookmarks?: string[]
}

const reducer: React.Reducer<State, BookmarksAction> = (state, action) => {
  switch (action.type) {
    case 'ADD_BOOKMARK':
      return {
        ...state,
        bookmarks: [...state.bookmarks, action.id],
      }
    case 'REMOVE_BOOKMARK':
      return {
        ...state,
        bookmarks: state.bookmarks.filter(item => item !== action.id),
      }

    case 'LOADED_BOOKMARK':
      return {
        ...state,
        bookmarks: action.bookmarks,
      }
    default:
      return defaultState
  }
}
export const BookmarksContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<BookmarksAction>
}>({ state: defaultState, dispatch: () => null })

export const BookmarksContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  const loadBookmarks = async () => {
    const bookmarks: string[] = await BookmarkService.getBookmarks()
    dispatch({
      type: 'LOADED_BOOKMARK',
      bookmarks,
    })
  }

  // LOAD BOOKMARKS
  React.useEffect(() => {
    loadBookmarks()
  }, [])

  React.useEffect(() => {
    BookmarkService.setBookmarks(state.bookmarks)
  }, [state])

  return (
    <BookmarksContext.Provider value={{ state, dispatch }}>
      {props.children}
    </BookmarksContext.Provider>
  )
}

export const useBookmarksContext = () => React.useContext(BookmarksContext)
