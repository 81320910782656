import React from 'react'
import { Link } from '../components/atoms'

export type TSpacerLevel = 0 | 0.4 | 0.5 | 0.6 | 0.8 | 1 | 1.5 | 2 | 3 | 4 | 5
export type TSizeLevel = 14 | 16 | 17 | 18 | 20 | 24 | 26 | 30
export type TTextAlign = 'left' | 'center' | 'right'

export interface IStyledSpacingLevel {
  '0': {}
  '1': {}
  '2': {}
  '3': {}
  '4': {}
  '5': {}
}

export interface IStyledDistance {
  t: IStyledSpacingLevel
  r: IStyledSpacingLevel
  b: IStyledSpacingLevel
  l: IStyledSpacingLevel
}

export interface ISpacing {
  top?: TSpacerLevel
  right?: TSpacerLevel
  bottom?: TSpacerLevel
  left?: TSpacerLevel
}

export const getSpacingString = (l: TSpacerLevel | undefined, def?: number) => {
  return `${l || l === 0 ? l : def ? def : 0}rem`
}

export const replaceLinkText = (
  text: string,
  toReplace: string,
  linkText: string,
  link: string,
  target?: string
) => {
  const splitted = text.split(toReplace)
  const len = splitted.length

  return splitted.map((a, i) => {
    if (i < len - 1) {
      return (
        <React.Fragment key={i}>
          {a}
          <Link href={link} target={target}>
            {linkText}
          </Link>
        </React.Fragment>
      )
    }

    return a
  })
}
