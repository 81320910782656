import styled from '@emotion/styled'
import { Button } from 'cuenect-web-core'
import React from 'react'
import { ExternalContent } from '../..'
import { fairtouchConfig } from '../../../config'
import { AnalyticsTransformer, useModalContext } from '../../../utility'
import { Furioos, FurioosPlayer } from '../../atoms'

export const FurioosShowroom = React.forwardRef<FurioosPlayer, {}>((_, ref) => {
  const [handCursor, setHandCursor] = React.useState(false)

  return (
    <>
      <FurioosShowroomContainer handCursor={handCursor}>
        <Furioos playerId={String(process.env.GATSBY_PAYER_ID)} ref={ref} />
      </FurioosShowroomContainer>
    </>
  )
})

const ButtonDebug = styled.div`
  button {
    display: inline-block;
    margin-right: 10px;
  }
`
const FurioosShowroomContainer = styled.div<{ handCursor: boolean }>(
  ({ handCursor }) => `
  overflow: hidden;
  cursor:${handCursor ? 'pointer' : 'default'};
`
)
