import { GenIcon, IconBaseProps } from 'react-icons'
export const SieMenuDots = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M10.5,20c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S10.5,20.8,10.5,20z M10.5,12c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S10.5,12.8,10.5,12z M10.5,4c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S12.8,5.5,12,5.5S10.5,4.8,10.5,4z',
        },
        child: [],
      },
    ],
  })(props)
