import { TimeUtil } from 'cuenect-web-core'

interface Abbr {
  ndst: string
  dst: string
}

interface IanaTimezonesInterface {
  name: string
  utcOffset: number
  abbr: {
    de: Abbr
    en: Abbr
  }
}

//export type TimezoneKey = keyof typeof IanaTimezones.map(({ name }) => name)

export const IanaTimezones: IanaTimezonesInterface[] = [
  {
    name: 'Africa/Abidjan',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Accra',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Algiers',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Africa/Bissau',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Cairo',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Africa/Casablanca',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Ceuta',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Africa/El_Aaiun',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Johannesburg',
    utcOffset: 120,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Africa/Juba',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'CAT',
        dst: 'DST',
      },
      de: {
        ndst: 'CAT',
        dst: 'DST',
      },
    },
  },
  {
    name: 'Africa/Khartoum',
    utcOffset: 120,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Africa/Lagos',
    utcOffset: 60,
    abbr: {
      en: {
        ndst: 'WAT',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAT',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Africa/Maputo',
    utcOffset: 120,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Africa/Monrovia',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Nairobi',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'CAT',
        dst: 'DST',
      },
      de: {
        ndst: 'CAT',
        dst: 'DST',
      },
    },
  },
  {
    name: 'Africa/Ndjamena',
    utcOffset: 60,
    abbr: {
      en: {
        ndst: 'WAT',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAT',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Africa/Sao_Tome',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Africa/Tripoli',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Africa/Tunis',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Africa/Windhoek',
    utcOffset: 120,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'America/Adak',
    utcOffset: -600,
    abbr: {
      en: {
        ndst: 'HAST',
        dst: 'HADT',
      },
      de: {
        ndst: 'HAST',
        dst: 'HADT',
      },
    },
  },
  {
    name: 'America/Anchorage',
    utcOffset: -540,
    abbr: {
      en: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
      de: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
    },
  },
  {
    name: 'America/Araguaina',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },

  {
    name: 'America/Argentina/La_Rioja',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },

  {
    name: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'America/Argentina/Salta',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'America/Argentina/San_Juan',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'America/Argentina/San_Luis',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'America/Argentina/Tucuman',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'America/Argentina/Ushuaia',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'America/Asuncion',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'PYT',
        dst: 'PYT',
      },
      de: {
        ndst: 'PYT',
        dst: 'PYT',
      },
    },
  },

  {
    name: 'America/Bahia',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'BST',
        dst: 'BST',
      },
      de: {
        ndst: 'BST',
        dst: 'BST',
      },
    },
  },
  {
    name: 'America/Bahia_Banderas',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Barbados',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Belem',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Belize',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Blanc-Sablon',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Boa_Vista',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'SWST',
        dst: 'SWST',
      },
      de: {
        ndst: 'SWST',
        dst: 'SWST',
      },
    },
  },
  {
    name: 'America/Bogota',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'SPST',
        dst: 'SPST',
      },
      de: {
        ndst: 'SPST',
        dst: 'SPST',
      },
    },
  },
  {
    name: 'America/Boise',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Cambridge_Bay',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Campo_Grande',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'CBST',
        dst: 'CBST',
      },
      de: {
        ndst: 'CBST',
        dst: 'CBST',
      },
    },
  },
  {
    name: 'America/Cancun',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Caracas',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'VST',
        dst: 'VST',
      },
      de: {
        ndst: 'VST',
        dst: 'VST',
      },
    },
  },
  {
    name: 'America/Cayenne',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Chicago',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Chihuahua',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MDT',
        dst: 'MDT',
      },
      de: {
        ndst: 'MDT',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Costa_Rica',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Creston',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Cuiaba',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'CBST',
        dst: 'CBST',
      },
      de: {
        ndst: 'CBST',
        dst: 'CBST',
      },
    },
  },
  {
    name: 'America/Curacao',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Danmarkshavn',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'America/Dawson',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Dawson_Creek',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Denver',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Detroit',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Edmonton',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Eirunepe',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'SPST',
        dst: 'SPST',
      },
      de: {
        ndst: 'SPST',
        dst: 'SPST',
      },
    },
  },
  {
    name: 'America/El_Salvador',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Fort_Nelson',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Fortaleza',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Glace_Bay',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Goose_Bay',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Grand_Turk',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Guatemala',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Guayaquil',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'SPST',
        dst: 'SPST',
      },
      de: {
        ndst: 'SPST',
        dst: 'SPST',
      },
    },
  },
  {
    name: 'America/Guyana',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'UTC-4',
        dst: 'UTC-4',
      },
      de: {
        ndst: 'UTC-4',
        dst: 'UTC-4',
      },
    },
  },
  {
    name: 'America/Halifax',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Havana',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EDT',
        dst: 'EDT',
      },
      de: {
        ndst: 'EDT',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Hermosillo',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'UMST',
        dst: 'UMST',
      },
      de: {
        ndst: 'UMST',
        dst: 'UMST',
      },
    },
  },

  {
    name: 'America/Indiana/Knox',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Indiana/Marengo',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Indiana/Petersburg',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Indiana/Tell_City',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Indiana/Vevay',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Indiana/Vincennes',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Indiana/Winamac',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Inuvik',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Iqaluit',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Jamaica',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Juneau',
    utcOffset: -540,
    abbr: {
      en: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
      de: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
    },
  },

  {
    name: 'America/Kentucky/Monticello',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/La_Paz',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'SWST',
        dst: 'SWST',
      },
      de: {
        ndst: 'SWST',
        dst: 'SWST',
      },
    },
  },
  {
    name: 'America/Lima',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'SPST',
        dst: 'SPST',
      },
      de: {
        ndst: 'SPST',
        dst: 'SPST',
      },
    },
  },
  {
    name: 'America/Los_Angeles',
    utcOffset: -480,
    abbr: {
      en: {
        ndst: 'PST',
        dst: 'PDT',
      },
      de: {
        ndst: 'PST',
        dst: 'PDT',
      },
    },
  },
  {
    name: 'America/Maceio',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Managua',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Manaus',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'SWST',
        dst: 'SWST',
      },
      de: {
        ndst: 'SWST',
        dst: 'SWST',
      },
    },
  },
  {
    name: 'America/Martinique',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Matamoros',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Mazatlan',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MDT',
        dst: 'MDT',
      },
      de: {
        ndst: 'MDT',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Menominee',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Merida',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Metlakatla',
    utcOffset: -540,
    abbr: {
      en: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
      de: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
    },
  },
  {
    name: 'America/Mexico_City',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Miquelon',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'America/Moncton',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Monterrey',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Montevideo',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'America/Nassau',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/New_York',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Nipigon',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Nome',
    utcOffset: -540,
    abbr: {
      en: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
      de: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
    },
  },
  {
    name: 'America/Noronha',
    utcOffset: -120,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'America/North_Dakota/Beulah',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/North_Dakota/Center',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/North_Dakota/New_Salem',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },

  {
    name: 'America/Ojinaga',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Panama',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Pangnirtung',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Paramaribo',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Phoenix',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Port-au-Prince',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Port_of_Spain',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Porto_Velho',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'SWST',
        dst: 'SWST',
      },
      de: {
        ndst: 'SWST',
        dst: 'SWST',
      },
    },
  },
  {
    name: 'America/Puerto_Rico',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Punta_Arenas',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'America/Rainy_River',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Rankin_Inlet',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Recife',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Regina',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Resolute',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Rio_Branco',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'SPST',
        dst: 'SPST',
      },
      de: {
        ndst: 'SPST',
        dst: 'SPST',
      },
    },
  },
  {
    name: 'America/Santarem',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'America/Santiago',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'PSST',
        dst: 'PSST',
      },
      de: {
        ndst: 'PSST',
        dst: 'PSST',
      },
    },
  },
  {
    name: 'America/Santo_Domingo',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Sao_Paulo',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'ESAST',
        dst: 'ESAST',
      },
      de: {
        ndst: 'ESAST',
        dst: 'ESAST',
      },
    },
  },
  {
    name: 'America/Scoresbysund',
    utcOffset: -60,
    abbr: {
      en: {
        ndst: 'ADT',
        dst: 'ADT',
      },
      de: {
        ndst: 'ADT',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Sitka',
    utcOffset: -540,
    abbr: {
      en: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
      de: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
    },
  },
  {
    name: 'America/St_Johns',
    utcOffset: -210,
    abbr: {
      en: {
        ndst: 'NST',
        dst: 'NDT',
      },
      de: {
        ndst: 'NST',
        dst: 'NDT',
      },
    },
  },
  {
    name: 'America/Swift_Current',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Tegucigalpa',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Thule',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'America/Thunder_Bay',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Tijuana',
    utcOffset: -480,
    abbr: {
      en: {
        ndst: 'PST',
        dst: 'PDT',
      },
      de: {
        ndst: 'PST',
        dst: 'PDT',
      },
    },
  },
  {
    name: 'America/Toronto',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },
  {
    name: 'America/Vancouver',
    utcOffset: -480,
    abbr: {
      en: {
        ndst: 'PST',
        dst: 'PDT',
      },
      de: {
        ndst: 'PST',
        dst: 'PDT',
      },
    },
  },
  {
    name: 'America/Whitehorse',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'America/Winnipeg',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },
  {
    name: 'America/Yakutat',
    utcOffset: -540,
    abbr: {
      en: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
      de: {
        ndst: 'AKST',
        dst: 'AKDT',
      },
    },
  },
  {
    name: 'America/Yellowknife',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'Antarctica/Casey',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Antarctica/Davis',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Antarctica/DumontDUrville',
    utcOffset: 600,
    abbr: {
      en: {
        ndst: 'WPST',
        dst: 'WPST',
      },
      de: {
        ndst: 'WPST',
        dst: 'WPST',
      },
    },
  },
  {
    name: 'Antarctica/Macquarie',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
      de: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
    },
  },
  {
    name: 'Antarctica/Mawson',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Antarctica/Palmer',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'PSST',
        dst: 'PSST',
      },
      de: {
        ndst: 'PSST',
        dst: 'PSST',
      },
    },
  },
  {
    name: 'Antarctica/Rothera',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'Antarctica/Syowa',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'EAST',
        dst: 'EAST',
      },
      de: {
        ndst: 'EAST',
        dst: 'EAST',
      },
    },
  },
  {
    name: 'Antarctica/Troll',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Antarctica/Vostok',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },
  {
    name: 'Asia/Almaty',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },
  {
    name: 'Asia/Amman',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Asia/Anadyr',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'Asia/Aqtau',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Aqtobe',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Ashgabat',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Atyrau',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Asia/Baghdad',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'Asia/Baku',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'ADT',
        dst: 'ADT',
      },
      de: {
        ndst: 'ADT',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'Asia/Bangkok',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Asia/Barnaul',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Asia/Beirut',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Asia/Bishkek',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },
  {
    name: 'Asia/Brunei',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'MPST',
        dst: 'MPST',
      },
      de: {
        ndst: 'MPST',
        dst: 'MPST',
      },
    },
  },
  {
    name: 'Asia/Chita',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'YST',
        dst: 'YST',
      },
      de: {
        ndst: 'YST',
        dst: 'YST',
      },
    },
  },
  {
    name: 'Asia/Choibalsan',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'UST',
        dst: 'UST',
      },
      de: {
        ndst: 'UST',
        dst: 'UST',
      },
    },
  },
  {
    name: 'Asia/Colombo',
    utcOffset: 330,
    abbr: {
      en: {
        ndst: 'UTC+5:30',
        dst: 'UTC+5:30',
      },
      de: {
        ndst: 'UTC+5:30',
        dst: 'UTC+5:30',
      },
    },
  },
  {
    name: 'Asia/Damascus',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Asia/Dhaka',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'BST',
        dst: 'BST',
      },
      de: {
        ndst: 'BST',
        dst: 'BST',
      },
    },
  },
  {
    name: 'Asia/Dili',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'JST',
        dst: 'JST',
      },
      de: {
        ndst: 'JST',
        dst: 'JST',
      },
    },
  },
  {
    name: 'Asia/Dubai',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'UTC+4',
        dst: 'UTC+4',
      },
      de: {
        ndst: 'UTC+4',
        dst: 'UTC+4',
      },
    },
  },
  {
    name: 'Asia/Dushanbe',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Famagusta',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Asia/Gaza',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Asia/Hebron',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },

  {
    name: 'Asia/Hong_Kong',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'HKT',
        dst: 'HKST',
      },
      de: {
        ndst: 'HKT',
        dst: 'HKST',
      },
    },
  },
  {
    name: 'Asia/Hovd',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Asia/Irkutsk',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'NAEST',
        dst: 'NAEST',
      },
      de: {
        ndst: 'NAEST',
        dst: 'NAEST',
      },
    },
  },
  {
    name: 'Asia/Jakarta',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Asia/Jayapura',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'JST',
        dst: 'JST',
      },
      de: {
        ndst: 'JST',
        dst: 'JST',
      },
    },
  },
  {
    name: 'Asia/Jerusalem',
    utcOffset: 120,
    abbr: {
      en: {
        ndst: 'JDT',
        dst: 'JDT',
      },
      de: {
        ndst: 'JDT',
        dst: 'JDT',
      },
    },
  },
  {
    name: 'Asia/Kabul',
    utcOffset: 270,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'Asia/Kamchatka',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'KDT',
        dst: 'KDT',
      },
      de: {
        ndst: 'KDT',
        dst: 'KDT',
      },
    },
  },
  {
    name: 'Asia/Karachi',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'PKT',
        dst: 'PKT',
      },
      de: {
        ndst: 'PKT',
        dst: 'PKT',
      },
    },
  },

  {
    name: 'Asia/Khandyga',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'YST',
        dst: 'YST',
      },
      de: {
        ndst: 'YST',
        dst: 'YST',
      },
    },
  },
  {
    name: 'Asia/Kolkata',
    utcOffset: 330,
    abbr: {
      en: {
        ndst: 'IST',
        dst: 'IST',
      },
      de: {
        ndst: 'IST',
        dst: 'IST',
      },
    },
  },
  {
    name: 'Asia/Calcutta',
    utcOffset: 330,
    abbr: {
      en: {
        ndst: 'IST',
        dst: 'IST',
      },
      de: {
        ndst: 'IST',
        dst: 'IST',
      },
    },
  },
  {
    name: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'NAST',
        dst: 'NAST',
      },
      de: {
        ndst: 'NAST',
        dst: 'NAST',
      },
    },
  },
  {
    name: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'UTC+8',
        dst: 'UTC+8',
      },
      de: {
        ndst: 'UTC+8',
        dst: 'UTC+8',
      },
    },
  },
  {
    name: 'Asia/Kuching',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'UTC+8',
        dst: 'UTC+8',
      },
      de: {
        ndst: 'UTC+8',
        dst: 'UTC+8',
      },
    },
  },
  {
    name: 'Asia/Macau',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CST',
      },
      de: {
        ndst: 'CST',
        dst: 'CST',
      },
    },
  },
  {
    name: 'Asia/Magadan',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'Asia/Makassar',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'MPST',
        dst: 'MPST',
      },
      de: {
        ndst: 'MPST',
        dst: 'MPST',
      },
    },
  },
  {
    name: 'Asia/Manila',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'MPST',
        dst: 'MPST',
      },
      de: {
        ndst: 'MPST',
        dst: 'MPST',
      },
    },
  },
  {
    name: 'Asia/Nicosia',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Asia/Novokuznetsk',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'NCAST',
        dst: 'NCAST',
      },
      de: {
        ndst: 'NCAST',
        dst: 'NCAST',
      },
    },
  },
  {
    name: 'Asia/Novosibirsk',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'NCAST',
        dst: 'NCAST',
      },
      de: {
        ndst: 'NCAST',
        dst: 'NCAST',
      },
    },
  },
  {
    name: 'Asia/Omsk',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'NCAST',
        dst: 'NCAST',
      },
      de: {
        ndst: 'NCAST',
        dst: 'NCAST',
      },
    },
  },
  {
    name: 'Asia/Oral',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Pontianak',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Asia/Pyongyang',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'KST',
        dst: 'KST',
      },
      de: {
        ndst: 'KST',
        dst: 'KST',
      },
    },
  },
  {
    name: 'Asia/Qatar',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'Asia/Qostanay',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Asia/Qyzylorda',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },
  {
    name: 'Asia/Riyadh',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'AST',
      },
      de: {
        ndst: 'AST',
        dst: 'AST',
      },
    },
  },
  {
    name: 'Asia/Sakhalin',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'VST',
        dst: 'VST',
      },
      de: {
        ndst: 'VST',
        dst: 'VST',
      },
    },
  },
  {
    name: 'Asia/Samarkand',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Seoul',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'KST',
        dst: 'KST',
      },
      de: {
        ndst: 'KST',
        dst: 'KST',
      },
    },
  },
  {
    name: 'Asia/Shanghai',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CST',
      },
      de: {
        ndst: 'CST',
        dst: 'CST',
      },
    },
  },
  {
    name: 'Asia/Singapore',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CST',
      },
      de: {
        ndst: 'CST',
        dst: 'CST',
      },
    },
  },
  {
    name: 'Asia/Srednekolymsk',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'Asia/Taipei',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'TST',
        dst: 'TST',
      },
      de: {
        ndst: 'TST',
        dst: 'TST',
      },
    },
  },
  {
    name: 'Asia/Tashkent',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Asia/Tbilisi',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'GET',
        dst: 'GET',
      },
      de: {
        ndst: 'GET',
        dst: 'GET',
      },
    },
  },
  {
    name: 'Asia/Tehran',
    utcOffset: 210,
    abbr: {
      en: {
        ndst: 'IDT',
        dst: 'IDT',
      },
      de: {
        ndst: 'IDT',
        dst: 'IDT',
      },
    },
  },
  {
    name: 'Asia/Thimphu',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'BST',
        dst: 'BST',
      },
      de: {
        ndst: 'BST',
        dst: 'BST',
      },
    },
  },
  {
    name: 'Asia/Tokyo',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'JST',
        dst: 'JST',
      },
      de: {
        ndst: 'JST',
        dst: 'JST',
      },
    },
  },
  {
    name: 'Asia/Tomsk',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'UST',
        dst: 'UST',
      },
      de: {
        ndst: 'UST',
        dst: 'UST',
      },
    },
  },
  {
    name: 'Asia/Urumqi',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },
  {
    name: 'Asia/Ust-Nera',
    utcOffset: 600,
    abbr: {
      en: {
        ndst: 'VST',
        dst: 'VST',
      },
      de: {
        ndst: 'VST',
        dst: 'VST',
      },
    },
  },
  {
    name: 'Asia/Vladivostok',
    utcOffset: 600,
    abbr: {
      en: {
        ndst: 'VST',
        dst: 'VST',
      },
      de: {
        ndst: 'VST',
        dst: 'VST',
      },
    },
  },
  {
    name: 'Asia/Yakutsk',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'YST',
        dst: 'YST',
      },
      de: {
        ndst: 'YST',
        dst: 'YST',
      },
    },
  },

  {
    name: 'Asia/Yekaterinburg',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'YEKT',
        dst: 'YEKT',
      },
      de: {
        ndst: 'YEKT',
        dst: 'YEKT',
      },
    },
  },
  {
    name: 'Asia/Yerevan',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CST',
      },
      de: {
        ndst: 'CST',
        dst: 'CST',
      },
    },
  },
  {
    name: 'Atlantic/Azores',
    utcOffset: -60,
    abbr: {
      en: {
        ndst: 'ADT',
        dst: 'ADT',
      },
      de: {
        ndst: 'ADT',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'Atlantic/Bermuda',
    utcOffset: -240,
    abbr: {
      en: {
        ndst: 'AST',
        dst: 'ADT',
      },
      de: {
        ndst: 'AST',
        dst: 'ADT',
      },
    },
  },
  {
    name: 'Atlantic/Canary',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    abbr: {
      en: {
        ndst: 'CVST',
        dst: 'CVST',
      },
      de: {
        ndst: 'CVST',
        dst: 'CVST',
      },
    },
  },

  {
    name: 'Atlantic/Madeira',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Atlantic/Reykjavik',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Atlantic/South_Georgia',
    utcOffset: -120,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Atlantic/Stanley',
    utcOffset: -180,
    abbr: {
      en: {
        ndst: 'SEST',
        dst: 'SEST',
      },
      de: {
        ndst: 'SEST',
        dst: 'SEST',
      },
    },
  },
  {
    name: 'Australia/Adelaide',
    utcOffset: 630,
    abbr: {
      en: {
        ndst: 'ACST',
        dst: 'ACDT',
      },
      de: {
        ndst: 'ACST',
        dst: 'ACDT',
      },
    },
  },
  {
    name: 'Australia/Brisbane',
    utcOffset: 600,
    abbr: {
      en: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
      de: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
    },
  },
  {
    name: 'Australia/Broken_Hill',
    utcOffset: 630,
    abbr: {
      en: {
        ndst: 'ACST',
        dst: 'ACDT',
      },
      de: {
        ndst: 'ACST',
        dst: 'ACDT',
      },
    },
  },
  {
    name: 'Australia/Darwin',
    utcOffset: 570,
    abbr: {
      en: {
        ndst: 'ACST',
        dst: 'ACDT',
      },
      de: {
        ndst: 'ACST',
        dst: 'ACDT',
      },
    },
  },
  {
    name: 'Australia/Eucla',
    utcOffset: 525,
    abbr: {
      en: {
        ndst: 'ACWST',
        dst: 'ACWDT',
      },
      de: {
        ndst: 'ACWST',
        dst: 'ACWDT',
      },
    },
  },
  {
    name: 'Australia/Hobart',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
      de: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
    },
  },
  {
    name: 'Australia/Lindeman',
    utcOffset: 600,
    abbr: {
      en: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
      de: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
    },
  },
  {
    name: 'Australia/Lord_Howe',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'LHST',
        dst: 'LHDT',
      },
      de: {
        ndst: 'LHST',
        dst: 'LHDT',
      },
    },
  },
  {
    name: 'Australia/Melbourne',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
      de: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
    },
  },
  {
    name: 'Australia/Perth',
    utcOffset: 480,
    abbr: {
      en: {
        ndst: 'AWST',
        dst: 'AWDT',
      },
      de: {
        ndst: 'AWST',
        dst: 'AWDT',
      },
    },
  },
  {
    name: 'Australia/Sydney',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
      de: {
        ndst: 'AEST',
        dst: 'AEDT',
      },
    },
  },

  {
    name: 'CST6CDT',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CST',
        dst: 'CDT',
      },
      de: {
        ndst: 'CST',
        dst: 'CDT',
      },
    },
  },

  {
    name: 'EST5EDT',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: 'EST',
        dst: 'EDT',
      },
      de: {
        ndst: 'EST',
        dst: 'EDT',
      },
    },
  },

  {
    name: 'Europe/Amsterdam',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Andorra',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Astrakhan',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'SAMT',
        dst: 'SAMT',
      },
      de: {
        ndst: 'SAMT',
        dst: 'SAMT',
      },
    },
  },
  {
    name: 'Europe/Athens',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Belgrade',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Berlin',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Brussels',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Bucharest',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Budapest',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Chisinau',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Copenhagen',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Dublin',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Europe/Gibraltar',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Helsinki',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Istanbul',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'TDT',
        dst: 'TDT',
      },
      de: {
        ndst: 'TDT',
        dst: 'TDT',
      },
    },
  },
  {
    name: 'Europe/Kaliningrad',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Kiev',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Kirov',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'MSK',
        dst: 'MSK',
      },
      de: {
        ndst: 'MSK',
        dst: 'MSK',
      },
    },
  },
  {
    name: 'Europe/Lisbon',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Europe/London',
    utcOffset: 0,
    abbr: {
      de: {
        ndst: 'WEZ',
        dst: 'WESZ',
      },
      en: {
        ndst: 'WET',
        dst: 'WEST',
      },
    },
  },
  {
    name: 'Europe/Luxembourg',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Madrid',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Malta',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Minsk',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'MSK',
        dst: 'MSK',
      },
      de: {
        ndst: 'MSK',
        dst: 'MSK',
      },
    },
  },
  {
    name: 'Europe/Monaco',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Moscow',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'MSK',
        dst: 'MSK',
      },
      de: {
        ndst: 'MSK',
        dst: 'MSK',
      },
    },
  },
  {
    name: 'Europe/Oslo',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Paris',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Prague',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Riga',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Rome',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Samara',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'SAMT',
        dst: 'SAMT',
      },
      de: {
        ndst: 'SAMT',
        dst: 'SAMT',
      },
    },
  },
  {
    name: 'Europe/Saratov',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Europe/Simferopol',
    utcOffset: 180,
    abbr: {
      en: {
        ndst: 'MSK',
        dst: 'MSK',
      },
      de: {
        ndst: 'MSK',
        dst: 'MSK',
      },
    },
  },
  {
    name: 'Europe/Sofia',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Stockholm',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Tallinn',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Tirane',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Ulyanovsk',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'SAMT',
        dst: 'SAMT',
      },
      de: {
        ndst: 'SAMT',
        dst: 'SAMT',
      },
    },
  },
  {
    name: 'Europe/Uzhgorod',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Vienna',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Vilnius',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Volgograd',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'MSK',
        dst: 'MSK',
      },
      de: {
        ndst: 'MSK',
        dst: 'MSK',
      },
    },
  },
  {
    name: 'Europe/Warsaw',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },
  {
    name: 'Europe/Zaporozhye',
    utcOffset: 120,
    abbr: {
      de: {
        ndst: 'OEZ',
        dst: 'OESZ',
      },
      en: {
        ndst: 'EET',
        dst: 'EEST',
      },
    },
  },
  {
    name: 'Europe/Zurich',
    utcOffset: 60,
    abbr: {
      de: {
        ndst: 'MEZ',
        dst: 'MESZ',
      },
      en: {
        ndst: 'CET',
        dst: 'CEST',
      },
    },
  },

  {
    name: 'Indian/Chagos',
    utcOffset: 360,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },
  {
    name: 'Indian/Christmas',
    utcOffset: 420,
    abbr: {
      en: {
        ndst: 'SAST',
        dst: 'SAST',
      },
      de: {
        ndst: 'SAST',
        dst: 'SAST',
      },
    },
  },
  {
    name: 'Indian/Cocos',
    utcOffset: 390,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'Indian/Kerguelen',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Indian/Mahe',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'Indian/Maldives',
    utcOffset: 300,
    abbr: {
      en: {
        ndst: 'WAST',
        dst: 'WAST',
      },
      de: {
        ndst: 'WAST',
        dst: 'WAST',
      },
    },
  },
  {
    name: 'Indian/Mauritius',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },
  {
    name: 'Indian/Reunion',
    utcOffset: 240,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MST',
      },
      de: {
        ndst: 'MST',
        dst: 'MST',
      },
    },
  },

  {
    name: 'MST7MDT',
    utcOffset: -420,
    abbr: {
      en: {
        ndst: 'MST',
        dst: 'MDT',
      },
      de: {
        ndst: 'MST',
        dst: 'MDT',
      },
    },
  },
  {
    name: 'PST8PDT',
    utcOffset: -480,
    abbr: {
      en: {
        ndst: 'PST',
        dst: 'PDT',
      },
      de: {
        ndst: 'PST',
        dst: 'PDT',
      },
    },
  },
  {
    name: 'Pacific/Apia',
    utcOffset: 840,
    abbr: {
      en: {
        ndst: 'SST',
        dst: 'SST',
      },
      de: {
        ndst: 'SST',
        dst: 'SST',
      },
    },
  },
  {
    name: 'Pacific/Auckland',
    utcOffset: 780,
    abbr: {
      en: {
        ndst: 'NZST',
        dst: 'NZDT',
      },
      de: {
        ndst: 'NZST',
        dst: 'NZDT',
      },
    },
  },
  {
    name: 'Pacific/Bougainville',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Pacific/Chatham',
    utcOffset: 825,
    abbr: {
      en: {
        ndst: 'CHAST',
        dst: 'CHADT',
      },
      de: {
        ndst: 'CHAST',
        dst: 'CHADT',
      },
    },
  },

  {
    name: 'Pacific/Easter',
    utcOffset: -300,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Pacific/Efate',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'CPST',
        dst: 'CPST',
      },
      de: {
        ndst: 'CPST',
        dst: 'CPST',
      },
    },
  },
  {
    name: 'Pacific/Enderbury',
    utcOffset: 780,
    abbr: {
      en: {
        ndst: 'TST',
        dst: 'TST',
      },
      de: {
        ndst: 'TST',
        dst: 'TST',
      },
    },
  },
  {
    name: 'Pacific/Fakaofo',
    utcOffset: 780,
    abbr: {
      en: {
        ndst: 'TST',
        dst: 'TST',
      },
      de: {
        ndst: 'TST',
        dst: 'TST',
      },
    },
  },
  {
    name: 'Pacific/Fiji',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'FST',
        dst: 'FST',
      },
      de: {
        ndst: 'FST',
        dst: 'FST',
      },
    },
  },
  {
    name: 'Pacific/Funafuti',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Galapagos',
    utcOffset: -360,
    abbr: {
      en: {
        ndst: 'CAST',
        dst: 'CAST',
      },
      de: {
        ndst: 'CAST',
        dst: 'CAST',
      },
    },
  },

  {
    name: 'Pacific/Guadalcanal',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'CPST',
        dst: 'CPST',
      },
      de: {
        ndst: 'CPST',
        dst: 'CPST',
      },
    },
  },
  {
    name: 'Pacific/Honolulu',
    utcOffset: -600,
    abbr: {
      en: {
        ndst: 'HAST',
        dst: 'HADT',
      },
      de: {
        ndst: 'HAST',
        dst: 'HADT',
      },
    },
  },
  {
    name: 'Pacific/Kiritimati',
    utcOffset: 840,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Pacific/Kosrae',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'CPST',
        dst: 'CPST',
      },
      de: {
        ndst: 'CPST',
        dst: 'CPST',
      },
    },
  },
  {
    name: 'Pacific/Kwajalein',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Majuro',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Marquesas',
    utcOffset: -570,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Pacific/Nauru',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Niue',
    utcOffset: -660,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Norfolk',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },
  {
    name: 'Pacific/Noumea',
    utcOffset: 660,
    abbr: {
      en: {
        ndst: 'CPST',
        dst: 'CPST',
      },
      de: {
        ndst: 'CPST',
        dst: 'CPST',
      },
    },
  },
  {
    name: 'Pacific/Pago_Pago',
    utcOffset: -660,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Palau',
    utcOffset: 540,
    abbr: {
      en: {
        ndst: 'JST',
        dst: 'JST',
      },
      de: {
        ndst: 'JST',
        dst: 'JST',
      },
    },
  },
  {
    name: 'Pacific/Pitcairn',
    utcOffset: -480,
    abbr: {
      en: {
        ndst: '',
        dst: '',
      },
      de: {
        ndst: '',
        dst: '',
      },
    },
  },

  {
    name: 'Pacific/Port_Moresby',
    utcOffset: 600,
    abbr: {
      en: {
        ndst: 'WPST',
        dst: 'WPST',
      },
      de: {
        ndst: 'WPST',
        dst: 'WPST',
      },
    },
  },
  {
    name: 'Pacific/Rarotonga',
    utcOffset: -600,
    abbr: {
      en: {
        ndst: 'HST',
        dst: 'HST',
      },
      de: {
        ndst: 'HST',
        dst: 'HST',
      },
    },
  },
  {
    name: 'Pacific/Tahiti',
    utcOffset: -600,
    abbr: {
      en: {
        ndst: 'HST',
        dst: 'HST',
      },
      de: {
        ndst: 'HST',
        dst: 'HST',
      },
    },
  },
  {
    name: 'Pacific/Tarawa',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Tongatapu',
    utcOffset: 780,
    abbr: {
      en: {
        ndst: 'TST',
        dst: 'TST',
      },
      de: {
        ndst: 'TST',
        dst: 'TST',
      },
    },
  },
  {
    name: 'Pacific/Wake',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
  {
    name: 'Pacific/Wallis',
    utcOffset: 720,
    abbr: {
      en: {
        ndst: 'U',
        dst: 'U',
      },
      de: {
        ndst: 'U',
        dst: 'U',
      },
    },
  },
]

export const getAbbr = (
  timezone: string,
  language = 'en',
  date: dayjs.Dayjs = null
) => {
  const { abbr } = IanaTimezones.find(({ name }) => name === timezone) || {
    abbr: '',
  }

  if (!abbr[language]) {
    return ''
  }

  if (date && TimeUtil.getIsDst(date, timezone)) {
    return abbr[language].dst
  }

  return abbr[language].ndst
}
