import { GenIcon, IconBaseProps } from 'react-icons'
export const SieSettings = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M15.6,22H8.4v-2.4c-0.4-0.2-0.8-0.4-1.2-0.7l-0.4,0.2l-1.7,1l-1-1.7l-1.6-2.8l-1-1.7l1.7-1l0.4-0.2c0-0.2,0-0.5,0-0.7s0-0.4,0-0.7l-0.4-0.2l-1.7-1l1-1.7l1.6-2.8l1-1.7l1.7,1l0.4,0.2C7.6,4.8,8,4.6,8.4,4.4V2h7.2v2.4c0.4,0.2,0.8,0.4,1.2,0.7l0.4-0.2l1.7-1l1,1.7l1.6,2.8l1,1.7l-1.7,1l-0.4,0.2c0,0.2,0,0.5,0,0.7s0,0.5,0,0.7l0.4,0.2l1.7,1l-1,1.7l-1.6,2.8l-1,1.7l-1.7-1l-0.4-0.2c-0.4,0.3-0.8,0.5-1.2,0.7L15.6,22L15.6,22L15.6,22z M7.4,16.5L7.4,16.5c0.8,0.8,1.8,1.4,3,1.7V20h3.2v-1.8c1.1-0.3,2.2-0.9,3-1.7l1.6,0.9l1.6-2.8l-1.6-0.9c0.3-1.1,0.3-2.3,0-3.4l1.6-0.9l-1.6-2.8l-1.6,0.9c-0.8-0.8-1.8-1.4-3-1.7V4h-3.2v1.8c-1.1,0.3-2.1,0.9-3,1.7L5.9,6.6L4.3,9.4l1.6,0.9c-0.3,1.1-0.3,2.3,0,3.4l-1.6,0.9l1.6,2.8L7.4,16.5L7.4,16.5z M8,12c0-2.2,1.8-4,4-4s4,1.8,4,4s-1.8,4-4,4S8,14.2,8,12z M10,12c0,1.1,0.9,2,2,2s2-0.9,2-2s-0.9-2-2-2S10,10.9,10,12z',
        },
        child: [],
      },
    ],
  })(props)
