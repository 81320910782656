import React from 'react'
interface State {
  showModal?: boolean
  // NEED TO FIX
  // tslint:disable-next-line: no-any
  component?: any
  params?: {}
}

const defaultState: State = {
  showModal: false,
}
export interface ModalAction {
  type: 'OPEN_WITH_COMPONENT' | 'HIDE_MODAL'
  state?: State
}

const reducer: React.Reducer<State, ModalAction> = (state, action) => {
  switch (action.type) {
    case 'OPEN_WITH_COMPONENT':
      console.log('OPEN W COM')
      return {
        ...state,
        showModal: true,
        component: action.state?.component,
        params: action.state?.params,
      }
    case 'HIDE_MODAL':
      return defaultState
    default:
      return defaultState
  }
}

export const ModalContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<ModalAction>
}>({ state: defaultState, dispatch: () => null })

export const ModalContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => React.useContext(ModalContext)
