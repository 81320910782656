import styled from '@emotion/styled'
import React from 'react'
import { Heading } from '../../atoms/heading'

type DivProps = JSX.IntrinsicElements['div']

export interface ImageTeaserProps extends DivProps {
  imageUrl?: string
}

const ASPECT_RATIO_X = 4
const ASPECT_RATIO_Y = 3
// e.g. "75%" for 4:3
const ASPECT_RATIO_PADDING_TOP = `${(ASPECT_RATIO_Y / ASPECT_RATIO_X) * 100}%`

export const ImageTeaser: React.FC<ImageTeaserProps> = ({
  children,
  imageUrl,
  ...rest
}) => {
  return (
    <Container imageUrl={imageUrl} {...rest}>
      {children && (
        <TextContainer>
          <Heading type="h5">{children}</Heading>
        </TextContainer>
      )}
    </Container>
  )
}

const Container = styled.div<Pick<ImageTeaserProps, 'imageUrl' | 'onClick'>>(
  ({ imageUrl, onClick }) => ({
    paddingTop: ASPECT_RATIO_PADDING_TOP,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    // show the pointer cursor, if an onClick handler has been given
    cursor: onClick ? 'pointer' : 'default',
  })
)

const TextContainer = styled.div({
  position: 'absolute',
  backgroundColor: 'rgba(0,0,0,.9)',
  padding: '1rem',
  bottom: 0,
  left: 0,
  right: 0,
  textAlign: 'center',

  '& > h5': {
    margin: 0,
  },
})
