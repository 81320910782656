import { GenIcon, IconBaseProps } from 'react-icons'
export const SieCheck = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M19.8,3.2l2.1,1.4L11.1,20.8l-9-9L3.8,10l6.9,6.9L19.8,3.2z',
        },
        child: [],
      },
    ],
  })(props)
