import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { isDesktop, isIOS, isMobileSafari } from 'react-device-detect'
import { ShowroomItem } from './../api'
import { VodVideo } from './../components'
import { useModalContext } from './modalContext'

interface State {
  src: string | null | undefined
  track: string | null
  trackingTitle: string | null
  playerName?: string | null
  videoSelector?: ShowroomItem[] | null
  showMobile?: boolean
}

const defaultState: State = {
  src: null,
  track: null,
  trackingTitle: null,
  videoSelector: null,
  showMobile: false,
  playerName: 'VoD',
}
export interface VodAction {
  type: 'OPEN_VOD' | 'HIDE_VOD' | 'OPEN_MOBILE_VOD'
  src: string | null | undefined
  track?: string | null
  trackingTitle?: string | null
  videoSelector?: ShowroomItem[] | null
  playerName?: string | null
  state?: State
}

const reducer: React.Reducer<State, VodAction> = (state, action) => {
  switch (action.type) {
    case 'OPEN_VOD':
      return {
        src: action.src,
        track: action.track,
        trackingTitle: action.trackingTitle,
        videoSelector: action.videoSelector,
        playerName: action.playerName || 'VoD',
        showMobile: false,
      }
    case 'OPEN_MOBILE_VOD':
      return {
        src: action.src,
        videoSelector: action.videoSelector,
        playerName: action.playerName || 'VoD',
        showMobile: true,
      }
    case 'HIDE_VOD':
      return {}
    default:
      return {}
  }
}

export const VodContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<VodAction>
}>({ state: defaultState, dispatch: () => null })

export const VodContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)
  const { state: modalState, dispatch: modalDispatch } = useModalContext()

  React.useEffect(() => {
    if (state.src) {
      /* if(isIOS){
        return document.location.href = state.src
      } */
      if (
        isMobileSafari &&
        (isMobileOnly || document.documentElement.clientWidth < 769)
      ) {
        dispatch({
          type: 'OPEN_MOBILE_VOD',
          src: state.src,
          trackingTitle: state.trackingTitle,
          playerName: state.playerName,
        })
      } else {
        modalDispatch({
          type: 'OPEN_WITH_COMPONENT',
          state: {
            component: VodVideo,
            params: {
              onClose: () => {
                modalDispatch({
                  type: 'HIDE_MODAL',
                })
              },

              src: state.src,
              track: state.track,
              trackingTitle: state.trackingTitle,
              videoSelector: state.videoSelector,
              playerName: state.playerName,
            },
          },
        })
      }
    }
  }, [state.src])

  React.useEffect(() => {
    if (!modalState.showModal) {
      dispatch({ type: 'HIDE_VOD' })
    }
  }, [modalState.showModal])

  return (
    <VodContext.Provider value={{ state, dispatch }}>
      {props.children}
    </VodContext.Provider>
  )
}

export const useVodContext = () => React.useContext(VodContext)
