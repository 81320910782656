import { apiConfig } from '../config/api'
import { HttpService } from './http'

export class ResendTokenService {
  public static async resendToken(email: string) {
    const result = await HttpService.post({
      url: `${apiConfig.baseUrl}/users/resend`,
      data: { email },
      config: { withCredentials: true },
    })

    if (!result) {
      throw new Error()
    }

    return result
  }
}
