import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility/breakpoint'

type DivProps = JSX.IntrinsicElements['div']

interface LineContainerProps extends DivProps {
  standalone?: boolean
}

export const LineContainer: React.FC<LineContainerProps> = ({
  children,
  standalone = true,
  ...rest
}) => {
  return (
    <LineContainerOuter standalone={standalone} {...rest}>
      <InnerContainer></InnerContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </LineContainerOuter>
  )
}

export default LineContainer

const LineContainerOuter = styled.div<Pick<LineContainerProps, 'standalone'>>(
  ({ theme: { grid }, standalone }) => ({
    marginLeft: standalone ? `-${100 / grid.columns}vw` : undefined,
    display: 'flex',
  })
)

const InnerContainer = styled.div(({ theme }) => ({
  flexBasis: '6px',
  flexShrink: 0,
  marginTop: '.95rem',
  marginBottom: '.8rem',
  background: theme.colors.lineDecorator,
}))

const ChildrenContainer = styled.div(({ theme }) => ({
  marginLeft: `calc(${200 / theme.grid.columns}vw - 6px)`,

  '& h1': {
    margin: 0,
  },

  [mq[2]]: {
    marginLeft: `calc(${100 / theme.grid.columns}vw - 6px)`,
  },
}))

const LineDecoration = styled.div(({ theme: { colors } }) => ({
  // width: '6px',
  // background: colors.lineDecorator,
  // position: 'absolute',
  // left: 0,
  // top: '.95rem',
  // bottom: '.8rem',
}))

const LineContent = styled.div(({ theme: { grid } }) => ({
  // marginLeft: `calc(${200 / grid.columns}vw - 6px)`,
  // marginTop: 0,
  // marginBottom: 0,
  // '& h1': {
  //   margin: 0,
  // },
  // [mq[2]]: {
  //   marginLeft: `calc(${100 / grid.columns}vw - 6px)`,
  // },
}))
