import styled from '@emotion/styled'
import {
  getSpacingString,
  ISpacing,
  TSizeLevel,
} from '../../../utility/styleProps'

interface ISideblock {
  spacing?: ISpacing
  noPadding?: boolean
}

export const Sideblock = styled.div<ISideblock>(({ spacing, noPadding }) => ({
  paddingRight: noPadding ? 0 : '1rem',
  flex: 15,
  marginTop: getSpacingString(spacing?.top),
  marginRight: getSpacingString(spacing?.right),
  marginBottom: getSpacingString(spacing?.bottom),
  marginLeft: getSpacingString(spacing?.left),
}))
