import { GenIcon, IconBaseProps } from 'react-icons'
export const SieCalendar = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M10.9,10.9h2v2h-2V10.9z M2,2v19.8h19.8V2H2z M19.9,19.9H4V8.9h15.9V19.9z M19.9,7H4V4h15.9V7z M7,10.9h2v2H7V10.9z M14.9,10.9h2v2h-2V10.9z M7,14.9h2v2H7V14.9z M10.9,14.9h2v2h-2V14.9z M14.9,14.9h2v2h-2V14.9z',
        },
        child: [],
      },
    ],
  })(props)
