import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface RadiobuttonGroupProps {
  label?: string
  error?: string
}

export const RadiobuttonGroup: React.FC<RadiobuttonGroupProps> = ({
  children,
  label,
  error,
}) => {
  return (
    <RadiobuttonGroupContainer className={error && 'error'}>
      {label && <span>{label}</span>}
      <div>{children}</div>
      {error && <Error>{error}</Error>}
    </RadiobuttonGroupContainer>
  )
}

export const RadiobuttonGroupContainer = styled.div(
  ({ theme: { colors, fonts } }) => `
  font-family: ${fonts.fontFamily};
  font-size:18px;
  padding:13px 16px 13px 16px;
  border:none;
  outline:none;
  background:${colors.inputBackground};
  border-bottom:1px solid ${colors.inputBorder};
  color:${colors.inputText};
  box-sizing: border-box;
  position:relative;
  &:hover, &:focus{
    background:${colors.inputBackgroundHover};
    border-bottom:1px solid ${colors.inputBorderHover};
  }
  & > div{
    display:flex;
    & > *{
      margin-right:15px;
    }
  }
  & > span{
    position:absolute;
    font-size:12px;
    font-family: ${fonts.fontFamily};
    color:${colors.inputBorder};
    left:16px;
    top:2px;
  }
  &.error{
    background:${rgba(colors.error, 0.2)};
    border-bottom:1px solid ${colors.error};
  }
`
)
export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  bottom:-20px;
  left:0;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
