import styled from '@emotion/styled'
import { mq } from '../../../utility'

interface InnerContainerProps {
  width?: string
  align?: string
}

export const InnerContainer = styled.div<InnerContainerProps>(
  ({ theme: { grid }, width }) => ({
    display: 'block',
    gridTemplateColumns: `repeat(${grid.columns},1fr)`,
    gridTemplateRows: '1fr',
    marginTop: '4rem',

    [mq[3]]: {
      width: width ? width : 'auto',
      alignItems: 'center',
    },
    marginLeft: `${100 / grid.columns}vw`,
  })
)
