import { GenIcon, IconBaseProps } from 'react-icons'
export const SiePlus = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M21.9,10.9h-8.8V2.1h-2.2v8.8H2.1v2.2h8.8v8.8h2.2v-8.8h8.8V10.9z',
        },
        child: [],
      },
    ],
  })(props)
