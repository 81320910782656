import { GenIcon, IconBaseProps } from 'react-icons'
export const SieEnvelope = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M2.1,4.1v15.9h19.8V4.1H2.1z M18.3,6L12,11.7L5.7,6H18.3z M4.1,18V7.3l7.9,7.1l7.9-7.1V18H4.1z',
        },
        child: [],
      },
    ],
  })(props)
