import { GenIcon, IconBaseProps } from 'react-icons'
export const SieLinkedin = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M8.1,9.6v9.3H5.2V9.6H8.1z M15.5,9.4c2.9,0,3.5,1.9,3.5,4.4l0,0v5.1h-2.9v-4.6c0-1.1-0.1-2.4-1.5-2.4c-1.5,0-1.7,1.2-1.7,2.4l0,0v4.6h-3V9.6h2.8v1.3h0.1C13.1,10.2,14.1,9.4,15.5,9.4L15.5,9.4z M6.7,5L6.7,5c1,0.1,1.6,0.8,1.6,1.7S7.5,8.4,6.6,8.4C5.8,8.4,5,7.6,5,6.7S5.8,5,6.7,5L6.7,5z',
        },
        child: [],
      },
    ],
  })(props)
