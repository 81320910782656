import React, { Component } from 'react'
import {
  I18nextProvider,
  withTranslation,
  WithTranslation,
} from 'react-i18next'
import { PageFrameProps } from '../components/organisms'
import i18next from './config'

export const withTrans = (ComponentToWrap: React.FC<PageFrameProps>) => {
  const WrappedComponent: React.FC<WithTranslation> = withTranslation()(
    ComponentToWrap
  )

  return class extends Component {
    render() {
      return (
        <I18nextProvider i18n={i18next}>
          <WrappedComponent {...this.props} language={i18next.language} />
        </I18nextProvider>
      )
    }
  }
}
