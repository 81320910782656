import { GenIcon, IconBaseProps } from 'react-icons'
export const SieFilter = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M11,20.9V14h2v6.9H11z M13,14h-2L2.1,3.1h19.8L13,14z M11.9,12h0.1l5.7-6.9H6.3L11.9,12z',
        },
        child: [],
      },
    ],
  })(props)
