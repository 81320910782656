import { GenIcon, IconBaseProps } from 'react-icons'
export const SieVolumeUp = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M16,16.1l-1.4-1.4c1-0.5,1.7-1.6,1.7-2.7c0-1.2-0.6-2.2-1.6-2.7l1.3-1.4c1.3,0.9,2.1,2.4,2.1,4.1C18.2,13.7,17.3,15.2,16,16.1L16,16.1z M18.7,18.7l-1.3-1.3c1.6-1.3,2.7-3.2,2.7-5.4c0-2.2-1-4.1-2.6-5.4l1.3-1.3c2,1.6,3.2,4,3.2,6.7C21.9,14.7,20.7,17.1,18.7,18.7L18.7,18.7z M2.1,9.5l1.9-0.1v5.2l-1.9-0.1V9.5z M5.8,9.4l7.4-3.6v12.4l-7.4-3.6V9.4z',
        },
        child: [],
      },
    ],
  })(props)
