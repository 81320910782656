import { GenIcon, IconBaseProps } from 'react-icons'
export const SieTwitter = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M8.6,19.6c-2.1,0-4-0.6-5.6-1.7C3.3,18,3.6,18,3.9,18c1.7,0,3.3-0.6,4.6-1.6c-1.6,0-3-1.1-3.4-2.6c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.7,0,1-0.1c-1.7-0.3-3-1.8-3-3.6l0,0c0.5,0.3,1.1,0.4,1.7,0.5c-1-0.7-1.6-1.8-1.6-3.1c0-0.7,0.2-1.3,0.5-1.9C6.2,7.9,8.9,9.4,12,9.5c-0.1-0.3-0.1-0.5-0.1-0.8c0-2,1.7-3.7,3.7-3.7c1.1,0,2,0.4,2.7,1.2C19,6,19.7,5.7,20.5,5.3c-0.3,0.9-0.9,1.6-1.6,2C19.6,7.2,20.3,7,21,6.7c-0.5,0.7-1.1,1.4-1.8,1.9c0,0.2,0,0.3,0,0.5C19.1,14,15.4,19.6,8.6,19.6L8.6,19.6z',
        },
        child: [],
      },
    ],
  })(props)
