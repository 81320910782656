import { GenIcon, IconBaseProps } from 'react-icons'
export const SieStarFilled = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M21.8,9.6l-7.1-0.8L11.9,2L9.1,8.8L2,9.6l5.3,5l-1.5,7.3l6.1-3.8l6.1,3.8l-1.5-7.3L21.8,9.6z',
        },
        child: [],
      },
    ],
  })(props)
