import { v4 as uuidv4 } from 'uuid'

import { AppointmentFormData } from './../components/organisms/appointmentForm'
const addUuid = (src: AppointmentTopicProps[]) =>
  src.map(e => {
    let ret = { ...e, uuid: uuidv4() }
    if (e.children) {
      ret = { ...ret, children: addUuid(e.children) }
    }

    return ret
  })

interface TopicsFlat {
  uuid: string | undefined
  value: string[]
}

const flattenTopics = (src: AppointmentTopicProps[]) => {
  const topics: TopicsFlat[] = []
  // LEVEL 1
  src.forEach(elem1 => {
    topics.push({ uuid: elem1.uuid, value: elem1.value })
    // LEVEL 2
    elem1.children?.forEach(elem2 => {
      topics.push({ uuid: elem2.uuid, value: elem2.value })
      // LEVEL 3
      elem2.children?.forEach(elem3 => {
        topics.push({ uuid: elem3.uuid, value: elem3.value })
      })
    })
  })

  return topics
}

/* const uuidTopics: AppointmentTopicProps[] = topics //addUuid(topics)
const uuidTopicsFlat: AppointmentTopicProps[] = flattenTopics(topics) */
interface AppointmentTopicProps {
  label: string
  value: string[]
  business?: string
  businessUnit?: string
  position?: string | null
  uuid?: string
  children?: AppointmentTopicProps[]
}

export interface Topic extends Pick<AppointmentTopicProps, 'label' | 'uuid'> {}

export class AppointmentTopics {
  public static getMainTopics(topics): Topic[] {
    return topics?.map(({ label, uuid }) => ({ label, uuid }))
  }
  public static getLevel2Topics(
    topics,
    level2Uuid: string | null
  ): Topic[] | null {
    return (
      topics
        ?.find(({ uuid }) => uuid === level2Uuid)
        ?.children?.map(({ label, uuid }) => ({ label, uuid })) || null
    )
  }

  public static getLevel2BusinessUnit(topics, level2Uuid: string | null) {
    let found = null
    topics?.forEach(({ children: lvl1 }) => {
      lvl1?.forEach(({ children: lvl2, uuid: lvl2Uuid, businessUnit }) => {
        if (lvl2Uuid === level2Uuid) {
          found = businessUnit || null
        }
      })
    })

    return found
  }

  public static getLevel3Topics(
    topics,
    level3Uuid: string | null
  ): Topic[] | null {
    let found = null
    topics?.forEach(({ children: lvl1 }) => {
      lvl1?.forEach(({ children: lvl2, uuid: lvl2Uuid, label }) => {
        if (lvl2Uuid === level3Uuid) {
          found = lvl2 || null
        }
      })
    })

    return found
  }

  public static parseFormData = (data: AppointmentFormData, topics) => {
    const {
      note,
      preferedDate,
      alternativeDate,
      alternativeSecondDate,
      country,
      meetingLanguage,
    } = data

    const appointmentSlots = []
    if (preferedDate) {
      appointmentSlots.push(Number(preferedDate))
    }
    if (alternativeDate) {
      appointmentSlots.push(Number(alternativeDate))
    }
    if (alternativeSecondDate) {
      appointmentSlots.push(Number(alternativeSecondDate))
    }

    // Reduce Units
    const tags = [
      ...[...new Set(AppointmentTopics.parseFormtopics(data, topics))],
      `#${meetingLanguage.toUpperCase()}`,
      `Country: ${country}`,
    ]

    console.log('tags', tags)

    return {
      description: note,
      proposals: appointmentSlots,
      tags: tags.filter(tag => tag !== ''),
    }
  }

  public static parseFormtopics = (data: AppointmentFormData, topics) => {
    const { topicLevel1, topicLevel2, topicLevel3, topicLevel3_1 } = data

    console.log('data', data)

    const uuidTopicsFlat: AppointmentTopicProps[] = flattenTopics(topics)

    let tags = []

    if (topicLevel3 || topicLevel3_1) {
      if (topicLevel3 && topicLevel3 !== '0') {
        /*  tags = [
          ...tags,
          ...uuidTopicsFlat.find(({ uuid }) => uuid === topicLevel3).value,
        ] */
        tags = tags.concat(
          uuidTopicsFlat.find(({ uuid }) => uuid === topicLevel3).value
        )
      }
      if (topicLevel3_1 && topicLevel3_1 !== '0') {
        tags = tags.concat(
          uuidTopicsFlat.find(({ uuid }) => uuid === topicLevel3_1).value
        )
      }
      return tags
    }

    if (topicLevel2) {
      return uuidTopicsFlat.find(({ uuid }) => uuid === topicLevel2).value
    }

    if (topicLevel1) {
      return uuidTopicsFlat.find(({ uuid }) => uuid === topicLevel1).value
    }
  }
}

const parseTags = (level: AppointmentTopicProps) => {
  let ret = {
    businessUnit: [],
    business: '',
    position: '',
  }

  if (level.businessUnit) {
    ret = { ...ret, businessUnit: level?.businessUnit.split(',') }
  }
  if (level.business) {
    ret = { ...ret, business: level.business }
  }
  if (level.position) {
    ret = { ...ret, position: level.position }
  }

  return ret
}
