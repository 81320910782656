import React from 'react'
import { AnalyticsTransformer } from '../utility/analytics'

interface State {
  showSlideIn: boolean
  // NEED TO FIX
  // tslint:disable-next-line: no-any
  component?: any
  params?: {}
}

const defaultState: State = {
  showSlideIn: false,
}
export interface SlideInAction {
  type: 'OPEN_WITH_COMPONENT' | 'HIDE_SLIDEIN'
  state?: State
}

const reducer: React.Reducer<State, SlideInAction> = (state, action) => {
  switch (action.type) {
    case 'OPEN_WITH_COMPONENT':
      return {
        ...state,
        showSlideIn: true,
        component: action.state?.component,
        params: action.state?.params,
      }
    case 'HIDE_SLIDEIN':
      AnalyticsTransformer.customEvent(['slidein', `close`])

      return {
        showSlideIn: false,
      }
    default:
      return { showSlideIn: false }
  }
}

export const SlideInContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<ModalAction>
}>({ state: defaultState, dispatch: () => null })

export const SlideInContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  return (
    <SlideInContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SlideInContext.Provider>
  )
}

export const useSlideInContext = () => React.useContext(SlideInContext)
