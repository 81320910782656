import styled from '@emotion/styled'
import React from 'react'
import { BoothRatio, ShowRoomHotspot } from './'
import { ShowroomItem } from './../../../api'
import { mq } from './../../../utility'

interface ShowroomBoothProps {
  data: ShowroomItem[]
  boothRatio: BoothRatio
  activeElement?: string
  onHighlight?(slug: string): void
}

export const ShowroomBooth: React.FC<ShowroomBoothProps> = ({
  boothRatio,
  activeElement = '',
  onHighlight,
  data,
}) => {
  return (
    <Container>
      {data.map(elem => (
        <ShowRoomHotspot
          key={elem.slug}
          onHighlight={slug => onHighlight && onHighlight(slug)}
          active={elem.slug === activeElement}
          boothRatio={boothRatio}
          {...elem}
        />
      ))}
    </Container>
  )
}
const Container = styled.div(
  ({ theme: { colors, grid } }) => `
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  ${mq[2]}{
    top: 0%;
    left: 0%;
    width: 120%;
    height: 120%;
  }

  ${mq[3]}{
    top: -20%;
    left: -20%;
    width: 160%;
    height: 160%;
  }
  background:  url(/assets/showroom/island.png) center center / contain;
  &:before{
    content:"";
    z-index:-1;
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    background: radial-gradient(ellipse, rgba(1,162,164,1) 0%, rgba(0,212,255,0) 65%);
  }
`
)
