import { GenIcon, IconBaseProps } from 'react-icons'
export const SieFullscreenEnter = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M20,22h-5v-2h5v-5h2v7H20z M4,22H2v-7h2v5h5v2H4z M20,9V4h-5V2h7v7H20z M2,9V2h7v2H4v5H2z M20,22h-5v-2h5v-5h2v7H20z M4,22H2v-7h2v5h5v2H4z M20,9V4h-5V2h7v7H20z M2,9V2h7v2H4v5H2z',
        },
        child: [],
      },
    ],
  })(props)
