import { GenIcon, IconBaseProps } from 'react-icons'
export const SieVolumeMute = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M2.1,9.5l1.9-0.1v5.2l-1.9-0.1V9.5z M5.8,9.4l7.4-3.6v12.4l-7.4-3.6V9.4z M22.5,9.6l-1.2-1.2l-3,3l-3-3l-1.2,1.2l3,3l-3,3l1.2,1.2l3-3l3,3l1.2-1.2l-3-3L22.5,9.6z',
        },
        child: [],
      },
    ],
  })(props)
