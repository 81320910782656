import { GenIcon, IconBaseProps } from 'react-icons'
export const SieClockDisable = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M12.5,14.9l1.4-1.4l1.8,1.7l-1.4,1.4L12.5,14.9z M11,9.5l2-2V7h-2V9.5z M4.9,15.6c-0.6-1.1-0.9-2.3-0.9-3.6c0-4.4,3.6-7.9,7.9-7.9c1.3,0,2.5,0.3,3.6,0.9l1.5-1.5c-1.5-0.9-3.2-1.4-5.1-1.4c-5.5,0-9.9,4.4-9.9,9.9c0,1.9,0.5,3.6,1.4,5.1L4.9,15.6z M19.1,8.4c0.6,1.1,0.9,2.3,0.9,3.6c0,4.4-3.6,7.9-7.9,7.9c-1.3,0-2.5-0.3-3.6-0.9l-1.5,1.5c1.5,0.9,3.2,1.4,5.1,1.4c5.5,0,9.9-4.4,9.9-9.9c0-1.9-0.5-3.6-1.4-5.1L19.1,8.4z M19.7,2.8L2.8,19.7l1.4,1.4L21.2,4.3L19.7,2.8z',
        },
        child: [],
      },
    ],
  })(props)
