import styled from '@emotion/styled'
import React from 'react'

type DivProps = JSX.IntrinsicElements['div']
interface ContentContainerProps extends DivProps {
  fullheight?: boolean
  fullwidth?: boolean
  docked?: boolean
  align?: string
}

export const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  fullheight,
  fullwidth,
  docked,
  ...rest
}) => {
  return (
    <ContentContainerOuter fullheight={fullheight} docked={docked}>
      <ContentContainerInner
        fullheight={fullheight}
        fullwidth={fullwidth}
        {...rest}
      >
        {children}
      </ContentContainerInner>
    </ContentContainerOuter>
  )
}
const ContentContainerOuter = styled.div<ContentContainerProps>(
  ({ theme: { grid }, fullheight, docked }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.columns},1fr)`,
    gridTemplateRows: '1fr',
    minHeight: fullheight ? '100vh' : 'auto',
    marginTop: `${docked ? '-68px' : '0'}`,
  })
)

const ContentContainerInner = styled.div<ContentContainerProps>(
  ({ align, fullwidth }) => `
  grid-column: ${fullwidth ? '1/37' : '3/35'};
  ${align && `display:flex; justify-content:${align}`};
`
)
