import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
const httpClient = axios.create()
httpClient.defaults.timeout = 25000
export class HttpService {
  public static async post<T = {}>({
    url,
    data,
    config,
  }: {
    url: string
    data?: object
    config?: AxiosRequestConfig
  }): Promise<AxiosResponse | false> {
    try {
      const result = await httpClient.post<T>(url, data, config)

      return result
    } catch (err) {
      return false
    }
  }

  public static async get<T = {}>({
    url,
    config,
  }: {
    url: string
    config?: AxiosRequestConfig
  }): Promise<AxiosResponse | false> {
    try {
      const result = await httpClient.get<T>(url, config)

      return result
    } catch (err) {
      return false
    }
  }
}
