import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AdhocService, AuthService, Tag } from '../../../api'
import { mq, useModalContext } from '../../../utility'
import { Button } from '../button'
import { Heading } from '../heading'
import { SieChat } from '../icons'
import { LineContainer } from '../lineContainer'
import { LoadingIndicator } from '../loadingIndicator'
import { LoadingSpinner } from '../loadingSpinner'
import { Paragraph } from '../paragraph'
import { Select } from '../select'

export const INITIAL_STATUS = null

interface DataInitial {
  since: null
  status: typeof INITIAL_STATUS
  meetingURL: null
}

interface DataQueued {
  since: string
  status: 'queued'
  meetingURL: null
}

interface DataRejected extends Omit<DataQueued, 'status'> {
  status: 'rejected'
}

interface DataConfirmed {
  since: string
  status: 'confirmed'
  meetingURL: string
}

interface DataCancelled {
  since: string
  status: 'canceled'
  meetingURL: null
}

type AdHocData =
  | DataInitial
  | DataQueued
  | DataConfirmed
  | DataRejected
  | DataCancelled

interface AdhocModalState {
  data: AdHocData
  selectedTag: Tag
}

interface AdhocModalProps {
  initialState?: AdHocData
}

const isTag = (x: string): x is Tag => {
  return Object.values(Tag).includes(x as Tag)
}

const isDataQueued = (x: unknown): x is DataQueued => {
  const { since, status } = x as DataQueued

  return typeof since === 'string' && status === 'queued'
}

const isDataRejected = (x: unknown): x is DataRejected => {
  const { status } = x as DataRejected

  return status === 'rejected'
}

const isDataConfirmed = (x: unknown): x is DataConfirmed => {
  const { status, meetingURL } = x as DataConfirmed

  return status === 'confirmed' && typeof meetingURL === 'string'
}

const isDataCancelled = (x: unknown): x is DataCancelled => {
  const { status } = x as DataCancelled

  return status === 'canceled'
}

const DEFAULT_INITIAL_STATE: DataInitial = {
  meetingURL: null,
  since: null,
  status: INITIAL_STATUS,
}

const POLLING_INTERVAL_MS = 2000

const startDate = TimeUtil.getUtc('2021-10-04T06:00')
const endDate = TimeUtil.getUtc('2021-10-08T16:00')

export const getIsAdhocAvailable = () => {
  const now = TimeUtil.getNowUtc()

  const isBefore = now.isBefore(startDate)
  const isAfter = now.isAfter(endDate)

  if (isBefore || isAfter) {
    return false
  }

  if (now.hour() < startDate.hour()) {
    return false
  }

  if (now.hour() >= endDate.hour()) {
    return false
  }

  return true
}

const CATEGORY_TAG = 'Category: AdHoc Talk'
const COUNTRY_TAG = (country: string) => `Country: ${country}`

export const AdhocModal: React.FC<AdhocModalProps> = ({
  initialState = DEFAULT_INITIAL_STATE,
}) => {
  const { dispatch: modalDispatch } = useModalContext()
  const isAdhocAvailable = getIsAdhocAvailable()
  const now = TimeUtil.getNowUtc()
  const isEventOver = now.isAfter(endDate)

  // turning the Enum Tag into an iterable array
  const tags = Object.values(Tag)

  console.log({ tags })

  const [state, setState] = React.useState<AdhocModalState>({
    data: initialState,
    selectedTag: tags[0],
  })

  const { t } = useTranslation('adhoc')

  const handleTransitionInitial = async () => {
    const me = await AuthService.me()

    const languageTag = me.person.language === 'en' ? '#EN' : '#DE'
    const selectedTag = `Ad Hoc Topic: ${state.selectedTag}`

    const responseSendRequest = await AdhocService.sendRequest([
      selectedTag,
      languageTag,
      COUNTRY_TAG(me.person.fields.country),
      CATEGORY_TAG,
    ])

    if (responseSendRequest) {
      const responseGetStatus = await AdhocService.getStatus()

      console.log({ responseGetStatus })

      if (isDataQueued(responseGetStatus)) {
        setState(s => ({ ...s, data: responseGetStatus }))
      }
    }
  }

  // this useEffect handles the queued state, in an interval of the length of POLLING_INTERVAL_MS
  // the getStatus function is called until the request is either rejected or confirmed
  React.useEffect(() => {
    if (state.data.status !== 'queued' || !isAdhocAvailable) {
      return
    }

    const intervalId = window.setInterval(async () => {
      const responseGetStatus = await AdhocService.getStatus()

      console.log({ responseGetStatus }, 'interval', intervalId)

      if (
        isDataRejected(responseGetStatus) ||
        isDataConfirmed(responseGetStatus) ||
        isDataCancelled(responseGetStatus)
      ) {
        setState(s => ({ ...s, data: responseGetStatus }))
      }
    }, POLLING_INTERVAL_MS)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [state.data.status, isAdhocAvailable])

  // set initial state
  // if the current status is either confirmed or queued, it should get updated
  React.useEffect(() => {
    ;(async () => {
      const response = await AdhocService.getStatus()

      console.log({ response })

      if (!response) {
        return
      }

      const { status } = response

      if (status !== 'confirmed' && status !== 'queued') {
        return
      }

      // otherwise update the state
      if (response.status !== state.data.status) {
        setState(s => ({ ...s, data: response }))
      }
    })()
  }, [])

  return (
    <AdhocModalContainer>
      <AdhocModalContainerInner>
        {!isAdhocAvailable && !isEventOver && (
          <>
            <LineContainer standalone={false}>
              <Heading type="h4">{t('adhocUnavailable.title')}</Heading>
            </LineContainer>

            <Paragraph>{t('adhocUnavailable.text')}</Paragraph>

            <Button onClick={() => modalDispatch({ type: 'HIDE_MODAL' })}>
              {t('adhocUnavailable.cta')}
            </Button>
          </>
        )}

        {!isAdhocAvailable && isEventOver && (
          <>
            <LineContainer standalone={false}>
              <Heading type="h4">{t('eventOver.title')}</Heading>
            </LineContainer>

            <Paragraph>{parse(t('eventOver.text'))}</Paragraph>

            <Button onClick={() => modalDispatch({ type: 'HIDE_MODAL' })}>
              {t('eventOver.cta')}
            </Button>
          </>
        )}

        {state.data.status === INITIAL_STATUS && isAdhocAvailable && (
          <>
            <LineContainer standalone={false}>
              <Heading type="h4">{t('select.title')}</Heading>
            </LineContainer>

            <Paragraph>{t('select.text')}</Paragraph>

            <AdhocModalSelectContainer>
              <Select
                placeholder={t('select.selectTitle')}
                onChange={e => {
                  const selectedTag = e.currentTarget.value

                  if (isTag(selectedTag)) {
                    setState(s => ({ ...s, selectedTag }))
                  }
                }}
              >
                {tags.map((s, i) => {
                  return (
                    <option key={i} value={`${s}`}>
                      {t(s)}
                    </option>
                  )
                })}
              </Select>
            </AdhocModalSelectContainer>
            <AdhocModaCtaContainer>
              <Button onClick={handleTransitionInitial}>
                {t('select.cta')}
              </Button>
            </AdhocModaCtaContainer>
          </>
        )}

        {state.data.status === 'queued' && isAdhocAvailable && (
          <QueuedContainer>
            <QueuedLoadingContainer>
              <LoadingIndicator />
              <Paragraph>{t('queued.loading')}</Paragraph>
            </QueuedLoadingContainer>

            <Heading>{t('queued.title')}</Heading>
            <Heading type="h2">{t(state.selectedTag)}</Heading>
          </QueuedContainer>
        )}

        {state.data.status === 'confirmed' && isAdhocAvailable && (
          <ConfirmedContainer>
            <LineContainer standalone={false} style={{ width: '100%' }}>
              <Heading type="h4">{t('confirmed.title')}</Heading>
            </LineContainer>

            <Paragraph>{t('confirmed.text')}</Paragraph>

            <AdhocCtaLink
              href={state.data.meetingURL}
              target="_blank"
              rel="noopener"
            >
              <Button>{t('confirmed.cta')}</Button>
            </AdhocCtaLink>
          </ConfirmedContainer>
        )}

        {(state.data.status === 'canceled' ||
          state.data.status === 'rejected') &&
          isAdhocAvailable && (
            <ConfirmedContainer>
              <Paragraph>{t('canceled.text')}</Paragraph>

              <Button
                plain
                onClick={() => modalDispatch({ type: 'HIDE_MODAL' })}
              >
                {t('canceled.cta')}
              </Button>
            </ConfirmedContainer>
          )}
      </AdhocModalContainerInner>
    </AdhocModalContainer>
  )
}

export interface AdhocCtaProps {
  small?: boolean
}

export const AdhocCta: React.FC<AdhocCtaProps> = ({ small }) => {
  const { t } = useTranslation('adhoc')
  const { dispatch: modalDispatch } = useModalContext()

  return (
    <ButtonContainer>
      <Button
        icon={SieChat}
        onClick={() =>
          modalDispatch({
            type: 'OPEN_WITH_COMPONENT',
            state: {
              component: AdhocModal,
              showModal: true,
              params: {
                onClose: () => modalDispatch({ type: 'HIDE_MODAL' }),
              },
            },
          })
        }
        iconOnly={small}
      >
        {!small && t('cta')}
      </Button>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div({
  position: 'fixed',
  bottom: '2.5rem',
  right: '2.5rem',
  zIndex: 99,
})

const AdhocModalContainer = styled.div({
  padding: '2.5rem',
  width: '80vw',
  display: 'flex',
  justifyContent: 'center',
})

const AdhocModalContainerInner = styled.div({
  flexBasis: '100%',

  [`${mq[2]}`]: {
    flexBasis: '50%',
  },
})

const QueuedContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  // textAlign: 'center',
})

const QueuedLoadingContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& > p': {
    marginTop: '1rem',
  },
})

const AdhocModaCtaContainer = styled.div({ margin: '1rem 0' })

const AdhocModalSelectContainer = styled.div({ margin: '1.5rem 0' })

const AdhocCtaLink = styled.a({ textDecoration: 'none' })

const ConfirmedContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
