import styled from '@emotion/styled'
import {
  getSpacingString,
  ISpacing,
  TSizeLevel,
} from '../../../utility/styleProps'

interface ILinkProps {
  href: string
  spacing?: ISpacing
  size?: TSizeLevel
  black?: boolean
}

export const Link = styled.a<ILinkProps>(
  ({ theme: { fonts, colors }, spacing, size = 0, black, href }) => ({
    href: href,
    color: colors.linkOnDark,
    fontFamily: black ? fonts.fontFamilyBlack : fonts.fontFamily,
    fontSize: fonts.text.sizes[size],
    marginTop: getSpacingString(spacing?.top, 1),
    marginRight: getSpacingString(spacing?.right),
    marginBottom: getSpacingString(spacing?.bottom),
    marginLeft: getSpacingString(spacing?.left),
  })
)
