import styled from '@emotion/styled'
import React from 'react'

type DivProps = JSX.IntrinsicElements['div']
interface CardProps extends DivProps {
  image?: string
}

export const Card: React.FC<CardProps> = ({ children, image, ...rest }) => {
  return (
    <CardContainer {...rest}>
      {image && <CardImageContainer url={image} />}
      <CardContentContainer>{children}</CardContentContainer>
    </CardContainer>
  )
}
const CardContainer = styled.div(({ theme }) => ({}))

const CardImageContainer = styled.div<{ url: string }>(({ url }) => ({
  paddingBottom: `${(3 / 4) * 100}%`,
  backgroundImage: `url('${url}')`,
  backgroundSize: 'contain',
}))

const CardContentContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bodyBackground,
  padding: '1.5rem 0',
}))
