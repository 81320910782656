"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languages = void 0;
exports.languages = {
    languages: [
        {
            key: 'en',
            label: 'English',
        },
    ],
    defaultLanguage: 'en',
};
