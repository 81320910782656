import styled from '@emotion/styled'

interface AgendaCtaProps {
  inverted?: boolean
}

export const AgendaCta = styled.button<AgendaCtaProps>(
  ({ theme: { colors }, inverted }) => `
  margin-left:16px;
  width:48px;
  height:48px;
  border-radius:999px;
  border:2px solid ${inverted ? colors.linkOnDark : colors.link};
  background:${inverted ? colors.linkOnDark : 'none'};
  outline:0;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items:center;
  svg{
    width:24px;
    height:24px;
    fill:${inverted ? colors.bodyBackground : colors.link};

  }
  &:hover {
    background:none;
    border-color:${inverted ? colors.link : colors.linkOnDark};
    svg{ fill:${inverted ? colors.link : colors.linkOnDark}; }
  }
`
)
