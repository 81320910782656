import React from 'react'
interface State {
  // NEED TO FIX
  // tslint:disable-next-line: no-any
  status?: any
}

const defaultState: State = {
  status: undefined,
}
export interface GlobalAction {
  type: 'SET_STATUS' | 'CLEAR_STATUS'
  status?: State
}

const reducer: React.Reducer<State, GlobalAction> = (state, action) => {
  switch (action.type) {
    case 'SET_STATUS':
      return { status: action.status }
    case 'CLEAR_STATUS':
      return defaultState
    default:
      return defaultState
  }
}

export const GlobalContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<GlobalAction>
}>({ state: defaultState, dispatch: () => null })

export const GlobalContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => React.useContext(GlobalContext)
