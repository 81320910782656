import { GenIcon, IconBaseProps } from 'react-icons'
export const SieInfo = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M2.1,12c0-5.5,4.4-9.9,9.9-9.9s9.9,4.4,9.9,9.9s-4.4,9.9-9.9,9.9C6.5,21.9,2.1,17.5,2.1,12z M4.1,12c0,4.4,3.6,7.9,7.9,7.9s7.9-3.6,7.9-7.9S16.4,4.1,12,4.1C7.6,4.1,4.1,7.6,4.1,12z M10.9,17.2V11h2.1v6.2H10.9z M11.3,9.1c-0.4-0.2-0.6-0.7-0.6-1.1c0-0.7,0.5-1.2,1.1-1.2c0,0,0.1,0,0.1,0c0.6,0,1.2,0.5,1.2,1.1c0,0,0,0,0,0.1c0.1,0.7-0.4,1.3-1.1,1.3C11.8,9.3,11.6,9.2,11.3,9.1L11.3,9.1z',
        },
        child: [],
      },
    ],
  })(props)
