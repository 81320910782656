import { jsx, useTheme } from '@emotion/react'
import React from 'react'
import { getSpacingString, ISpacing } from '../../../utility/styleProps'
import { mq } from './../../../utility/breakpoint'

export interface HeadingProps extends React.HTMLProps<HTMLElement> {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  spacing?: ISpacing
  underlined?: boolean
  dark?: boolean
  handCursor?: boolean
}

export const Heading: React.FC<HeadingProps> = ({
  type = 'h1',
  spacing,
  children,
  underlined,
  dark,
  handCursor,
  ...rest
}) => {
  const { colors, fonts } = useTheme()

  return jsx(
    type,
    {
      css: {
        display: 'block',
        cursor: handCursor ? 'pointer' : 'default',
        marginTop: getSpacingString(spacing?.top, 1),
        marginRight: getSpacingString(spacing?.right),
        marginBottom: getSpacingString(spacing?.bottom, 1),
        marginLeft: getSpacingString(spacing?.left),
        color: dark ? colors.textDark : colors.text,
        fontFamily: fonts.fontFamily,
        fontSize: fonts.headingSizesMobile[type],
        [mq[3]]: {
          fontSize: fonts.headingSizes[type],
        },
        borderBottom: underlined ? '1px solid white' : 'none',
        paddingBottom: underlined ? '10px' : '0',
      },
      ...rest,
    },
    children
  )
}
