import { GenIcon, IconBaseProps } from 'react-icons'
export const SieFacebook = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M13.8,21v-8.2h2.7l0.4-3.2h-3.1v-2c0-0.9,0.3-1.6,1.5-1.6H17V3.1c-0.2,0-1.3-0.1-2.4-0.1c-2.4,0-4,1.5-4,4.2v2.4H8v3.2h2.6V21H13.8z',
        },
        child: [],
      },
    ],
  })(props)
