import styled from '@emotion/styled'
import React from 'react'

type InputProps = Omit<JSX.IntrinsicElements['input'], 'ref'>
export interface ToggleProps extends InputProps {
  ref?: React.Ref<HTMLInputElement>
}

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ disabled, ...rest }, ref) => {
    return (
      <StyledLabel className={disabled ? 'disabled' : ''}>
        <StyledCheckbox
          {...rest}
          disabled={disabled}
          ref={ref}
          type="checkbox"
        />
        <StyledBar />
        <StyledIndicator />
      </StyledLabel>
    )
  }
)

const StyledCheckbox = styled.input({ display: 'none' })

const StyledBar = styled.div(
  ({ theme: { colors, fonts } }) => `
  width:100%;
  height:23px;
  background:${colors.tableBorder};
  border-radius:99999px;
`
)
const StyledIndicator = styled.div(
  ({ theme: { colors, fonts } }) => `
   width:28px;
  height:28px;
  background:${colors.inputText};
  border-radius:50%;
  position:absolute;
`
)

const StyledLabel = styled.label(
  ({ theme: { colors, fonts } }) => `
  cursor:pointer;
  display:inline-block;
  width:53px;
  height:28px;
  display:flex;
  align-items:center;
  position:relative;
  & input:checked ~ div:nth-of-type(1){
    background:${colors.buttonBackground};
  }
  & input:checked ~ div:nth-of-type(2){
    right:0;
  }

  &:hover{
    & input:checked ~ div:nth-of-type(2){
      background:${colors.tableBorder}
    }
    & input:not(:checked) ~ div:nth-of-type(1){
      background:${colors.inputText}
    }
  }

  &.disabled{
    opacity:.5;
    pointer-events:none;
  }

`
)
