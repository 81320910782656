import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FeatureFlag,
  LocalStorageUtil,
  OffsetToStringConfig,
  Speaker,
  Talk,
  TimeUtil,
} from 'cuenect-web-core'
import parse from 'html-react-parser'
import { TimezoneSwitch } from '../../molecules/timezoneSwitch'

import dayjs from 'dayjs'
import { rgba } from 'emotion-rgba'
import i18n from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AgendaReserve,
  Avatar,
  Button,
  SieClose,
  SieStarFilled,
  Text,
  TextToggle,
} from '../../'
import { mq } from '../../../utility'
import { AnalyticsTransformer } from '../../../utility/analytics'
import { AgendaReservate } from '../../atoms/agendaReservate/agendaReservate'
import { Bookmark } from '../../atoms/bookmark'
import { ProgramCategory, ProgramParticipants } from './../../../api'
import { eventConfig } from './../../../config'
import {
  getAbbr,
  useBookmarksContext,
  useReservationContext,
  useTimezoneContext,
} from './../../../utility'

export const AGENDA_TOGGLE = 'agendaToggle'
export interface IProgramEntry
  extends Pick<
    Talk,
    | 'id'
    | 'title'
    | 'startUtc'
    | 'endUtc'
    | 'videoUrl'
    | 'speakers'
    | 'description'
  > {
  shareable?: boolean
  categories?: ProgramCategory[]
  room?: ProgramCategory | null
  participants?: ProgramParticipants | null
  renderCta?(): React.ReactNode
  renderCustom?(): React.ReactNode
  // needs to be a tz database name
  // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  displayTimezone: string
  durationMs?: number
  offsetToStringConfig?: OffsetToStringConfig
  isBreak?: boolean
  showReservations?: boolean
  showAppointment?: boolean
  registered?: boolean
  showOnlyParticipations?: boolean
  toggleOpen?: boolean
  isBlockbuster?: boolean
  eventSlug?: string | null
  hideTestSession?: boolean
  selectedDate?: number
  meetingUrl?: string
  highlightLocation?: boolean
  location: string | null
}

export const ProgramEntry: React.FC<IProgramEntry> = React.memo(props => {
  const {
    id,
    title,
    categories,
    shareable,
    participants,
    startUtc,
    endUtc,
    displayTimezone,
    speakers,
    description,
    renderCta,
    renderCustom,
    offsetToStringConfig,
    isBreak,
    showReservations,
    showAppointment,
    registered,
    showOnlyParticipations,
    toggleOpen = false,
    isBlockbuster = false,
    eventSlug = null,
    hideTestSession = false,
    selectedDate = 0,
    isAppointment,
    meetingUrl = '',
    highlightLocation = false,
    location: eventLocation,
  } = props

  console.log({ props })

  const isStandalone = process.env.GATSBY_STANDALONE

  const { t } = useTranslation('program')
  const { t: tSalesForm } = useTranslation('salesform')

  const {
    state: { reservations },
    dispatch: dispatchReservation,
  } = useReservationContext()

  const {
    state: { bookmarks },
    dispatch: dispatchBookmark,
  } = useBookmarksContext()

  const {
    state: { userTimezone, eventTimezone, timezoneChecked },
    dispatch: dispatchTimezone,
  } = useTimezoneContext()

  const { capacityRemaining, signUp } = participants || {
    capacityRemaining: null,
    signUp: false,
  }

  const [more, setMore] = useState(false)

  const externalOpenSession = e => {
    if (location.hash === `#${id}`) {
      setMore(true)
    }
  }

  React.useEffect(() => {
    if (isStandalone) {
      if (location.hash === `#${id}`) {
        setMore(true)
      }

      window.addEventListener('hashchange', externalOpenSession)

      return () => {
        window.removeEventListener('hashchange', externalOpenSession)
      }
    }
  }, [])

  const toggleChange = (checked: boolean) => {
    setMore(checked)
    const current: string[] =
      LocalStorageUtil.getObject<string[]>(AGENDA_TOGGLE) || []

    LocalStorageUtil.saveData<string[]>(
      AGENDA_TOGGLE,
      checked ? [...current, id] : current.filter(item => item !== id)
    )

    if (checked) {
      AnalyticsTransformer.customEvent([
        'agenda',
        `readmore||${JSON.stringify({ uid: id })}`,
      ])
    }
  }

  const parseCategories = cat => {
    const hasFocus = cat.find(({ slug }) => slug === 'sys-focus-session')

    return (
      <Text size={18} spacing={{ bottom: 1 }}>
        {hasFocus ? `${hasFocus.title}: ` : ``}
        {cat
          .filter(
            ({ slug }) =>
              slug !== 'sys-focus-session' && slug !== 'sys-blockbuster-color'
          )
          .map(({ title: cTitle }) => `${cTitle}`)
          .join(' / ')}
      </Text>
    )
  }

  if (isBreak) {
    return (
      <BreakBlock>
        <Container>
          <FirstEmptyColumn />
          <BreakContainer>
            {renderCustom && renderCustom()}
            <BreakText>{t('lunchbreak')}</BreakText>
          </BreakContainer>
        </Container>
      </BreakBlock>
    )
  }

  const locationToShow = (() => {
    if (!highlightLocation) {
      return ''
    }

    if (eventLocation === 'on-site') {
      return tSalesForm('onSite')
    }

    return tSalesForm('virtual')
  })()

  return (
    <Container className={`${isBlockbuster ? 'blockbuster' : ''}`}>
      <Anchor id={id} />
      {/** Timer Column */}
      <TimeContainer>
        <ClockContainer>
          <Text black>{renderCustom && renderCustom()}</Text>
        </ClockContainer>
        {displayTimezone && (
          <TimezoneContainer>
            <Text size={14}>
              {getAbbr(
                displayTimezone,
                i18n.language,
                dayjs(startUtc.format('YYYY-MM-DD'))
              )}
            </Text>
            {userTimezone !== eventTimezone && (
              <TimezoneSwitch
                short
                userTimezone={userTimezone}
                eventTimezone={eventTimezone}
                onTimezoneChange={selected =>
                  dispatchTimezone({
                    type: 'CHANGE_TIMEZONE',
                    timezoneChecked: selected,
                  })
                }
                timezoneChecked={!timezoneChecked}
              />
            )}
          </TimezoneContainer>
        )}
      </TimeContainer>

      <ContentBlock>
        <Content>
          <Infos>
            <TitleContainer>
              <Titles>
                {categories &&
                  Object.keys(categories).length > 0 &&
                  parseCategories(categories)}
                <Text size={18} spacing={{ bottom: 1 }} black>
                  {highlightLocation && <Badge>{locationToShow}</Badge>}
                  {title}
                </Text>
              </Titles>
            </TitleContainer>
            <InfoContainer>
              {description && (
                <DescriptionContainer>
                  <DescInner>
                    <DescText isMore={more}>{parse(description)}</DescText>
                  </DescInner>
                </DescriptionContainer>
              )}

              {speakers && more && (
                <SpeakersContainer>
                  {!speakersHaveAvatar(speakers) && (
                    <Text>{speakers.join(', ')}</Text>
                  )}
                  {speakersHaveAvatar(speakers) &&
                    speakers.map((speaker: Speaker, index: number) => (
                      <Avatar key={index} speaker={speaker} />
                    ))}
                </SpeakersContainer>
              )}

              {description && speakers && (
                <MoreDescriptionContainer>
                  <TextToggle
                    textOn={t('toggle.open')}
                    textOff={t('toggle.close')}
                    checked={more}
                    onChecked={toggleChange}
                  />
                </MoreDescriptionContainer>
              )}
              {!isStandalone &&
                (signUp || showOnlyParticipations) &&
                eventLocation !== 'on-site' && (
                  <ExtContainer>
                    <Inline>
                      <AgendaReservate
                        {...{
                          startUtc,
                          endUtc,
                          id,
                          title,
                          eventSlug,
                          participants,
                          registered: isAppointment ? true : registered,
                          hideTestSession,
                          selectedDate,
                          isAppointment,
                          meetingUrl,
                        }}
                      />
                    </Inline>
                  </ExtContainer>
                )}
            </InfoContainer>
          </Infos>
          {/** Right buttons column: */}
          <Buttons>
            {!isStandalone && !showAppointment && (
              <Bookmark
                checked={bookmarks.includes(id)}
                onChange={({ currentTarget: { checked } }) => {
                  dispatchBookmark({
                    type: checked ? 'ADD_BOOKMARK' : 'REMOVE_BOOKMARK',
                    id,
                  })

                  AnalyticsTransformer.customEvent([
                    'bookmark',
                    `${checked ? 'add' : 'remove'}||${JSON.stringify({
                      uid: id,
                    })}`,
                  ])
                }}
              />
            )}
            <CTAContainer>
              {(shareable && renderCta && renderCta()) || null}{' '}
            </CTAContainer>
          </Buttons>
        </Content>
      </ContentBlock>
    </Container>
  )
})

export const ProgramEntryPlaceholder = () => {
  const { colors } = useTheme()

  return (
    <Container style={{ pointerEvents: 'none', userSelect: 'none' }}>
      <TimeContainer>
        <ClockContainer>
          <Placeholder width={'80px'} height={15} />
          <br />
          <Placeholder width={'40px'} />
        </ClockContainer>
      </TimeContainer>
      <ContentBlock style={{ border: 'none' }}>
        <Content>
          <Infos>
            <TitleContainer>
              <Titles>
                <Placeholder width={'80px'} height={15} /> <br /> <br />
              </Titles>
            </TitleContainer>
            <InfoContainer>
              <DescriptionContainer>
                <DescInner>
                  <DescText isMore={true}>
                    <Placeholder height={22} width={'80%'} />
                    <br />
                    <Placeholder height={22} width={'100%'} />
                    <br />
                    <Placeholder height={22} width={'40%'} /> <br />
                  </DescText>
                </DescInner>
              </DescriptionContainer>
              <MoreDescriptionContainer>
                <Placeholder width={'40px'} /> <br />
              </MoreDescriptionContainer>
              <ExtContainer>
                <Inline style={{ width: '100%' }}>
                  <Placeholder height={54} width={'20%'} />
                  <Placeholder
                    height={54}
                    width={'20%'}
                    outlined
                    style={{ marginLeft: '10px' }}
                  />
                </Inline>
              </ExtContainer>
            </InfoContainer>
          </Infos>
          <Buttons>
            <SieStarFilled color={rgba(colors.primary, 0.25)} />

            <CTAContainer>
              <Placeholder
                round
                width={'48px'}
                height={48}
                style={{ marginLeft: '16px' }}
              />
              <Placeholder
                round
                width={'48px'}
                height={48}
                style={{ marginLeft: '16px' }}
              />
            </CTAContainer>
          </Buttons>
        </Content>
      </ContentBlock>
    </Container>
  )
}

const Badge = styled.span(({ theme }) => ({
  background: theme.colors.tertiary,
  padding: '0.5rem',
  marginRight: '1rem',
  borderRadius: '.5rem',
}))

interface PlaceholderProps {
  width?: string
  height?: number
  outlined?: boolean
  round?: boolean
}

const Placeholder = styled.div<PlaceholderProps>(
  ({
    theme: { colors },
    width = '50px',
    height = 10,
    outlined = false,
    round = false,
  }) => `
  display:inline-block;
  background:${outlined ? 'transparent' : rgba(colors.primary, 0.25)};
  border: 1px solid ${outlined ? rgba(colors.primary, 0.25) : 'transparent'};
  width:${width};
  height:${height}px;
  border-radius:${round ? '99999px' : 0}
`
)

const Anchor = styled.div({
  width: '0',
  height: '0',
  position: 'absolute',
  top: '-20vh',
})

const ContentBlock = styled.div(({ theme: { colors } }) => ({
  padding: '3.5rem 0 0',
  flex: 85,
}))

const Content = styled.div(({ theme: { colors } }) => ({
  display: 'flex',
  [mq[3]]: {
    minHeight: '150px',
  },
}))

const Infos = styled.div(() => ({
  flex: 80,
}))

const Buttons = styled.div(
  ({ theme: { colors } }) => `
  text-align:right;
  flex:auto;

  & label{
    display:block;
    height:25px;
  }
  & div > button, a > button{
    display: grid;
    border: none;
    width: 24px;
    height: 24px;
    padding-right: 4px;
  }
  ${mq[3]}{
    flex:20;

    display: block;
    & div: {
      margin-top: -10px,
    }
    & div > button, a > button {
      border: 2px solid ${colors.link};
      border-size:2px;
      width: 48px;
      height: 48px;
      padding-right: 6px;
      &.inverted{
        border: 2px solid ${colors.linkOnDark};
        &:hover{
          border: 2px solid ${colors.link};

        }
      }
    }
  }
`
)

const BreakBlock = styled.div(({ theme: { colors } }) => ({
  width: '100%',
  background: colors.blockGadient,
  margin: '3rem 0',
}))

const FirstEmptyColumn = styled.div({
  display: 'none',
  [mq[3]]: {
    display: 'block',
    flex: 15,
  },
})

const BreakContainer = styled.div(({ theme: { fonts, colors } }) => ({
  padding: '2rem',
  flex: 85,
  color: colors.buttonText,
  fontSize: fonts.fontSize,
  [mq[3]]: {
    padding: '2rem 0',
  },
}))

const BreakText = styled.div(({ theme: { fonts, colors } }) => ({
  marginTop: '0.3rem',
  fontFamily: fonts.fontFamilyBlack,
  fontsize: fonts.text.sizes[20],
  color: colors.buttonText,
}))

const speakersHaveAvatar = (
  speakers: string[] | Speaker[]
): speakers is Speaker[] => (speakers?.[0] as Speaker)?.name !== undefined

const Inline = styled.div({
  display: 'inline-block',
  button: {
    marginRight: '20px',
    marginBottom: '1rem',
  },
  [mq[3]]: {
    display: 'inline-flex',
  },
})

const ExtContainer = styled.div({
  display: 'block',
  marginTop: '0.8rem',
})

const DescriptionContainer = styled.div({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
})

interface IDescText {
  isMore?: boolean
}
const DescText = styled.div<IDescText>(
  ({ theme: { fonts, colors }, isMore }) => ({
    fontSize: fonts.fontSize,
    width: '100%',
    color: colors.text,
    lineHeight: fonts.text.lineHeight,
    float: 'left',
    overflow: isMore ? 'visible' : 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: isMore ? 'inherit' : 2,
    WebkitBoxOrient: 'vertical',
    maxWidth: '100%',
    a: {
      color: colors.linkOnDark,
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
)

const DescInner = styled.div({
  display: 'table-cell',
  verticalAlign: 'middle',
})

const MoreDescriptionContainer = styled.div({
  marginTop: '1rem',
})

const Container = styled.div(({ theme: { colors } }) => ({
  [mq[2]]: {
    display: 'flex',
  },
  padding: '0 15px',
  borderBottom: `1px solid ${colors.inputBorder}`,
  position: 'relative',
  '&.blockbuster': {
    background: rgba('#00CCCC', 0.25),
  },
}))

const TimeContainer = styled.div({
  padding: '3.5rem 0 0',
  flex: 15,
})

const Titles = styled.div({
  display: 'table-cell',
  width: '100%',
})

const ClockContainer = styled.div(() => ({
  display: 'inline-block',
  [mq[2]]: {
    display: 'block',
  },
}))

const TimezoneContainer = styled.div({
  display: 'inline-block',
  marginLeft: '0.5rem',
  [mq[2]]: {
    marginLeft: 0,
    display: 'block',
  },
})

const CTAContainer = styled.div(({ theme: { colors } }) => ({
  display: 'block',
  alignItems: 'center',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
  '& + a, button': {
    marginTop: '1rem',
  },
  [mq[2]]: {
    justifyContent: 'flex-end',
    paddingTop: '1rem',
    marginTop: 0,
  },
  [mq[3]]: {
    display: 'flex',
    paddingTop: '3rem',
  },
}))

const InfoContainer = styled.div(({ theme: { colors } }) => ({
  width: '100%',
  paddingBottom: '3.5rem',
}))

const TitleContainer = styled.div({
  display: 'inline-table',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SpeakersContainer = styled.div({
  margin: '3rem 0 1rem',
  display: 'block',
})
