import countryData from './countries-data.json'

interface Languages {
  en: string
  de: string
}

interface Country {
  name: Languages
  code: string
  value: string
}

export const CountryList: Country[] = countryData
