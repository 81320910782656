import styled from '@emotion/styled'
import React from 'react'
import { SieInfo } from '../icons'

interface TooltipProps {
  value: string
}

export const TooltipIcon: React.FC<TooltipProps> = ({ value }) => {
  return (
    <TooltipContainer>
      <SieInfo size={'16px'} data-tip={value} />
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div(
  ({ theme: { colors } }) => `
  display:inline-block;
  margin-left:5px;
  svg{
    cursor:pointer;
    &:hover{
      fill:${colors.buttonActiveBackground};
    }
  }
`
)
