import { LocalStorageUtil } from 'cuenect-web-core'

export const AGENDA_BOOKMARKS = 'agendaBookmarks'

export class BookmarkService {
  public static async getBookmarks(): Promise<string[]> {
    return LocalStorageUtil.getObject<string[]>(AGENDA_BOOKMARKS) || []
  }

  public static async setBookmarks(
    bookmarks: string[]
  ): Promise<string[] | null> {
    LocalStorageUtil.saveData<string[]>(AGENDA_BOOKMARKS, bookmarks)

    return bookmarks
  }
}
