import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

import React from 'react'
import { withTrans } from '../../../i18n/withTrans'
/*
TODO

import './../../../themes/siemens/global.css'
 */
export interface PageContainerOuterProps {
  theme?: Theme
}

export const PageContainerOuter: React.FC<PageContainerOuterProps> = ({
  children,
}) => {
  return <PCO>{children}</PCO>
}
export default withTrans(PageContainerOuter)

const PCO = styled.div(({ theme: { grid } }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columns},1fr)`,
}))
