import { apiConfig } from './../config'
import { HttpService } from './http'

export enum Tag {
  MACHINE_BUILDER = 'Machine Builder',
  MACHINE_USER = 'Machine User',
  SINUMERIK = 'Highlight Showcase | Sinumerik ONE',
}

export class AdhocService {
  static sendRequest = async (tags: string[]) => {
    const url = `${apiConfig.baseUrl}/appointments/queue`

    const result = await HttpService.post({
      url,
      data: { tags },
      config: {
        withCredentials: true,
      },
    })

    if (result) {
      return result.status === 204
    }

    return false
  }

  static getStatus = async () => {
    const url = `${apiConfig.baseUrl}/appointments/queue`

    const result = await HttpService.get({
      url,
      config: { withCredentials: true },
    })

    if (result) {
      return result.data
    }

    return false
  }
}
