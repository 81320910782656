import { Dimensions } from 'cuenect-web-core'
import React from 'react'

/**
 * This hook can be used to watch a div element's dimensions. When the hook is called,
 * it will return an array, where the first element is the ref that you can pass to your div
 * element and the second element is the dimensions which are updated on window resize
 */
export const useDimensions = (): [
  React.MutableRefObject<HTMLDivElement | undefined>,
  Dimensions
] => {
  const ref = React.useRef<HTMLDivElement>()
  const [boundingBox, setBoundingBox] = React.useState({ height: 0, width: 0 })

  React.useEffect(() => {
    const resizeCallback = () => {
      if (ref.current) {
        setBoundingBox(ref.current.getBoundingClientRect())
      } else {
        setBoundingBox(document.body.getBoundingClientRect())
      }
    }

    window.addEventListener('resize', resizeCallback)

    // calling it once to initialize the dimensions with current width and height
    resizeCallback()

    return () => window.removeEventListener('resize', resizeCallback)
  }, [ref || document.body])

  return [ref, boundingBox]
}
