import React from 'react'
import { ServiceContainer } from 'react-service-container'
import {
  AuthenticationContextProvider,
  SlideInContextProvider,
  BookmarksContextProvider,
  ReservationContextProvider,
  ModalContextProvider,
  VodContextProvider,
  FullscreenContextProvider,
  TimezoneContextProvider,
  GlobalContextProvider,
} from './src/utility'
import { featureFlags, isHidePage } from './src/config'
import { FeatureFlagProvider } from 'cuenect-web-core'

export const onClientEntry = () => {
  if (isHidePage()) {
    window.location.replace(
      'https://new.siemens.com/global/en/markets/machinebuilding/additivemanufacturing.html'
    )
  }
}

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <FeatureFlagProvider featureFlags={featureFlags}>
      <FullscreenContextProvider>
        <TimezoneContextProvider>
          <AuthenticationContextProvider>
            <SlideInContextProvider>
              <ModalContextProvider>
                <VodContextProvider>
                  <BookmarksContextProvider>
                    <ReservationContextProvider>
                      {element}
                    </ReservationContextProvider>
                  </BookmarksContextProvider>
                </VodContextProvider>
              </ModalContextProvider>
            </SlideInContextProvider>
          </AuthenticationContextProvider>
        </TimezoneContextProvider>
      </FullscreenContextProvider>
    </FeatureFlagProvider>
  </GlobalContextProvider>
)
