import styled from '@emotion/styled'
import {
  getSpacingString,
  ISpacing,
  TSizeLevel,
} from '../../../utility/styleProps'

interface ITh {
  width?: string
  noBottomLine?: boolean
  padding?: ISpacing
  size?: TSizeLevel
  black?: boolean
}

export const Th = styled.th<ITh>(
  ({
    theme: { fonts, colors },
    padding,
    black,
    noBottomLine,
    size = 18,
    width = 'auto',
  }) => ({
    width: width,
    textAlign: 'left',

    paddingTop: getSpacingString(padding?.top, 1),
    paddingRight: getSpacingString(padding?.right, 1),
    paddingBottom: getSpacingString(padding?.bottom, 1),
    paddingLeft: getSpacingString(padding?.left, 1),

    fontFamily: black ? fonts.fontFamilyBlack : fonts.fontFamily,
    fontSize: fonts.text.sizes[size],
    borderBottom: noBottomLine ? 'none' : `1px solid ${colors.tableColor}`,
    lineHeight: '28px',
  })
)
