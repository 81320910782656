import { LiveVideo, TimeUtil } from 'cuenect-web-core'
import { ProgramEvent } from '../api/program'
import { ProgramTransformer } from './programTransformer'

export class LivestreamTransformer {
  public static transform = ({
    apiResponse,
  }: {
    apiResponse: ProgramEvent[]
  }): LiveVideo[] => {
    const mappedData: LiveVideo[] = ProgramTransformer.sortDates(
      apiResponse.map(({ start, startPlayback = start, end, links, id }) => {
        const ret: LiveVideo = {
          id,
          startUtc: TimeUtil.getUtc(startPlayback),
          endUtc: TimeUtil.getUtc(end),
          durationMs: TimeUtil.getUtc(end).diff(TimeUtil.getUtc(startPlayback)),
          videoUrl: links ? ProgramTransformer.getVideo(links) || '' : '',
        }

        return ret
      })
    ).filter(({ videoUrl }) => videoUrl !== '')

    return mappedData
  }
}
