import React from 'react'

interface State {
  isFullscreen: boolean
}

const defaultState: State = {
  isFullscreen: false,
}
export interface FullscreenAction {
  type: 'FULLSCREEN_CHANGE'
  state?: State
  isFullscreen?: boolean
}

const reducer: React.Reducer<State, FullscreenAction> = (state, action) => {
  switch (action.type) {
    case 'FULLSCREEN_CHANGE':
      return {
        ...state,
        isFullscreen: action.isFullscreen,
      }
    default:
      return defaultState
  }
}
export const FullscreenContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<FullscreenAction>
}>({ state: defaultState, dispatch: () => null })

export const FullscreenContextProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  return (
    <FullscreenContext.Provider value={{ state, dispatch }}>
      {props.children}
    </FullscreenContext.Provider>
  )
}

export const useFullscreenContext = () => React.useContext(FullscreenContext)
