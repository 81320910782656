import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import { Player } from 'furioos-sdk'
import React from 'react'
import { Button } from '../button'

const USER_MAX_TIMEOUT = 10800000 // 3h
const inter = null

const safelyParseJSON = (json: string): unknown => {
  let parsed: unknown
  try {
    parsed = JSON.parse(json)
  } catch (e) {
    console.log('ERROR', e)
    parsed = {}
  }

  return parsed
}

interface FurioosProps {
  playerId: string
  debug?: boolean
}

export type SDKMessage = Record<string, string>

export interface FurioosPlayer {
  sendSDKMessage(sdkMessage: SDKMessage): void
  onSDKMessage(callback: (sdkMessage: string) => void): void
}

export const Furioos = React.forwardRef<FurioosPlayer, FurioosProps>(
  ({ playerId, debug }, ref) => {
    const refDiv = React.createRef<HTMLDivElement>()
    const [height, setHeight] = React.useState<number>()

    const options = {
      whiteLabel: true,
      hideToolbar: true,
      hideTitle: true,
      hidePlayButton: false,
    }

    React.useEffect(() => {
      if (!debug) {
        const newPlayer = new Player(playerId, 'furioos-container', options)

        if (!ref) {
          return
        }

        if (typeof ref === 'function') {
          ref(newPlayer)

          return
        }

        ref.current = newPlayer
      }
    }, [])

    React.useEffect(() => {
      if (!refDiv.current) {
        return
      }

      const defineHeight = () => {
        if (!refDiv.current) {
          return
        }

        const { width } = refDiv.current.getBoundingClientRect()
        const computedHeight = (width / 16) * 9

        if (height !== computedHeight) {
          setHeight(computedHeight)
        }
      }

      defineHeight()

      window.addEventListener('resize', defineHeight)

      return () => window.removeEventListener('resize', defineHeight)
    })

    return (
      <FurioosContainer
        heightProp={height || 1}
        id="furioos-container"
        ref={refDiv}
      ></FurioosContainer>
    )
  }
)

const DebugContainer = styled.div`
  button {
    display: inline-block;
    margin:20px:
  }
`
const FurioosContainer = styled.div<{ heightProp: number }>(
  ({ heightProp, theme: { colors, grid } }) => `
  width:100%;
  height:${heightProp}px;
  display:flex;
  align-items:center;
  justify-content:center;
  iframe{
    border:0;
    width:100%;
    height:100%;
  }
`
)

const FurioosContainerDetection = styled.div(
  ({ theme: { colors, grid } }) => `
  position:fixed;
  pointer:none;
  background:red;
  opacity:.5;
  width:100vw;
  height:100vh;

    pointer-events:auto;


`
)
