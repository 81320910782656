import { AxiosResponse } from 'axios'
import { LocalStorageUtil } from 'cuenect-web-core'
import { HttpService } from './../api/http'
import { apiConfig, AUTH_ACTIVE } from './../config'

export interface LocalData {
  person: UserData
}
export interface UserData {
  salutation: {
    de: string
    en: string
  }

  address?: {
    de: string
    en: string
  }

  fields: {
    country: string
    company: string
  }

  firstname: string
  lastname: string
  language: string
}

export class Auth {
  private static isBrowser: boolean = typeof window !== 'undefined'
  public static isLocalhost: boolean =
    typeof window !== 'undefined'
      ? window.location.hostname === 'localhost'
      : false

  public static isAuthenticated(): boolean {
    if (!Auth.isBrowser) {
      return false
    }

    return LocalStorageUtil.getString('isLoggedIn') === 'true'
  }

  public static async authenticate(token?: string): Promise<boolean> {
    if (process.env.GATSBY_BYPASS_AUTH === 'true') {
      return true
    }
    if (!token) {
      return false
    }
    const response = await AuthService.checkin(token)

    if (!response) {
      return false
    }

    const {
      status,
      data: {
        analytics: { userId, customDimensions },
        person,
      },
    } = response

    if (status !== 200) {
      return false
    }

    LocalStorageUtil.saveString('isLoggedIn', 'true')
    LocalStorageUtil.saveData('user', {
      token,
      userId,
      customDimensions: customDimensions[0],
      person,
    })

    return true
  }

  public static async reAuthenticate(): Promise<boolean> {
    const user = LocalStorageUtil.getObject<{ token: string }>('user')

    if (!user || !user.token) {
      window.localStorage.clear()

      return false
    }

    return await Auth.authenticate(user.token)
  }

  public static async getUserData(): Promise<UserData> {
    return await AuthService.me()
  }
}

export class AuthService {
  public static async checkin(token: string) {
    const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.checkIn,
      data: { token },
      config: { withCredentials: true },
    })

    return result
  }

  public static async me(): Promise<LocalData> {
    const result: false | AxiosResponse<LocalData> = await HttpService.get({
      url: apiConfig.me,
      config: { withCredentials: true },
    })

    if (!result) {
      throw new Error()
    }

    return result.data
  }
}
