import styled from '@emotion/styled'
import { BrowserUtil } from 'cuenect-web-core'
import React from 'react'
import { SieArrow } from '../../atoms/icons'
import { mq } from './../../../utility/breakpoint'

interface KeyVisualProps {
  extraLayer?: boolean
  hideScrollCta?: boolean
}

export const KeyVisual: React.FC<KeyVisualProps> = ({
  children,
  extraLayer,
  hideScrollCta = false,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  const onButtonPress = () => {
    if (!containerRef.current || typeof window === 'undefined') {
      return
    }

    const { height } = containerRef.current.getBoundingClientRect()

    console.log({ isSafari: BrowserUtil.isSafari() })

    if (BrowserUtil.isSafari()) {
      window.scrollTo(0, height - window.scrollY)
    } else {
      window.scrollBy({ behavior: 'smooth', top: height - window.scrollY })
    }
  }

  return (
    <KeyVisualContainer ref={containerRef}>
      <KeyVisualContent>{children}</KeyVisualContent>
      {!hideScrollCta && (
        <MoreContainer>
          <MoreCta onClick={onButtonPress}>{<SieArrow />}</MoreCta>
        </MoreContainer>
      )}
    </KeyVisualContainer>
  )
}
export const KeyVisualContainer = styled.div(({ theme: { grid } }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}))

export const KeyVisualContent = styled.div(({ theme: { grid } }) => ({
  flex: 1,
}))

export const MoreContainer = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '2rem',
})

const MoreCta = styled.button(({ theme }) => ({
  borderRadius: '50%',
  height: '3rem',
  width: '3rem',
  background: theme.colors.buttonBackground,
  border: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  boxShadow: '0px 0px .5rem rgba(0,0,0,.5)',

  '&:hover': {
    background: theme.colors.buttonActiveBackground,
  },

  '& > svg': {
    transform: 'rotate(90deg)',
    fill: '#fff',
  },
}))
