import { GenIcon, IconBaseProps } from 'react-icons'
export const SieFullscreenExit = (props: IconBaseProps) =>
  GenIcon({
    tag: 'svg',
    attr: { version: '1.1', x: '0px', y: '0px', viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d:
            'M15,22v-7h7v2h-5v5H15z M7,22v-5H2v-2h7v7H7z M17,9h-2V2h2v5h5v2H17z M2,9V7h5V2h2v7H2z M15,22v-7h7v2h-5v5H15z M7,22v-5H2v-2h7v7H7z M17,9h-2V2h2v5h5v2H17z M2,9V7h5V2h2v7H2z',
        },
        child: [],
      },
    ],
  })(props)
