import styled from '@emotion/styled'
import { TimeUtil, useAtoms } from 'cuenect-web-core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { eventConfig } from '../../../config'
import { Heading, Progress } from './../'
import { mq } from './../../../utility/breakpoint'

interface BigCountdownProps {
  headline?: React.ReactChild
  onComplete?(): void
}
export const BigCountdown: React.FC<BigCountdownProps> = ({
  headline,
  onComplete,
}) => {
  const { Countdown } = useAtoms()
  const { t } = useTranslation(['index', 'countdown'])

  const START_LONG = eventConfig.countdownStart.utc()
  const END_LONG = eventConfig.countdownEnd.utc()
  const diffDates = END_LONG.diff(START_LONG)

  return (
    <BigCountdownContainer className="BigCountdown">
      {headline && headline}
      <Countdown
        end={END_LONG}
        customOutputRenderer={({
          asDays,
          hours,
          minutes,
          seconds,
          completed,
        }: {
          asDays: number
          hours: number
          minutes: number
          seconds: number
          completed: boolean
        }) => {
          const diffCurrent = END_LONG.diff(TimeUtil.getNow())
          const percentageDate = 1 - diffCurrent / diffDates

          if (completed) {
            onComplete && onComplete()

            return <></>
          }

          return (
            <CountdownHolder>
              <CountdownHolderInner>
                {slot(asDays, t('countdown:days'))}
                {slot(hours, t('countdown:hours'))}
                {slot(minutes, t('countdown:minutes'))}
                {slot(seconds, t('countdown:seconds'))}
              </CountdownHolderInner>
              <Progress value={percentageDate}></Progress>
            </CountdownHolder>
          )
        }}
      ></Countdown>
    </BigCountdownContainer>
  )
}

const BigCountdownContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  'h1, h2, h3, h4, h5, h6': {
    textAlign: 'center',
  },
})

const CountdownHolder = styled.div(({ theme: { colors, fonts } }) => ({
  color: colors.tertiary,
  textAlign: 'center',
  bottom: '7rem',
  h4: {
    color: colors.lightestBackground,
    fontSize: '4vw',
    [mq[1]]: { fontSize: 'vw' },
  },
  marginTop: '1rem',
  [mq[1]]: { marginTop: 0, bottom: 'inherit', position: 'relative' },
}))

const CountdownHolderInner = styled.div({
  display: 'flex',
  margin: '0 -1rem',
  [mq[1]]: { margin: '0 -1.5rem' },
})

const CountdownSlot = styled.div(({ theme: { colors, fonts } }) => ({
  padding: '0 3vw 3.5vw',
  textAlign: 'center',
  fontWeight: 'bold',
  color: colors.inputText,
  div: {
    fontSize: '10vw',
    fontFamily: fonts.fontFamilyBlack,
    [mq[3]]: { fontSize: '100px' },
  },
  span: { fontSize: '3vw', [mq[1]]: { fontSize: '32px' } },
}))

const slot = (value: number, subline: string) => (
  <CountdownSlot>
    <div>{zeroPad(value)}</div>
    <span>{subline}</span>
  </CountdownSlot>
)

export function zeroPad(value: number | string): string {
  const strValue = String(value)
  const match = strValue.match(/(.*?)([0-9]+)(.*)/)
  const prefix = match ? match[1] : ''
  const suffix = match ? match[3] : ''
  const strNo = match ? match[2] : strValue
  const paddedNo =
    strNo.length >= 2
      ? strNo
      : ([...Array(2)].map(() => '0').join('') + strNo).slice(2 * -1)

  return `${prefix}${paddedNo}${suffix}`
}
