import styled from '@emotion/styled'

import React from 'react'
import { mq } from '../../../utility'
/*
TODO
import './../../../themes/siemens/global.css'
 */
export interface PageContainerProps {
  padding?: boolean | undefined
}

interface PCProps extends Pick<PageContainerProps, 'padding'> {}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  padding,
}) => {
  return <PC padding={padding}>{children}</PC>
}

const PC = styled.div<PCProps>(({ padding }) => ({
  gridColumn: '3/35',
  [mq[1]]: {
    gridColumn: '5/32',
  },
  [mq[2]]: {
    gridColumn: '8/30',
  },
  [mq[3]]: {
    gridColumn: '10/28',
  },
  padding: padding ? '60px 0' : 'inherit',
}))
